import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import {isEmpty} from 'lodash'
import { Translate } from '../../../../../utils/Translate';
var config = require("../../../../../assets/config.json");
const Notification = props => {
  const { autoHideDuration,handleClose,text,severity,show,titles } = props;
  const [open, setOpen] = React.useState(show);
  useEffect(() => {
   if(show !== open && open){
    setOpen(false)
   }
  }, [show])
  
  const hideNotification=()=>{
    setOpen(false);
    handleClose();
  }
  // 
  const style = severity === "success" ? {backgroundColor: config.secondaryColor} : {}
  const obj = isEmpty(titles) ? {defaultMessage:text,id:"abc"} : {id:"Playlist.ClassAddedToAPlaylist",values:{playlist_name:titles.toString()},defaultMessage:"This class is added to your playlist"};
  return (
    <Snackbar className='play-list-notification' open={open} autoHideDuration={autoHideDuration} onClose={hideNotification}>
        <Alert className='play-list-alert' severity={severity} style={style}>
            {Translate(obj)}
        </Alert>
    </Snackbar>
  )
}

Notification.defaultProps = {
    // open : true,
    autoHideDuration : 3000,
    text : 'Successfully Created!',
    severity : 'success',
    handleClose : ()=>{},
}
Notification.propTypes = {
    // open : PropTypes.bool,
    autoHideDuration : PropTypes.number,
    handleClose : PropTypes.func,
    text : PropTypes.string,
    severity : PropTypes.string,
}

export { Notification }