import React from "react";
import { Typography, Button } from "@material-ui/core";
import { Translate } from "../../utils/Translate";
import Spinner from "../../components/spinner/spinner";

const ExternalLinks = (props) => {
  return (
    <React.Fragment>
      <div className="playerButtonText centerPopupBox">
        {Translate({
          id: "liveStream.ExternalLinkText",
          defaultMessage:
            "At the scheduled time access the live class on this link",
        })}
        <div className="externalLinkText">
          <Button
            variant="contained"
            color="secondary"
            className="playerButtons"
            onClick={() => props.externalCallback()}
            disabled={props.isExternalStartButton === true ? true : false}
          >
            <p className="playerButtonText">
              {Translate({ id: "liveStream.start", defaultMessage: "Start" })}
            </p>
            {props.isExternalStartButton === true && (
              <Spinner size={80} thickness={3} />
            )}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ExternalLinks;  