import React from "react";
import Workout from "hoc/WorkoutContainer"

function ChannelsCard(props) {
    const checkFavStatus = () => {
        let classes = props.data.title;
        classes[0].favourite = !classes[0].favourite
    }
    
    return (
            <Workout
                cardType={props.cardType}
                collectionType={"channel"}
                profileImageUrl={props.profileImageUrl}
                displayName={props.displayName}
                displayDate={props.created_Time}
                trailerLinkWeb={props.trailerLinkWeb}
                // history={channelData.data.history}
                // showFav={isAuthenticated ? true : false}
                thumbnail={props.thumbnail}
                title={props.title}
                duration={props.duration
                    ? Math.ceil(props.duration / 60)
                    : 55}
                channelDescription={props.description}
                description={props.streamDescription}
                show={true}
                id={props.id}
                trailerLinkMobile={props.trailerLinkMobile}
                clicked={props.redirectTo}
                level={props.level}
                mediaType={props.mediaType}
                isFav={props.isFav}
                collectionName={props.collectionName}
                favCallback={() => { checkFavStatus() }}
                isUnlocked={true}
            />
    )
}

export default ChannelsCard