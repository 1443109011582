import React, { useEffect } from "react";
import Slider from "hoc/SliderContainer";
import { Typography, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Translate } from "../../../../utils/Translate";
import LoadingClassesShimmer from "../../../../components/Shimmer/LoadingClassesShimmer";
import { useBrowseCollections } from "../../../../hooks/useBrowseCollections";
import { getImage } from "utils/utilityfunctions";
import { useNavigate } from "react-router";
import "./BrowseCollections.scss";
import BannerComponent from "components/Banner/BannerComponent";
const BrowseCollections = (props) => {
  const { useBrowseCollectionsDispatch } = useBrowseCollections();
  const { collectionListClasses, fetchOnDemandClasses } =
    useBrowseCollectionsDispatch();
  const clientId = localStorage.getItem("clientId");
  const collectionArray = useSelector(
    (state) => state.onDemand.featuredAndManagedCollectionList
  );

  const itemCount = 5;
  useEffect(() => {
    const data = {
      tenantId: clientId,
      pageNo: 0,
      pageSize: 0,
      collectionType: "",
      ItemCount: itemCount,
    };
    collectionListClasses(data);
  }, []);

  const src = getImage("collection-banner", "jpg");

  const navigate = useNavigate();
  return (
    <div className="main-margin">
       <BannerComponent
          heading={Translate({ id: "ClassListViews.Collections" })}
          subHeading={Translate({ id: "ClassListViews.CollectionDescription" })}
          imageUrl={src}
        ></BannerComponent>
      {/* <Grid container id="channelBg  ">
        {/* <div className="fixedWidthContainer container channelbg-banner collection-list-bg-image">
          {
            <div className="bg-placeholder transition">
              <div className="fallbackBox" />
              <img src={src} className="bg-banner" alt="collection" />
            </div>
          }

          <div className="overlay banner-overlay text-center">
            <div style={{ zIndex: 1 }} className="page-heading text-white">
              <Typography variant="h1" className="m-t-xs-5">
                {Translate({ id: "ClassListViews.Collections" })}
              </Typography>
              <Grid container direction="row">
                <Grid
                  item
                  lg={12}
                  md={12}
                  className="text-center channelDescription "
                >
                  <Typography className="padding16 font-h6" variant="h6">
                    {Translate({ id: "ClassListViews.CollectionDescription" })}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div> */}
        {/* <div className="page-container hidden-xs">
          <Grid container direction="row">
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              className="text-center channelDescription text-gray m-t-xs-5"
            >
              <Typography variant="h6">
                {Translate({ id: "ClassListViews.CollectionDescription" })}
              </Typography>
            </Grid>
          </Grid>
        </div> */}
      {/* </Grid>  */}
     
      <div className="collection-list collectionPage main-page-container list-container-gap collection-list-page">
        {collectionArray ? (
          collectionArray.length > 0 &&
          collectionArray.map((collection) => (
            <Slider
              key={collection.collectionTag}
              collectionTag={collection.collectionTag}
              collectionName={collection.collectionName}
              items={collection.collectionItems}
              description={collection.description}
              listClass={"list"}
              navigate={navigate}
            />
          ))
        ) : (
          <LoadingClassesShimmer />
        )}
      </div>
    </div>
  );
};

export default BrowseCollections;
