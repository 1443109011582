import { useDispatch, useSelector } from "react-redux";
import {
  getSubscriptionBundleDetails,
} from "store/actions/subscriptionBundle";

const useSubscriptionDetail = () => {
    const dispatch = useDispatch();
    const useSubscriptionDetailState = () => ({
      subscriptionBundleDetails: useSelector(
        (state) => state.subscriptionBundle.subscriptionBundleDetails
      ),
      title: useSelector((state) => state.subscriptionBundle.title),
      description: useSelector((state) => state.subscriptionBundle.description),
      imageLink: useSelector((state) => state.subscriptionBundle?.imageLink),
    });
    const useSubscriptionDetailDispatch = () => ({
      getSubscriptionBundleDetails: (SubsPackageId) => {
        dispatch(getSubscriptionBundleDetails(SubsPackageId));
      },
      resetBundleTitleDescriptions: () =>{
         dispatch(resetBundleTitleDescriptions());
      }
    });
    return {useSubscriptionDetailState, useSubscriptionDetailDispatch}
}
export {useSubscriptionDetail}