import {
  HTTPS_OK,
  HTTPS_UNAUTHORIZED,
  HTTPS_INTERNAL_SERVER_ERROR,
  HTTPS_BAD_REQUEST,
} from "../responseCodeConstant";
import { Translate } from "../utils/Translate";

export const showErrorMessage = (response) => {
  let errorMsg;
  if (response.status === HTTPS_BAD_REQUEST) {
    errorMsg = Translate({
      id: "ApiResponseStrings.InvalidDataPassed",
    });
  } else if (response.status === HTTPS_UNAUTHORIZED) {
    errorMsg = Translate({
      id: "ApiResponseStrings.ResetTokenExpired",
    });
  } else if (response.status === HTTPS_INTERNAL_SERVER_ERROR) {
    errorMsg = Translate({
      id: "ApiResponseStrings.InternalServerError",
    });
  } else {
    errorMsg = Translate({
      id: "ApiResponseStrings.GenericError",
    });
  }
  return errorMsg;
};
