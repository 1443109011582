import React,{ useState }  from 'react'
import { iconSizeLarge } from '../utils/svgSize';

function Sentimage() {
    const [size,setSize] = useState('')
    if(size === ""){
        const newSize = iconSizeLarge(size);
        newSize && setSize(newSize)
    }
    
    window.onresize = ()=>{ 
        const newSize = iconSizeLarge(size);
        newSize && setSize(newSize)
    }
  return (
    <svg width={size} height={size} viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <path d="M60 12H12c-3.3 0-5.97 2.7-5.97 6L6 54c0 3.3 2.7 6 6 6h48c3.3 0 6-2.7 6-6V18c0-3.3-2.7-6-6-6zm-1.2 12.75L37.59 38.01c-.96.6-2.22.6-3.18 0L13.2 24.75c-.75-.48-1.2-1.29-1.2-2.16 0-2.01 2.19-3.21 3.9-2.16L36 33l20.1-12.57c1.71-1.05 3.9.15 3.9 2.16 0 .87-.45 1.68-1.2 2.16z" id="fqp33b9f5a"/>
    </defs>
    <g fill="none" fillRule="evenodd">
        <mask id="qrexpycdub" fill="#fff">
            <use xlinkHref="#fqp33b9f5a"/>
        </mask>
        <g mask="url(#qrexpycdub)" fill="#000" fill-opacity=".87">
            <path d="M0 0h72v72H0z"/>
        </g>
    </g>
</svg>
  )
}

export default Sentimage