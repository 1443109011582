import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import CustomDialog from "../../../components/Modal/Modal";
import Spinner from "../../../components/spinner/spinner";
import { Translate } from "../../../utils/Translate";
import { Button, Typography } from "@material-ui/core";
import "./PlayListModal.scss";
const PlayListDeleteModal = (props) => {
  const {
    handleClose,
    deletePlayList,
    loading,
    selectedRow,
    deleteClass,
    title,
  } = props;
  const getText = () => {
    const isPlayList = isEmpty(selectedRow);
    return isPlayList
      ? {
          modalHeading: {
            id: "Playlist.DeletePlaylist",
            defaultMessage: "Delete playlist",
          },
          modalTitle: {
            id: "DeleteTitle",
            defaultMessage: title ?? "My first playlist",
          },
          modalDesc: {
            id: "Playlist.DeleteDesc",
            defaultMessage: "You can’t undo this action.",
          },
          isPlayList,
        }
      : {
          modalHeading: {
            id: "Playlist.DeleteClass",
            defaultMessage: "Delete Class",
          },
          modalTitle: { id: "TITLE", defaultMessage: selectedRow?.title },
          modalDesc: {
            id: "Playlist.DeleteDesc",
            defaultMessage: "You can’t undo this action.",
          },
          isPlayList,
        };
  };
  const { modalHeading, modalTitle, modalDesc, isPlayList } = getText();
  return (
    <CustomDialog
      open
      cancelicon
      root="playlist-modal-delete"
      handleDialogClose={handleClose}
      title={Translate(modalHeading)}
    >
      <div
        className={
          loading
            ? "play-list-delete-modal-container loading"
            : "play-list-delete-modal-container"
        }
        data-testid={"confirmationModal"}
      >
        {loading && <Spinner />}
        <div className="play-list-delete-modal-text">
          <span>
            <Typography variant="h5">{Translate(modalTitle)}</Typography>{" "}
          </span>
          <span className="modal-details">
            <Typography variant="h5">{Translate(modalDesc)}</Typography>
          </span>
        </div>
        <div className="text-center padding16 modal-delete-button">
          <Button
            size="small"
            type="submit"
            color="secondary"
            className="button btn-default add-playlist-popup-btn"
            variant="contained"
            onClick={isPlayList ? deletePlayList : deleteClass}
            data-testid={"confirmDeleteButton"}
          >
            <Typography variant="h5" className="ft-20">
              {Translate({ id: "Playlist.Delete", defaultMessage: "Delete" })}
            </Typography>
          </Button>
        </div>
      </div>
    </CustomDialog>
  );
};

PlayListDeleteModal.defaultProps = {
  handleClose: () => {},
  deletePlayList: () => {},
  deleteClass: () => {},
  loading: false,
};
PlayListDeleteModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  deletePlayList: PropTypes.func.isRequired,
  deleteClass: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export { PlayListDeleteModal };
