import { useDispatch, useSelector } from "react-redux";
import { getAllSubscriptionBundleDetails } from "store/actions/subscriptionBundle";

const useMySubscriptionList = () => {
    const dispatch = useDispatch()
    const useMySubscriptionListState = () => ({
        mySubscriptions: useSelector(state => state.subscriptionBundle?.subscriptionBundleDetails ?? {data:[]}),
        isLoading: useSelector(state => state.subscriptionBundle?.loading)
    });

    const useMySubscriptionListDispatch = () => ({     
            getmysubscription: () => dispatch(getAllSubscriptionBundleDetails()),
        })

    return { useMySubscriptionListState, useMySubscriptionListDispatch }
}
export { useMySubscriptionList }