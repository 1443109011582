import ClubList from "../containers/LiveConnect/ClubList"
import {injectIntl} from 'react-intl'; 
import { connect } from "react-redux";
import * as actionTypes from "../store/actions/actionTypes";
import { FetchClubLiveConnect, resetFetchClubLiveConnect } from '../store/actions/ondemand';


const mapStateToProps = state => {
    return {
        clubClasses: state.onDemand.clubClasses,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        FetchClubLiveConnect: () => dispatch(FetchClubLiveConnect()),
        resetFetchClubLiveConnect: () => dispatch(resetFetchClubLiveConnect()),
        storeDeepLinkDataToStore: (id) => dispatch({ type: actionTypes.SAVE_DEEPLINK_PAYLOAD, deeplinkData: id }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(ClubList));