import { create } from 'apisauce'
import axios from 'axios';
const userApi = create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    // Accept: 'application/json',
    headers: {
         'content-type': 'application/json',
         'Cache-Control' : 'no-cache'
    }
});


userApi.addRequestTransform(request => {

        request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
        request.headers['TenantId']=window.localStorage.getItem('clientId');
})
//WDP-139
userApi.addResponseTransform(res=>{
    if(res.status === 401 && window.location.pathname !== '/signin'){
        localStorage.clear();
        window.location.href = `/signin`;
    }
    return res;
  })

// export const userInstance = axios.create({
//     baseURL: process.env.REACT_APP_API_BASE_URL,
//     headers: {
//         'content-type': 'application/json',
//         'Cache-Control' : 'no-cache',
//         'Authorization' : 'Bearer ' + localStorage.getItem('token'),
//         'TenantId': window.localStorage.getItem('clientId')
//     }
// });

// userInstance.interceptors.response.use(function(res){
//     if(res.status === 401 && window.location.pathname !== '/signin'){
//         localStorage.clear();
//         window.location.href = `/signin`;
//     }
//     return res;
//   })
export default userApi;