import { Skeleton } from '@material-ui/lab'
import React from 'react'
import LoadingClassesShimmer from './LoadingClassesShimmer'

const LSListShimmer = (props) => {
  return (
    <div className={`${!props.onDemand && 'page-container'}`}>
    <div className={`onDemand-description content-desc hidden-xs`}>
        <Skeleton variant='text' width={'40%'} height={20} />    
    </div>
     <Skeleton variant='text' width={'100%'} height={70} />    
     <LoadingClassesShimmer myClass='' key={Math.random()}/>
    </div>

  )
}

export default LSListShimmer