import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: false,
  msg: "",
  royalty: "",
  pendingRoyaltyCalls: [],
  skips: [],
  skipTime: null,
  skipCounter: 0,
  skipsLimitSet: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIT_RADIO_ROYALTY_START:
    case actionTypes.FIT_RADIO_ROYALTY_SUCCESS:
    case actionTypes.FIT_RADIO_ROYALTY_END:
    case actionTypes.FIT_RADIO_ROYALTY_ERROR:
      return { ...state, ...action.payload };
    case actionTypes.FIT_RADIO_RESET_DATA:
      return { ...state, ...action.payload };
    case actionTypes.FIT_RADIO_ROYALTY_UPDATE_SKIPS:
      return { ...state, ...action.payload };
    case actionTypes.FIT_RADIO_ROYALTY_PENDING_CALLS:
      return { ...state, ...action.payload };
    case actionTypes.FIT_RADIO_ROYALTY_SKIP_TIMER:
      return { ...state, ...action.payload };
    case actionTypes.FIT_RADIO_ROYALTY_SKIP_COUNT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export default reducer;
