import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
  subscriptionBundleDetails: [],
  skuId: null,
  title: null,
  description: null,
  subscriptionType: null,
  isSubscribed: null,
  subscriptionDetails: null,
  pricingTableId: null,
  Status: null,
  streamingUrl: null,
  errorMessage: null,
  tenantEntitlement: null,
  navigationFooter: null,
  navigationHeader: null,
  homePage: null,
  connectPage: null,
  classFinderPage: null,
  channelPage: null,
  ChannelDetailPage: null,
  subscriptionPage: null,
  subscriptionDetailPage: null,
  mySubscriptionPage: null,
  loading: true
};

const fetchSubscriptionBundleDetailsFail = (state, action) => {
  return updateObject(state, {
    subscriptionDetails: null,
    errorMessage: action.errorMsg,
  });
};
const fetchSubscriptionBundleDetailStart = (state, action) => {
  return updateObject(state, {
    title: '',
    description: '',
    imageLink:'',
    loading:action.loading
  });
};

const fetchSubscriptionBundleDetailSuccess = (state, action) => {
  return updateObject(state, {
    skuId: action.subscriptionBundleDetails.data[0].skuId,
    title: action.subscriptionBundleDetails.data[0].title,
    description: action.subscriptionBundleDetails.data[0].description,
    imageLink: action.subscriptionBundleDetails.data[0].imageLink,
    subscriptionType: action.subscriptionBundleDetails.data[0].subscriptionType,
    isSubscribed: action.subscriptionBundleDetails.data[0].isSubscribed,
    subscriptionBundleDetails:
      action.subscriptionBundleDetails.data[0].subscriptionDetails,
    pricingTableId: action.subscriptionBundleDetails.data[0].pricingTableId,
    Status: action.subscriptionBundleDetails.data[0].Status,
    loading: action.loading,
  });
};

const fetchAllSubscriptionBundleDetailSuccess = (state, action) => {
  return updateObject(state, {
    subscriptionBundleDetails: action.subscriptionBundleDetails,
    loading: action.loading    
  });
};

const fetchAllSubscriptionBundleDetailStart = (state, action) => {
  return updateObject(state, {
    subscriptionBundleDetails: action.subscriptionBundleDetails,
    loading: action.loading,
  });
};
const fetchAllSubscriptionBundleDetailFail = (state, action) => {
  return updateObject(state, {
    subscriptionBundleDetails: null,
    errorMessage: action.errorMsg,
    loading: action.loading
  });
};

const fetchStreamUrlSuccess = (state, action) => {
  return updateObject(state, {
    streamingUrl: action.streamingUrl,
    errorMessage: action.errorMsg,
  });
};

const fetchStreamUrlFail = (state, action) => {
  return updateObject(state, {
    streamingUrl: null,
    errorMessage: action.errorMsg,
  });
};

const fetchSubscriptionPackageDetailSuccess = (state, action) => {
  return updateObject(state, {
    subscriptionBundleDetails: action.subscriptionPackageDetails,
  });
};

const fetchSubscriptionPackageDetailFail = (state, action) => {
  return updateObject(state, {
    subscriptionBundleDetails: null,
    errorMessage: action.errorMsg,
  });
};

const fetchSubscriptionPackageDetailForTitleSuccess = (state, action) => {
  return updateObject(state, {
    subscriptionBundleDetails: action.subscriptionPackageDetailForTitle,
  });
};

const fetchSubscriptionPackageDetailForTitleFail = (state, action) => {
  return updateObject(state, {
    subscriptionBundleDetails: null,
    errorMessage: action.errorMsg,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SUBSCRIPTION_BUNDLE_DETAILS_START:
      return fetchSubscriptionBundleDetailStart(state, action);
    case actionTypes.FETCH_SUBSCRIPTION_BUNDLE_DETAILS_SUCCESS:
      return fetchSubscriptionBundleDetailSuccess(state, action);
    case actionTypes.FETCH_SUBSCRIPTION_BUNDLE_DETAILS_FAIL:
      return fetchSubscriptionBundleDetailsFail(state, action);
    case actionTypes.FETCH_ALL_SUBSCRIPTION_BUNDLE_DETAILS_START:
      return fetchAllSubscriptionBundleDetailStart(state, action);
    case actionTypes.FETCH_ALL_SUBSCRIPTION_BUNDLE_DETAILS_SUCCESS:
      return fetchAllSubscriptionBundleDetailSuccess(state, action);
    case actionTypes.FETCH_ALL_SUBSCRIPTION_BUNDLE_DETAILS_FAIL:
      return fetchAllSubscriptionBundleDetailFail(state, action);
    case actionTypes.FETCH_STREAMING_URL_SUCCESS:
      return fetchStreamUrlSuccess(state, action);
    case actionTypes.FETCH_STREAMING_URL_FAIL:
      return fetchStreamUrlFail(state, action);
    case actionTypes.FETCH_SUBSCRIPTION_PACKAGE_DETAILS_SUCCESS:
      return fetchSubscriptionPackageDetailSuccess(state, action);
    case actionTypes.FETCH_SUBSCRIPTION_PACKAGE_DETAILS_FAIL:
      return fetchSubscriptionPackageDetailFail(state, action);
    case actionTypes.FETCH_SUBSCRIPTION_PACKAGE_DETAIL_FOR_TITLE_SUCCESS:
      return fetchSubscriptionPackageDetailForTitleSuccess(state, action);
    case actionTypes.FETCH_SUBSCRIPTION_PACKAGE_DETAIL_FOR_TITLE_FAIL:
      return fetchSubscriptionPackageDetailForTitleFail(state, action);

    default:
      return state;
  }
};
export default reducer;
