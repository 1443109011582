import ClasslistViewFav from "../containers/PlayerAndCorousel/views/ClasslistView/ClasslistViewFavourite";
import {
  fetchOnDemandClasses,
  getFavouritedClasses,
} from "../store/actions/ondemand";
import { resetFiltersOnDemand } from "../store/actions/filterOnDemand";
import { resetFilters } from "../store/actions/filter";
import * as actionTypes from "../store/actions/actionTypes";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

const mapStateToProps = (state) => {
  return {
    favoriteClasses: state.onDemand?.favoriteClasses,
    loading: state.onDemand?.favoriteClassesloading,
    isAuthenticated: state.auth.token !== "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //onOnDemandClassesLoad: (userId) => dispatch(fetchOnDemandClasses(userId)),
    getFavouritedClasses: () => dispatch(getFavouritedClasses()),
    resetFiltersOnDemand: () => dispatch(resetFiltersOnDemand()),
    resetFilters: () => dispatch(resetFilters()),
    onDemandDataReset: () => {
      dispatch({
        type: actionTypes.FETCH_ONDEMAND_SUCCESS,
        data: null,
      });
    },
    browseClassesReset: () => {
      dispatch({
        type: actionTypes.SEARCH_ONDEMAND_START,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ClasslistViewFav));
