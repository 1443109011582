import React, { useState } from "react";
import { Translate } from "utils/Translate";
import { Typography } from "@material-ui/core";
import MyReleasesList from "./MyReleases";
import InputSearch from "../../components/InputSearch";
import { useMySubscriptionList } from "hooks/useMySubscriptionList";
import { useEffect } from "react";
import { isNonEmptyArrayOrObject } from "utils/utilityfunctions";
import { PlaylistShimmer } from "./PlayLists/Playlist.shimmer";
import { subscriptionTypes } from "utils/constants";

const MySubscription = () => {
    const { useMySubscriptionListState, useMySubscriptionListDispatch } = useMySubscriptionList();
    const { getmysubscription } = useMySubscriptionListDispatch()
    const { mySubscriptions, isLoading } = useMySubscriptionListState();
    const subscriptions = mySubscriptions?.data;
    const [filteredData, setFilteredData] = useState(subscriptions);
    const [filteringUsed,setFilteringUsed] = useState(false)
       
    const allSubscriptionPackageCheck = () => {
       const hasAllIn = subscriptions?.find(subs => subs.subscriptionType.toLowerCase() == subscriptionTypes.ALLINONE)
       return hasAllIn ? true : false
    }

    const inputClass = () => {
        return isNonEmptyArrayOrObject(filteredData) ? "m-b-search-local-data" : "m-b-search-local"
    }

    useEffect(()=> {
        getmysubscription()
    },[])

    useEffect(()=> {
        setFilteredData(subscriptions)
        inputClass()
    },[subscriptions])

    const searchItems = (event) => {
        let searchItem = event.target.value;
        if (isNonEmptyArrayOrObject(subscriptions)){
        if (searchItem.length > 1) {
            const filteredData = subscriptions.filter((item) => {
                return item.title.toLowerCase().includes(searchItem.toLowerCase());
            });
            setFilteringUsed(true)
            setFilteredData(filteredData);
        } else {
            setFilteringUsed(false)
            setFilteredData(subscriptions);
        }
        } 
    };

    return <>
        <div className="m-t-40 m-t-xs-5 d-flex justify-content-spacebtw">
            <Typography variant="h1" className="review-page-title">
                {Translate({ id: "ClassListViews.MySubscriptions", defaultMessage: "Releases" })}
            </Typography>
            <InputSearch searchItem={searchItems} cls= {inputClass()} inputSearchPlaceholder={"localSearch"} />
        </div>
        {allSubscriptionPackageCheck()? 
             <div className="no-classes-found m-t-0">
                <Typography variant="body2" className="text-gray">{Translate({ id: "ClassListViews.allPackageSubscribed", defaultMessage: "You are subscribed to an all access subscription." })}</Typography>
            </div>
            :      
             isLoading ? <PlaylistShimmer /> : filteredData && <MyReleasesList dataFilter={filteringUsed} myReleases={filteredData} isLoading={isLoading} />     
        }

    </>
}

export default MySubscription