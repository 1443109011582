import { useDispatch, useSelector } from "react-redux";
import { resetFiltersOnDemand, setFilteredClassSearchData, setKeywordsOnDemand,
        setselectedFiltersOnDemand, setFilteredTopKeywords, setPageCount,
        setPaginatedDataOnDemand, setClassesSearchDataOnDemand, settempClassSearchDataOnDemand,
        setTopKeywordsOnDemand } from '../store/actions/filterOnDemand';
import { searchOnDemandClass } from '../store/actions/ondemand';
// const config = require("../assets/config.json")


 const useOnDemandFilter = () => {
     const dispatch = useDispatch();
     const useOnDemandFilterState = () => ({
        tempFilterSearchClasses : useSelector(state => state.onDemand?.fllTempFilterSearchClasses),
        selectedFilters :useSelector(state => state.filterOnDemand?.selectedFilters),
        loading :useSelector(state => state.onDemand?.liveConnectLoading),
        filteredClassSearchData :useSelector(state => state.filterOnDemand?.filteredClassSearchData),
        filteredSkill :useSelector(state => state.filterOnDemand?.filteredSkill),
        filteredDuration :useSelector(state => state.filterOnDemand?.filteredDuration),
        filteredKeywords :useSelector(state => state.filterOnDemand?.filteredKeywords),
        filteredSubCategory :useSelector(state => state.filterOnDemand?.filteredSubCategory),
        filteredFocusArea :useSelector(state => state.filterOnDemand?.filteredFocusArea),
        filteredEquipments :useSelector(state => state.filterOnDemand?.filteredEquipments),
        filterSortBy :useSelector(state => state.filterOnDemand?.filterSortBy),
        filterSearchText :useSelector(state => state.filterOnDemand?.filterSearchText),
        filteredIntensity :useSelector(state => state.filterOnDemand?.filteredIntensity),
        filteredLanguage :useSelector(state => state.filterOnDemand?.filteredLanguage),
        filteredTopKeywords :useSelector(state => state.filterOnDemand?.filteredTopKeywords),
        filteredMediaType :useSelector(state => state.filterOnDemand?.filteredMediaType),
        pageCount :useSelector(state => state.filterOnDemand?.pageCount),
        paginatedData :useSelector(state => state.filterOnDemand?.paginatedData),
        classesSearchDataOnDemand :useSelector(state => state.filterOnDemand?.classesSearchDataOnDemand),
        tempClassSearchDataOnDemand :useSelector(state => state.filterOnDemand?.tempClassSearchDataOnDemand),
        topKeywordsOnDemand :useSelector(state => state.filterOnDemand?.topKeywordsOnDemand),
        searchedOnDemandClassesObj : useSelector(state => state.onDemand?.fllOnDemand),
        resetInProgress : useSelector(state => state.onDemand?.resetInProgress),
        // eslint-disable-next-line eqeqeq
        isAuthenticated : useSelector(state => state.auth.token != "")
    })
    const useOnDemandFilterDispatch = () => ({
        searchOnDemandClassWithNoFilter: (providerId) => {
            dispatch(searchOnDemandClass('', '', false, 1, 0, 9999, true, '', '', '', '', '', '', '', '', '', false, providerId))
        },

        // dispatch(setClassesSearchDataOnDemand(searchedOnDemandClassesObj.items))
        // const fetchDataByFilterSearch = (searchText, fromSearch = true) => {
        //     dispatch(searchOnDemandClass("", filteredKeywords, false, 1, 0, 9999, false, filteredFocusArea.length>0?filteredFocusArea[0].filterValue:"", filteredSkill, filteredEquipments.length>0?filteredEquipments[0].filterValue:"", filteredDuration, filteredIntensity, searchText, filterSortBy, filteredLanguage.length>0?filteredLanguage[0].filterValue:"", filteredSubCategory, fromSearch,config.providerId));
        // }
        searchOnDemandClassWithFilter : (filteredKeywords, filteredFocusArea, filteredSkill,filteredDuration, filteredEquipments, filteredIntensity, searchText, filterSortBy , filteredLanguage, filteredSubCategory, fromSearch, providerId,filteredMediaType) => {
            dispatch(searchOnDemandClass("", filteredKeywords, false, 1, 0, 9999, false, filteredFocusArea.length>0?filteredFocusArea[0].filterValue:"", filteredSkill, filteredEquipments.length>0?filteredEquipments[0].filterValue:"", filteredDuration, filteredIntensity, searchText, filterSortBy, filteredLanguage.length>0?filteredLanguage[0].filterValue:"", filteredSubCategory, fromSearch,providerId,filteredMediaType.length>0?filteredMediaType:""));
        },
        setClassesSearchOnDemandData: (items) => {
            dispatch(setClassesSearchDataOnDemand(items))
        },  
        setOnDemandTopKeywords: (value ) => {
            dispatch(setTopKeywordsOnDemand(value))
        },  

        setTempClassSearchOnDemandData: (tempItems) => {
            dispatch(settempClassSearchDataOnDemand(tempItems))
        },
        // setOnDemandPaginatedData: (onDemandSearchData) => {
        //     dispatch(setPaginatedDataOnDemand(onDemandSearchData.slice(0, LAZY_LOADING_PAGE_SIZE)))
        // },
        setFilteredTopKeywords: (sortedKeywords) => {
            dispatch(setFilteredTopKeywords(sortedKeywords))
        },
        setPageCount: (page) => {
            dispatch(setPageCount(page))
        },
        setOnDemandPaginatedData: (data) => {
            dispatch(setPaginatedDataOnDemand(data))
        },
        setOnDemandKeywords: (filteredValue) => {
            dispatch(setKeywordsOnDemand(filteredValue));
        },
        setSelectedFiltersOnDemand: (tempFilters) => {
            dispatch(setselectedFiltersOnDemand(tempFilters))
        },
        resetOnDemandFilters: () => {
            dispatch(resetFiltersOnDemand());
        },
        setFilteredClassSearchData: (tempData) => {
            dispatch(setFilteredClassSearchData(tempData))
        },
    })

    const initialState ={
        classesCount : "",
        showNoDataFound : false,
        noDataFromApi : false,
        hasMoreData : false,
        loadMoreAllCalled : false
    }

    const useOnDemandFilterAction = {
        UPDATE_ONDEMANDFILTER : "UPDATE_ONDEMANDFILTER"
    }

    const useOnDemandFilterReducer = (state,action) => {
        const { type,payload} = action
        switch (type) {
            case useOnDemandFilterAction.UPDATE_ONDEMANDFILTER:                
                return {...state, ...payload};
        
            default:
                return state;
        }
    }

    const setReducerData = (onDemandFilterDispatch,payload) =>{
        onDemandFilterDispatch({
            type : useOnDemandFilterAction.UPDATE_ONDEMANDFILTER,
            payload : payload
        })
    }

    return {useOnDemandFilterState,
         useOnDemandFilterDispatch,
         onDemandFilterReducer :{ initialState , useOnDemandFilterAction, useOnDemandFilterReducer ,setReducerData}}
}
export {useOnDemandFilter}