import { useDispatch, useSelector } from "react-redux";
import {resetFetchClubLiveConnect } from "../store/actions/ondemand"

const useWrapperList = () => {
    const dispatch = useDispatch();
    const useWrapperListstate = () => ({
        entitlement: useSelector(state => state.tenantConfig.entitlement?.connectPage)
    })

    const useWrapperListDispatch = () => ({
        resetFetchClubLiveConnect: ()=> {
            dispatch(resetFetchClubLiveConnect())
        },
    })
    return {useWrapperListstate , useWrapperListDispatch}
}
export {useWrapperList}