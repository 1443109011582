import React from 'react';

const Fav = (props) => {
    const color = props.color
    return (
      <div className="icon-svg">
         <svg
          className="icon-svg-play-list"
          id={props.id}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 32 32`}
        >
          <defs>
            <path
              d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0z"
              id="8eioc1lkza"
            />
            <path
              d="M11.125 16.923a1.663 1.663 0 0 1-2.242-.009l-.092-.083c-4.375-3.958-7.233-6.55-7.125-9.783.05-1.417.775-2.775 1.95-3.575 2.2-1.5 4.917-.8 6.384.916 1.466-1.716 4.183-2.425 6.383-.916 1.175.8 1.9 2.158 1.95 3.575.117 3.233-2.75 5.825-7.125 9.8l-.083.075z"
              id="cth6zgfpgc"
            />
          </defs>
          <g fill="none" fill-rule="evenodd">
            <g>
              <use xlinkHref="#8eioc1lkza" fill={color} />
            </g>
            <g transform="translate(6 7)">
              <use xlinkHref="#cth6zgfpgc" fill="#fff" />
            </g>
          </g>
        </svg>
      </div>
    );
}


export { Fav }