import React from "react";
import { Typography } from "@material-ui/core";
import { Translate } from "utils/Translate";
import { getPosterSize, appendQueryParameterInURL, isNonEmptyArrayOrObject } from "utils/utilityfunctions";
import { useNavigate } from "react-router";
import Routes from "components/Router/Routes";
import "../../components/Banner/banner.scss"
import { isEmpty } from "lodash";


const MyReleasesList = (function MyReleasesList(props) {
    const navigate = useNavigate()

    const myReleases = props.myReleases
    const dataFilter = props.dataFilter

    const checkForDatafiltering = () => {
        switch (true) {
            case dataFilter:
                return Translate({
                    id: "ClassListViews.NoSearchMessage",
                    defaultMessage: "No results found.",
                })
        
            default:
                return Translate({
                    id: "ClassListViews.noPackageSubscribed",
                    defaultMessage: "You currently have no active subscriptions.",
                })
        }
    }

    const handleClick = (subscription) => {
        const collectionCount = subscription.bundleItemDetails.collections.count;
        const collectionTag = subscription.bundleItemDetails.collections.tag;
        const bundleName = subscription.title;
        const bundleTag = subscription.subsPackageId;
        const bundleDescription = subscription.description;

        if (collectionCount === 1) {
            navigate(Routes.bundleDetail + bundleTag + "/" + collectionTag, {
                state: {
                    bundleName: bundleName,
                    bundleDescription: bundleDescription,
                    },
                }
            );
        } else {
            navigate(Routes.bundleDetail + bundleTag);
        }
    }

    const releasesList = !isNonEmptyArrayOrObject(myReleases) ? (
         <div className="no-classes-found m-t-0">
            <Typography variant="body2" className="text-gray">
                {checkForDatafiltering()}
            </Typography>
            <span> </span>
            {!dataFilter && <Typography variant="body2">
                <a href="/contact" className="dynamiclinks">
                    {Translate({ id: "footer.contactUs" })}
                </a>
            </Typography>}
        </div>
    ) : (
        <div  className="playlist-page-container" >
        { myReleases.map((subscription, index) => (
            <div key={index} className="play-list-item" onClick={() => handleClick(subscription)}>
                    <div className="play-list-item-image playerlist-image" >
                    <img alt="img"
                             src={subscription?.imageLink ? appendQueryParameterInURL(subscription?.imageLink,"width",getPosterSize("").playlistThumbnail):require("../../assets/images/home.jpg") }
                    className="thumb-xs"  />
                    </div>
                    <div className="play-list-item-class player-right-side">
                        <div className="play-list-item-class-info playlist-details-classes-lists release-section">
                        <h3 className="h3-title details-h3-title">{subscription.title}</h3>
                             <span className="play-list-item-class-info-time text-gray time-title banner-desc-clamp release-desc">{subscription.description }</span>
                        </div>
                    </div>
                </div>
        ))}
        </div>
    );

    return releasesList
});

export default MyReleasesList;
