import React from "react";

function ShareIcon(props) {
    const color = props.color

  return (
    <div className="icon-svg">
      <svg
        className="icon-svg-share"
        id={props.id}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 48 48`}
      >
        <defs>
          <path
            d="M24 0c13.255 0 24 10.745 24 24S37.255 48 24 48 0 37.255 0 24 10.745 0 24 0z"
            id="wla0xfxaaa"
          />
          <path
            d="M9.5 8v2H6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V11c0-.55-.45-1-1-1h-3.778V8H19c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V10c0-1.1.9-2 2-2h4.5zM11.911.209c.192 0 .375.076.51.212l4.775 4.773a.721.721 0 0 1 0 1.022.73.73 0 0 1-1.029 0L12.632 2.68v10.691a.727.727 0 0 1-.724.725.727.727 0 0 1-.725-.725V2.681L7.641 6.209a.721.721 0 1 1-1.022-1.022L11.4.421a.721.721 0 0 1 .511-.212z"
            id="ob68q0ewvc"
          />
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g>
            <use xlinkHref="#wla0xfxaaa" fill={color} />
          </g>
          <g transform="translate(12 11)">
            <use xlinkHref="#ob68q0ewvc" fill="#fff" />
          </g>
        </g>
      </svg>
    </div>
  );

}

export { ShareIcon };
