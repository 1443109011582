import * as actionTypes from "../actions/actionTypes";

const initialState = {
  list: [],
  loading: false,
  error: false,
  msg: "",
  lastPlayedMixIds: [],
  fitRadioMixData:[],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIT_RADIO_LIST_START:
    case actionTypes.FIT_RADIO_LIST_SUCCESS:
    case actionTypes.FIT_RADIO_LIST_END:
    case actionTypes.FIT_RADIO_LIST_ERROR:
      return { ...state, ...action.payload };
    case actionTypes.FIT_RADIO_MIX_START:
    case actionTypes.FIT_RADIO_MIX_SUCCESS:
    case actionTypes.FIT_RADIO_MIX_END:
    case actionTypes.FIT_RADIO_MIX_ERROR:
      return { ...state, ...action.payload };
    case actionTypes.FIT_RADIO_RESET_DATA:
      return {...state, ...action.payload};
    
    default:
      return state;
  }
};
export default reducer;
