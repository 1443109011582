import { useDispatch, useSelector } from "react-redux";
import {
  GetConsentDetail,
  authTokenValidation,
  reSendSetPassword,
  setPasswordLogin,
} from "store/actions/auth";
import { getMKTGConsent, getTNCConsent } from "store/selectors/Consents";
const useSetPassword = () => {
  const dispatch = useDispatch();
  const useSetPasswordState = () => ({
    consents: useSelector((state) => state.auth.consents),
    tnConsent: useSelector((state) => getTNCConsent(state)),
    MktConsent: useSelector((state) => getMKTGConsent(state)),
    isAuthenticated: useSelector((state) => state.auth.isAuthenticated),
    resendPasswordTokenValidate: useSelector(
      (state) => state.auth.resendPasswordTokenValidate
    ),
    authPasswordErrorMsg: useSelector(
      (state) => state.auth.setPasswordApiErrorMsg
    ),
    setPasswordValidationData: useSelector(
      (state) => state.auth.setPasswordTokenValidate
    ),
  });

  const useSetPasswordDispatch = () => ({
    loadConsentDetail: () => dispatch(GetConsentDetail()),
    onAuthenticate: (tenantId, password, token, consentRequest) =>
      dispatch(setPasswordLogin(tenantId, password, token, consentRequest)),
    reSendSetPassword: (tenantId, token) =>
      dispatch(reSendSetPassword(tenantId, token)),
    TokenValidation: (token, username) =>
      dispatch(authTokenValidation(token, username)),
  });

  const initialState = {
    tncConsent: false,
    marketingConsent: false,
    isError: false,
    password: "",
    isLoading: true,
    tncDialog: false,
    tncHtml: null,
  };
  const useSetPasswordActions = {
    UPDATE_SET_PASSWORD: "UPDATE_SET_PASSWORD",
  };
  const useSetPasswordReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
      case useSetPasswordActions.UPDATE_SET_PASSWORD:
        return { ...state, ...payload };
      default:
        return state;
    }
  };
  const setReducerData = (setPasswordDispatch, payload) => {
    setPasswordDispatch({
      type: useSetPasswordActions.UPDATE_SET_PASSWORD,
      payload: payload,
    });
  };

  return {
    useSetPasswordState,
    useSetPasswordDispatch,
    setPasswordReducer: {
      useSetPasswordReducer,
      useSetPasswordActions,
      initialState,
      setReducerData,
    },
  };
};
export { useSetPassword };
