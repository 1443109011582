import * as actionTypes from "../actions/actionTypes";

const initialState = {
    globalSearchData: [],
    loading: false,
    error:false,
    msg:'',
    query:'',
    pageFrom:'',
    showSearch:false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GLOBAL_SEARCH_START:
        case actionTypes.GLOBAL_SEARCH_SET_QUERY:
        case actionTypes.GLOBAL_SEARCH_END:
        case actionTypes.GLOBAL_SEARCH_ERROR:
        case actionTypes.GLOBAL_SEARCH_SUCCESS:
        case actionTypes.GLOBAL_SEARCH_SET_RESULT:
        case actionTypes.GLOBAL_SEARCH_SHOW:
            return {...state,...action.payload};

        default:
            return state;
    }
};
export default reducer;
