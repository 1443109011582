import React, {Fragment, useState, useEffect } from 'react';
import { Translate } from "utils/Translate";
import { Typography } from "@material-ui/core";
import Routes from 'components/Router/Routes';
import {getImage, getTranslatedFile } from 'utils/utilityfunctions';
import { useBrowseClasses } from "hooks/useBrowseClasses";
import IconRigntArrow from 'CommonImages/icon-rightArrow';
import { useNavigate } from 'react-router';
import { viewAllCategory } from 'utils/constants';

const config = require("assets/config.json");

const BrowseClasses = (props) => {
  const { useBrowseClassesState, useBrowseClassesDispatch } = useBrowseClasses();
  const { metadata,entitlement } = useBrowseClassesState()
  const { resetBrowseClassData,setClassesSearchData,setPaginatedData,resetFilters } = useBrowseClassesDispatch()
  const [categories, setCategories] = useState([])
  const [showArrow] = useState(props.showArrow)
  const navigate = useNavigate();
  let backGroundImageFlag = true;
  let categoryImageUrl = {};


  useEffect(() => {
    if (metadata?.categories&&metadata.categories.length>0) {
      const language = getTranslatedFile()
      /// commenting below line as it's crashing for ZERO category Id
      //let sortedCategories=metadata.categories.sort((a,b)=>language.categoryName[a.id].localeCompare(language.categoryName[b.id]))
      let sortedCategories=metadata.categories.sort((a, b) => {
        try{
          const categoryNameA = language.categoryName[a.id];
          const categoryNameB = language.categoryName[b.id];
          if (categoryNameA && categoryNameB) {
            return categoryNameA.localeCompare(categoryNameB);
          }
          return false;
        }
        catch(e){
          return false;
        }
      });
      setCategories(sortedCategories)
    }
  }, [metadata])

  const navigateToBrowseClasses=(category)=>{
    resetBrowseClassData()
    setClassesSearchData()  
    setPaginatedData()
    resetFilters()
    navigate(`${Routes.categories}${category?.id}`);
  }

  const viewAll =viewAllCategory

  const getCategoryImage = (imageName) => {
    try {
      const bgImage = getImage(imageName, "jpg") 
        backGroundImageFlag = true;
        categoryImageUrl[imageName] = bgImage;
        //return bgImage;
      } catch {
        // backGroundImageFlag = false;
      }
  }

  const backGroundColor = {
    backgroundColor: "616161"
 };

  return (
    <Fragment>
      {categories && categories.length > 1 && (
        <div
          className={
            entitlement?.live
              ? "m-t-60 mobileScrollParent"
              : props.fromLandingPage !== true
              ? "m-t-30 mobileScrollParent"
              : "m-t-60 mobileScrollParent"
          }
        >
          {props.fromLandingPage !== true && (
            <div className="m-b-32 m-b-15">
              <div
                className={
                  showArrow
                    ? "align-left makeTextClickable adjustTitleWidth m-b-7 d-flex "
                    : "align-left adjustTitleWidth m-b-7 d-flex"
                }
                onClick={() => {
                  navigate(Routes.classListViewRoute);
                }}
              >
                <h1
                  className={
                    config.showLiveEvent || showArrow
                      ? "align-left clear font-h1"
                      : "align-left clear font-h1"
                  }
                >
                  {Translate({ id: "homePage.BrowseClassesTitle" })}
                </h1>
                {showArrow && <IconRigntArrow id="arrow-4" color={config.primaryColor} />}
              </div>
              <h6 className="text-gray font-h6 align-left ">
                {Translate({ id: "homePage.BrowseClassesDescription" })}
              </h6>
            </div>
          )}
          {/* <div item className="m-b-10 m-b-xs-10">
            <Typography variant="h6" className={(config.showLiveEvent||showArrow)?"text-gray align-left":"text-gray"}>
              {Translate({ id: "homePage.BrowseClassesDescription" })}
            </Typography>
          </div> */}
          <div
            className={
              !config.showLiveEvent && !showArrow
                ? props.fromLandingPage !== true
                  ? "mobileScroll m-b-10 m-t-15"
                  : "mobileScroll m-t-15"
                : props.fromLandingPage !== true
                ? "mobileScroll m-b-10 m-t-15"
                : "mobileScroll m-t-15"
            }
          >
            <div className="squareImageContainer">
              {categories.length > 1 && (
                <div
                  className={`list ${
                    props.fromLandingPage === true ?
                    "list-browseClasses-landing":
                    props.fromClassFinder === true
                      ? "list-browseClasses-classFinder"
                      : "list-browseClasses"
                  }`}
                >
                  <div
                      className={`cardPos cardSize browseClasses ${
                        props.fromLandingPage === true ?
                        "browseClasses-landing":
                        props.fromClassFinder === true
                          ? "browseClasses-classFinder"
                          : ""
                      }`}
                    >
                      <div
                        data-testid={"browseCategoryClick"}
                        className="imageHolder full-overlay makeTextClickable"
                        onClick={() => {
                          navigateToBrowseClasses(viewAll);
                        }}
                      >
                        <img className={`browseClasses-all-btn`} src={require(`../../CommonImages/all.png`)} alt="view all"/>
                        <div className="overlay">
                          <div className="bottomText text-white">
                            <Typography
                              variant="body2"
                              className="m-b-10 m-b-xs-10 "
                            >
                              {Translate({ id: `ClassListViews.ViewAll` })}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  {categories.map((category, index) => (
                    <div
                      key={index}
                      className={`cardPos cardSize browseClasses ${
                        props.fromLandingPage === true ?
                        "browseClasses-landing":
                        props.fromClassFinder === true
                          ? "browseClasses-classFinder"
                          : ""
                      }`}
                    >
                      <div  { ...getCategoryImage(category.id)} 
                        data-testid={"browseCategoryClick"}
                        className="imageHolder full-overlay makeTextClickable"
                        style={ backGroundImageFlag ? {backgroundImage: `url(${categoryImageUrl[category.id]})`}:{backGroundColor}}
                          onClick={() => {
                          navigateToBrowseClasses(category);
                        }}
                      >
                        <div className="overlay">
                          <div className="bottomText text-white">
                            <Typography
                              variant="body2"
                              className="m-b-10 m-b-xs-10 "
                            >
                              {/* {category.name} */}
                              {Translate({ id: `categoryName.${category.id}` })}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default BrowseClasses;
