import React from "react";

function IconPlayListDrag(props) {
  const color = props.color

  return (
    <>
      <svg
        className="drag-icon-svg-play-list play-list-info-drag-safari "
        id={props.id}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 24 24`}
        width={24}
        height={24}
      >
        <defs>
          <path
            d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
            id="6ai7b9zv4cde"
          />
        </defs>

        <g fill="none" fill-rule="evenodd">
          <use xlinkHref="#6ai7b9zv4cde" fill={color} />
        </g>
      </svg>
    </>
  );
}

export default IconPlayListDrag;
