import * as actionTypes from "./actionTypes";
import { useSelector } from "react-redux";
import api from "../../api";
import userApi from "../../userAPI";
import * as APIConstants from "../APIConstants";
import { showErrorMessage } from "../../components/ErrorMessage";
import {
  HTTPS_OK,
  HTTPS_UNAUTHORIZED,
  HTTPS_INTERNAL_SERVER_ERROR,
  HTTPS_BAD_REQUEST,
} from "../../responseCodeConstant";

const config = require("../../assets/config.json");

let connect = null;

export const getSubscriptionBundleDetails = (SubsPackageId) => {
  const isAuthenticated = localStorage.getItem("token");
  try {
    const tenantId = config.name
    if (isAuthenticated != null) connect = userApi;
    else connect = api;
    return (dispatch) => {
      let url = APIConstants.getSubscriptionBundleDetailAPI;
      let body = {
        tenantId: tenantId,
        SubsPackageId: SubsPackageId,
      };
      dispatch({
        type: actionTypes.FETCH_SUBSCRIPTION_BUNDLE_DETAILS_START,
        title: "",
        description: "",
        imageLink:"",
        loading: true,
        subscriptionBundleDetails: null,
      });
      connect.post(url, body).then((response) => {
        if (response.status === HTTPS_OK) {
          const subscriptionBundleDetails = response.data;
          dispatch({
            type: actionTypes.FETCH_SUBSCRIPTION_BUNDLE_DETAILS_SUCCESS,
            title: response.data[0]?.title,
            description: response.data[0]?.description,
            imageLink: response.data[0]?.imageLink,
            subscriptionBundleDetails: subscriptionBundleDetails,
            loading: false
          });
        } else {
          dispatch({
            type: actionTypes.FETCH_SUBSCRIPTION_BUNDLE_DETAILS_FAIL,
            subscriptionBundleDetails: null,
            errorMsg: showErrorMessage(response),
            loading: false
          });
        }
      });
    };
  } catch (err) {
    console.log(err);
  }
};

export const getAllSubscriptionBundleDetails = (pageSize) => {
  try {
    return (dispatch) => {
      let url = APIConstants.getAllSubscriptionBundleDetailAPI;
      let body =  {
        tenantId: config.name,
        itemCount: 5,
        pageNo:1,
        pageSize:pageSize?+pageSize:9999
      }
       dispatch({
         type: actionTypes.FETCH_ALL_SUBSCRIPTION_BUNDLE_DETAILS_START,
         subscriptionBundleDetails: null,
         loading: true,
       });
      const userId = localStorage.getItem("userId") 
      const connect = userId ? userApi : api
      connect.post(url, body).then((response) => {
        if (response.status === HTTPS_OK) {
          const subscriptionBundleDetails = response.data;
          dispatch({
            type: actionTypes.FETCH_ALL_SUBSCRIPTION_BUNDLE_DETAILS_SUCCESS,
            subscriptionBundleDetails: subscriptionBundleDetails,
            loading: false
          });
        } else {
          dispatch({
            type: actionTypes.FETCH_ALL_SUBSCRIPTION_BUNDLE_DETAILS_FAIL,
            subscriptionBundleDetails: null,
            errorMsg: showErrorMessage(response),
            loading: false
          });
        }
      });
    };
  } catch (err) {
    console.log(err);
  }
};

export const getStreamingUrl = (tenantId, tag) => {
  try {
    return (dispatch) => {
      let url = APIConstants.getStreamingUrlAPI;
      let body = {
        tenantId: tenantId,
        tag: tag,
      };
      userApi.post(url, body).then((response) => {
        if (response.status === HTTPS_OK) {
          const streamingUrl = response.data.streamingUrl;
          dispatch({
            type: actionTypes.FETCH_STREAMING_URL_SUCCESS,
            streamingUrl: streamingUrl,
          });
        } else {
          dispatch({
            type: actionTypes.FETCH_STREAMING_URL_FAIL,
            streamingUrl: null,
            errorMsg: showErrorMessage(response),
          });
        }
      });
    };
  } catch (err) {
    console.log(err);
  }
};

export const getSubscriptionPackageDetail = (tenantId, itemCount) => {
  const isAuthenticated = localStorage.getItem("token");
  try {
    if (isAuthenticated != null) connect = userApi;
    else connect = api;
    return (dispatch) => {
      let url = APIConstants.getSubscriptionPackageAPI;
      let body = {
        tenantId: tenantId,
        itemCount: itemCount,
      };
      connect.post(url, body).then((response) => {
        if (response.status === HTTPS_OK) {
          const subscriptionPackageDetails = response.data;
          dispatch({
            type: actionTypes.FETCH_SUBSCRIPTION_PACKAGE_DETAILS_SUCCESS,
            subscriptionPackageDetails: subscriptionPackageDetails,
            loading: false
          });
        } else {
          dispatch({
            type: actionTypes.FETCH_SUBSCRIPTION_PACKAGE_DETAILS_FAIL,
            subscriptionPackageDetails: null,
            errorMsg: showErrorMessage(response),
            loading: false
          });
        }
      });
    };
  } catch (err) {
    console.log(err);
  }
};

export const getSubscriptionPackageForTitle = (tenantId, itemCount) => {
  const isAuthenticated = localStorage.getItem("token");
  try {
    if (isAuthenticated != null) connect = userApi;
    else connect = api;
    return (dispatch) => {
      let url = APIConstants.getSubscriptionPackageForTitleAPI;
      let body = {
        tenantId: tenantId,
        itemCount: itemCount,
      };
      connect.post(url, body).then((response) => {
        if (response.status === HTTPS_OK) {
          const subscriptionPackageDetailForTitle = response.data;
          dispatch({
            type: actionTypes.FETCH_SUBSCRIPTION_PACKAGE_DETAIL_FOR_TITLE_SUCCESS,
            subscriptionPackageDetailForTitle:subscriptionPackageDetailForTitle,
            loading: false
          });
        } else {
          dispatch({
            type: actionTypes.FETCH_SUBSCRIPTION_PACKAGE_DETAIL_FOR_TITLE_FAIL,
            subscriptionPackageDetailForTitle: null,
            errorMsg: showErrorMessage(response),
            loading: false
          });
        }
      });
    };
  } catch (err) {
    console.log(err);
  }
};

export const getTenantEntitlement = (tenantId) => {
  const isAuthenticated = localStorage.getItem("token");
  try {
    if (isAuthenticated != null) connect = userApi;
    else connect = api;
    return (dispatch) => {
      let url = APIConstants.getTenantEntitlementAPI;
      let body = {
        tenantId: tenantId,
      };
      connect.post(url, body).then((response) => {
        if (response.status === HTTPS_OK) {
          const tentantEntitlement = response.data;
          dispatch({
            type: actionTypes.FETCH_TENANT_ENTITLEMENT_SUCCESS,
            tentantEntitlement: tentantEntitlement,
          });
        
        } else {
          dispatch({
            type: actionTypes.FETCH_TENANT_ENTITLEMENT_FAIL,
            tentantEntitlement: null,
            errorMsg: showErrorMessage(response),
          });
        }
      });
    };
  } catch (err) {
    console.log(err);
  }
};
