import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
    socialLinks: null,
    appBadgesLinks: null,
    customLinks: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SITE_CONFIG_SUCCESS:
            return fetchSiteConfigSuccess(state, action);
        case actionTypes.FETCH_SITE_CONFIG_FAIL:
            return fetchSiteConfigFail(state, action);
        default:
            return state;
    }
};

const fetchSiteConfigSuccess = (state, action) => {
    return updateObject(state, {
        socialLinks: action.socialLinks,
        appBadgesLinks: action.appBadgesLinks,
        customLinks: action.customLinks
    });
};
const fetchSiteConfigFail = (state, action) => {
    return updateObject(state, {
        socialLinks: action.socialLinks,
        appBadgesLinks: action.appBadgesLinks,
        customLinks: action.customLinks,
    });
};

export default reducer;