import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";
import { isNonEmptyArrayOrObject } from "../../utils/utilityfunctions";

const initialState = {
  subscriptionBundleDetails: [],
  skuId: null,
  title: null,
  isEntiltlementLoading: null,
  errorMessage: null,
  entitlementResponse: null,
  entitlement: null,
  tenantConfig: null,
  isTenantConfigLoading: true,
  routerEntiltlement: null,
};

let entitlement = {
  homePageLoggedIn: {
    live: false,
    ondemand: false,
    cotd: false,
    channel: false,
    collection: false,
    category: false,
    mysubscription: false,
    subscription: false
  },
  homePageLoggedOut: {
    live: false,
    ondemand: false,
    cotd: false,
    channel: false,
    collection: false,
    category: false,
    subscription: false,
  },
  headerLoggedIn: {
    ondemand: false,
    live: false,
    channel: false,
    collection: false,
    category: false,
    subscription: false,
    search: false,
  },
  headerLoggedOut: {
    subscription: false,
    signin: false,
    signup: false,
  },
  landingPage: {
    category: false,
    exploreclasses: false,
  },
  classFinderPage: {
    live: false,
    category: false,
  },
  connectPage: {
    live: false,
    ondemand: false,
  },
  footer: {
    channel: false,
    collection: false,
    category: false,
  },
  classDetails: {
    category: false,
    channel: false,
    collection: false,
    share: false,
  },
  bottomTab: {
    connect: false,
    category: false,
    search: false,
  },
  collectionPage: {
    share: false,
  },
  eventDetailsPage: {
    share: false,
  },
  channelPage: {
    subscription: false,
  },
  channelDetailPage: {
    subscription: false,
  },
  subscriptionPage: {
    connect: false,
    classFinder: false,
    channel: false,
    collection: false,
  },
  account: {
      mysubscription: false,
      rating: false,
      playlist: false,
      favourite: false,
      recentactivity: false
  }
};

const fetchTenantEntiltlementStart = (state, action) => {
  return updateObject(state, {
    loading:true,
    isTenantConfigLoading: true,
    errorMessage: "",
  });
};

const parseTenantEntitlement = (response, entitlement) => {
 let entitlementResponse = response[0].entitlement;
 Object.keys(entitlementResponse).map(function (key, index) {
   if (
     entitlementResponse[key] === null ||
     entitlementResponse[key] === undefined
   ) {
     entitlementResponse[key] = [];
   }
 });
 if (entitlementResponse !== null && entitlementResponse !== undefined) {
   Object.keys(entitlementResponse).forEach((key) => {
     if (entitlement[key] !== null && entitlement[key] !== undefined) {
       let convertIntoLowerCase = entitlementResponse[key].map((name) =>
         name.toLowerCase()
       );
       entitlementResponse[key] = convertIntoLowerCase;
       Object.keys(entitlementResponse[key]).forEach((innerKey) => {
         if (
           entitlement[key][entitlementResponse[key][innerKey]] !== null &&
           entitlement[key][entitlementResponse[key][innerKey]] !== undefined
         )
           entitlement[key][entitlementResponse[key][innerKey]] = true;
       });
     }
   });
   return entitlement;
 }
};

const fetchTenantEntiltlementSuccess = (state, action) => {
  return updateObject(state, {
    entitlementResponse: action.tenantEntitlement,
    entitlement: parseTenantEntitlement(action.tenantEntitlement, entitlement),
    isLoading: false,
    routerEntiltlement: routerEntiltlement(action.tenantEntitlement),
    errorMessage: "",
  });
};

const fetchTenantEntiltlementFail = (state, action) => {
  return updateObject(state, {
    entitlementResponse: null,
    errorMessage: action.errorMsg,
    isEntiltlementLoading: false,
  });
};
const tenantConfigLoadingStart = (state, action) => {
  return updateObject(state, {
    isTenantConfigLoading: false,
    tenantConfig: action.tenantConfig,
  });
};

const routerEntiltlement = (response) => {
  let routerEntiltlement = {};
  let entitlementResponse = response[0].entitlement;
  if (isNonEmptyArrayOrObject(entitlementResponse)) {
    Object.keys(entitlementResponse).forEach((key) => {
      if (entitlement[key] !== undefined) {
        entitlementResponse[key].map((key) =>
          routerEntiltlement.hasOwnProperty(key)
            ? ""
            : (routerEntiltlement[key] = true)
        );
      }
    });
    return routerEntiltlement;
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TENANT_ENTITLEMENT_START:
      return fetchTenantEntiltlementStart(state, action);
    case actionTypes.FETCH_TENANT_ENTITLEMENT_SUCCESS:
      return fetchTenantEntiltlementSuccess(state, action);
    case actionTypes.FETCH_TENANT_ENTITLEMENT_FAIL:
      return fetchTenantEntiltlementFail(state, action);
    case actionTypes.TENENT_CONFIG_LOADING_SUCCESS:
      return tenantConfigLoadingStart(state, action);
    default:
      return state;
  }
};
export default reducer;
