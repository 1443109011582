import React, { useEffect, useReducer } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import PageTitle from "components/PageTitle";
import { Translate } from "utils/Translate";
import Button from "@material-ui/core/Button";
import CheckboxValidatorElement from "ValidatorComponent/CheckboxValidatorElement";
import { ConsentAction } from "utils/constants";
import { useNavigate } from "react-router-dom";
import CustomDialog from "components/Modal/Modal";
import InputText from "ValidatorComponent/TextBoxValidatorComponent";
import { Typography } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import "./SetPassword.scss";
import Spinner from "../../components/spinner/spinner";
import { passwordValidation } from "utils/utilityfunctions";
import { useSetPassword } from "hooks/useSetPassword";

const config = require("../../assets/config.json");
const SetPassword = (props) => {
  const { useSetPasswordState, useSetPasswordDispatch, setPasswordReducer } =
    useSetPassword();
  const {
    consents,
    tnConsent,
    MktConsent,
    isAuthenticated,
    resendPasswordTokenValidate,
    authPasswordErrorMsg,
    setPasswordValidationData,
  } = useSetPasswordState();
  const {
    loadConsentDetail,
    onAuthenticate,
    reSendSetPassword,
    TokenValidation,
  } = useSetPasswordDispatch();
  const { setReducerData, useSetPasswordReducer, initialState } =
    setPasswordReducer;
  const [setPasswordState, setPasswordDispatch] = useReducer(
    useSetPasswordReducer,
    initialState
  );
  const {
    tncConsent,
    marketingConsent,
    isError,
    password,
    isLoading,
    tncDialog,
    tncHtml,
  } = setPasswordState;

  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window?.location?.search);
  const customParam = searchParams.get("token");

  useEffect(() => {
    if (
      setPasswordValidationData?.tokenValidationCode ||
      authPasswordErrorMsg
    ) {
      setReducerData(setPasswordDispatch, {
        isLoading: false,
      });
    }
  }, [setPasswordValidationData?.tokenValidationCode, authPasswordErrorMsg]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/home");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    loadConsentDetail();
    if (config.name && customParam) {
      TokenValidation(customParam, config.name);
    }
    ValidatorForm.addValidationRule("required", (value) => value);
    ValidatorForm.addValidationRule("passwordStrength", (value) => {
      return passwordValidation(value);
    });
  }, []);

  const handleCheckChange = (event) => {
    if (event.target.name === "tncConsent") {
      setReducerData(setPasswordDispatch, {
        tncConsent: !tncConsent,
      });
    } else if (event.target.name === "marketingConsent") {
      setReducerData(setPasswordDispatch, {
        marketingConsent: !marketingConsent,
      });
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const error = !passwordValidation(value);
    setReducerData(setPasswordDispatch, {
      isError: error,
      password: value,
    });
  };

  const loginNavigate = (event) => {
    navigate("/signin");
  };

  const OpenTncDialog = (event) => {
    setReducerData(setPasswordDispatch, {
      tncDialog: true,
    });
    setTimeout(() => {
      if (consents) {
        for (var i = 0; i < consents.length; i++) {
          if (consents[i].policy === "tnc") {
            setReducerData(setPasswordDispatch, {
              tncHtml: consents[i].policyHtml,
            });
          }
        }
      }
    }, 500);
  };

  const handleDialogClose = (event) => {
    setReducerData(setPasswordDispatch, {
      tncDialog: false,
    });
  };

  const sentEmail = (event) => {
    reSendSetPassword(config.name, customParam);
  };

  const loginGoToHome = (event) => {
    setReducerData(setPasswordDispatch, {
      isLoading: true,
    });
    let ConsentRequest = [
      {
        consentTag: tnConsent?.tag,
        action: tncConsent ? ConsentAction.Accepted : ConsentAction.Rejected,
      },
    ];
    if (config.isMarketingConsent) {
      ConsentRequest.push({
        consentTag: MktConsent?.tag,
        action: marketingConsent
          ? ConsentAction.Accepted
          : ConsentAction.Rejected,
      });
    }
    onAuthenticate(config.name, password, customParam, ConsentRequest);
  };

  const contactUs = (event) => {
    navigate("/contact");
  };

  return (
    <div>
      {isLoading ? (
        <Spinner backColor="white" />
      ) : (
        <div>
          {authPasswordErrorMsg ? (
            <div className="formContainer padding16 pd-16 ">
              <div className="m-t-contact m-t-26">
                <div className="errorMessage">
                  {Translate({ id: "ApiResponseStrings.GenericError" })}
                </div>
              </div>
              <div className="form-container">
                <div className="padding16 m-t-login-btn m-t-xs-15">
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className="btn-default"
                    data-testid="submitButton"
                    style={{
                      width: "100%",
                      height: 64,
                      borderRadius: 35,
                      marginBottom: "15px",
                    }}
                    onClick={contactUs}
                  >
                    <Typography
                      variant="button"
                      className={`${config.name}LoginButtonBackgroundOverride`}
                    >
                      {Translate({ id: "footer.contactUs" })}
                    </Typography>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {setPasswordValidationData?.tokenValidationCode == 1000 ? (
                <div className="formContainer padding16 form-container-margin-top45">
                  <div className="m-t-contact m-t-26">
                    <Typography variant="h1" className="login-title">
                      {Translate({
                        id: "SetPassword.SetPasswordTitle",
                        values: {
                          user_name: setPasswordValidationData?.firstName,
                        },
                      })}
                    </Typography>
                    <div className="subscriptionTagline login-subtitle subText">
                      <Typography variant="h6">
                        {Translate({
                          id: "SetPassword.SetPasswordDescription",
                        })}
                      </Typography>
                    </div>
                  </div>
                  <ValidatorForm
                    useRef="form"
                    onSubmit={() => {}}
                    className="form-container signupInputs set-password"
                    onError={() => setIsError({ isError: true })}
                  >
                    <div className="marginFormTop">
                      <div className="set-password-email">
                        {setPasswordValidationData?.email}
                      </div>
                    </div>
                    <InputText
                      isError={isError}
                      type="Password"
                      label={Translate({ id: "Signup.Password" })}
                      callback={handleChange}
                      name="password"
                      value={password}
                      validators={["required", "passwordStrength"]}
                      errorMessages={[
                        <Typography variant="overline">
                          {" "}
                          {Translate({ id: "ForgetPassword.Regex" })}
                        </Typography>,
                      ]}
                    />
                    <div className="floatLeft termsMargin ">
                      <CheckboxValidatorElement
                        name="tncConsent"
                        color="secondary"
                        label={Translate({ id: "Signup.tncText" })}
                        onChange={handleCheckChange}
                        validators={["required"]}
                        tncclick={OpenTncDialog}
                        errorMessages={Translate({ id: "TnCPage.tncAccept" })}
                        checked={tncConsent}
                        value={tncConsent}
                        className="makeTextClickable"
                      />
                    </div>
                    {config.isMarketingConsent ? (
                      <div className="floatLeft termsMargin ">
                        <CheckboxValidatorElement
                          name="marketingConsent"
                          color="secondary"
                          label={Translate({
                            id: "Signup.MarketingConsentText",
                          })}
                          onChange={handleCheckChange}
                          errorMessages={Translate({ id: "TnCPage.tncAccept" })}
                          checked={marketingConsent}
                          value={marketingConsent}
                          className="makeTextClickable"
                        />
                      </div>
                    ) : null}
                    <div className="clear"></div>
                    <div className="padding16 m-t-login-btn m-t-xs-15">
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={
                          password?.length != 0 && tncConsent && !isError
                            ? false
                            : true
                        }
                        className="btn-default"
                        data-testid="submitButton"
                        style={{
                          width: "100%",
                          height: 64,
                          borderRadius: 35,
                          marginBottom: "15px",
                        }}
                        onClick={loginGoToHome}
                      >
                        <Typography
                          variant="button"
                          className={`${config.name}LoginButtonBackgroundOverride`}
                        >
                          {" "}
                          {Translate({ id: "layout.SignIn" })}{" "}
                        </Typography>
                      </Button>
                    </div>
                    <div className="subText ft-12 m-t-15 m-t-xs-15">
                      <Typography variant="caption" className="text-gray">
                        {" "}
                        {Translate({ id: "Signup.AlreadyHaveAnAccount" })}{" "}
                      </Typography>
                      <Typography
                        variant="caption"
                        data-testid={"signinBtn"}
                        className="makeTextClickable"
                        color="secondary"
                        onClick={loginNavigate}
                      >
                        {" "}
                        {Translate({ id: "login.Title" })}{" "}
                      </Typography>
                    </div>
                    <div className="subText ft-12 m-t-15 m-t-xs-15 disclaimer-set-password">
                      <Typography
                        variant="caption"
                        style={{ color: "#8e8e93" }}
                      >
                        {Translate({ id: "login.Disclaimer" })}
                      </Typography>
                    </div>
                    <CustomDialog
                      open={tncDialog}
                      title={Translate({ id: "footer.TermsAndConditions" })}
                      cancelicon={true}
                      isContentScrollable={true}
                      handleDialogClose={handleDialogClose}
                      // acceptedCallback={this.acceptTnc}
                    >
                      <div>{tncHtml ? ReactHtmlParser(tncHtml) : null}</div>
                    </CustomDialog>
                  </ValidatorForm>
                </div>
              ) : (
                <div>
                  {setPasswordValidationData?.tokenValidationCode == 1002 ? (
                    <div className="formContainer form-container padding16">
                      <div className="subscriptionTagline  subText">
                        <Typography variant="h6">
                          {Translate({ id: "SetPassword.TokenAlreadyUsed" })}
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {resendPasswordTokenValidate == 200 ? (
                        <div className="formContainer  padding16">
                          <div className="page-container">
                            <PageTitle
                              className="m-t-sent-mail"
                              label={
                                <Typography variant="h1">
                                  {Translate({ id: "ForgetPassword.Sent" })}
                                </Typography>
                              }
                            ></PageTitle>
                          </div>
                          <div className="subscriptionTagline  subText">
                            <Typography variant="h6">
                              {Translate({
                                id: "SetPassword.EmailSentForPassword",
                              })}
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <>
                          {setPasswordValidationData?.tokenValidationCode ==
                          1001 ? (
                            <div className="formContainer padding16 pd-16">
                              <div className="m-t-contact m-t-26">
                                <div className="subscriptionTagline  subText">
                                  <Typography variant="h6">
                                    {Translate({
                                      id: "SetPassword.LinkExpired",
                                    })}
                                  </Typography>
                                </div>
                              </div>
                              <div className="form-container">
                                <div className="padding16 m-t-login-btn m-t-xs-15">
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    className="btn-default"
                                    data-testid="submitButton"
                                    style={{
                                      width: "100%",
                                      height: 64,
                                      borderRadius: 35,
                                      marginBottom: "15px",
                                    }}
                                    onClick={sentEmail}
                                  >
                                    <Typography
                                      variant="button"
                                      className={`${config.name}LoginButtonBackgroundOverride`}
                                    >
                                      {Translate({
                                        id: "SetPassword.SendEmail",
                                      })}
                                    </Typography>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default SetPassword;
