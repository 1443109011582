import React from "react"

function IconValidation(props) {
    const color = props.color
    const classes = props.cls
    return (
        <div className={classes}>
            <svg viewBox="0 0 28 31" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                    <path d="M2 15.875c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z" fill={color} />
                    <path d="m12.554 18.434 6.45-6.754a.962.962 0 0 1 1.405 0 1.075 1.075 0 0 1 0 1.47l-7.855 8.225-4.563-4.777a1.075 1.075 0 0 1 0-1.47.962.962 0 0 1 1.404 0l3.159 3.306z" fill="#FFF" />
                </g>
            </svg>
        </div>
    )
}

export default IconValidation