import React from "react"
import { useIconSize } from "../utils/svgSize";

function Skill(props) {
    let size = useIconSize(["32px", "32px", "40px", "48px"], props.id);
    return (
        <div className=" skill-logo">
            {/* <svg
                width={size}
                height={size}
                viewBox="0 0 36 37" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <filter color-interpolation-filters="auto" id="8vnowmzhxa">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
        </filter>
    </defs>
    <g transform="translate(-82 -981)" filter="url(%238vnowmzhxa)" fill="none" fillRule="evenodd">
        <g transform="translate(82 981.357)" fill="%23FFF">
            <rect x="14.571" y="12.429" width="6" height="21.857" rx="2"/>
            <path d="M3.857 21.71a2 2 0 0 1 1.998-1.996H7.86c1.104 0 1.998.89 1.998 1.996v10.58a2 2 0 0 1-1.998 1.996H5.855a1.994 1.994 0 0 1-1.998-1.996V21.71zM25.714 7.146c0-1.106.899-2.003 1.998-2.003h2.004c1.104 0 1.998.904 1.998 2.003v25.137a2.003 2.003 0 0 1-1.998 2.003h-2.004a2.005 2.005 0 0 1-1.998-2.003V7.146z"/>
        </g>
    </g>
            </svg> */}
            <svg viewBox="0 0 36 37" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(0 .357)" fill="#000" fill-rule="evenodd">
                    <rect x="14.571" y="12.429" width="6" height="21.857" rx="2" />
                    <path d="M3.857 21.71a2 2 0 0 1 1.998-1.996H7.86c1.104 0 1.998.89 1.998 1.996v10.58a2 2 0 0 1-1.998 1.996H5.855a1.994 1.994 0 0 1-1.998-1.996V21.71zM25.714 7.146c0-1.106.899-2.003 1.998-2.003h2.004c1.104 0 1.998.904 1.998 2.003v25.137a2.003 2.003 0 0 1-1.998 2.003h-2.004a2.005 2.005 0 0 1-1.998-2.003V7.146z" />
                </g>
            </svg>
        </div>)

}

export default Skill