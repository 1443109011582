import React from "react";

function IconBackArrow(props) {
  const color = props.color
  return (
    <span className="right-arrow icon-back">
      <svg
        id={props.id}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <path
            d="M19.791 10.995H6.621l2.88-2.88a.996.996 0 1 0-1.41-1.41l-4.59 4.59a.996.996 0 0 0 0 1.41l4.59 4.59a.996.996 0 1 0 1.41-1.41l-2.88-2.89h13.17c.55 0 1-.45 1-1s-.45-1-1-1z"
            id="uc4p0our6a"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <use xlinkHref="#uc4p0our6a" fill={color} />
        </g>
      </svg>
    </span>
  );
}
export default IconBackArrow;
