import UpdateAccount from "../containers/User/UpdateAccount"
import { GetUserDetail, UpdateUserProfileData } from "../store/actions/auth";
import {injectIntl} from 'react-intl'; 
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
      isAuthenticated: state.auth.token !== "",
      errorMessage: state.auth.errorMessage,
      userDetail: state.auth.userDetail,
      userUpdatedSucessfully: state.auth.userUpdatedSucessfully,
      loading: state.auth.loading
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      loadUserDetail: () => dispatch(GetUserDetail()),
      onUpdateClick: data => dispatch(UpdateUserProfileData(data))
    };
  };

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(UpdateAccount));