import React from "react"

function MoreVertical(props){
  const color = props.color
  return (<svg viewBox="0 0 30 30" width="30" height="30" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <path d="M14.968 25.488A2.978 2.978 0 0 1 12 22.5a2.978 2.978 0 0 1 2.968-2.988 2.978 2.978 0 0 1 2.968 2.988 2.978 2.978 0 0 1-2.968 2.988zm0-1.635c.743 0 1.345-.605 1.345-1.353s-.602-1.353-1.345-1.353-1.344.605-1.344 1.353.601 1.353 1.344 1.353zm0-6.365A2.978 2.978 0 0 1 12 14.5a2.978 2.978 0 0 1 2.968-2.988 2.978 2.978 0 0 1 2.968 2.988 2.978 2.978 0 0 1-2.968 2.988zm0-1.635c.743 0 1.345-.605 1.345-1.353s-.602-1.353-1.345-1.353-1.344.605-1.344 1.353.601 1.353 1.344 1.353zm0-6.365A2.978 2.978 0 0 1 12 6.5a2.978 2.978 0 0 1 2.968-2.988A2.978 2.978 0 0 1 17.936 6.5a2.978 2.978 0 0 1-2.968 2.988zm0-1.635c.743 0 1.345-.605 1.345-1.353s-.602-1.353-1.345-1.353-1.344.605-1.344 1.353.601 1.353 1.344 1.353z" id="bkzy1n4m9a"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <use xlinkHref="#bkzy1n4m9a" fill={color} />        
    </g>
    </svg>
  )
}

export default MoreVertical

