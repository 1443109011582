import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { usePlayList } from "../../../hooks/usePlayList";
import { Typography } from "@material-ui/core";
import { Translate } from "../../../utils/Translate";
import "./Playlists.scss";
import { PlaylistShimmer } from "./Playlist.shimmer";
import PlaylistItem from "./PlaylistItem";
import { Notification } from "../../PlayerAndCorousel/views/Player/PlayList/Notification";
const Playlists = (props) => {
  const { playList, showNotification, severity, fetchPlayList } = usePlayList();
  useEffect(()=>{
    fetchPlayList();
  },[])
  localStorage.removeItem("isLoop");
  return (
    <div className="m-t-40 m-t-xs-5 playlist-page m-t-32 playlist-details-page">
      <Typography
        variant="h1"
        data-testid="playlistTitle"
        className="playlist-page-title"
      >
        {Translate({ id: "Playlist.Playlists", defaultMessage: "Playlists" })}
      </Typography>
      {playList?.loading ? <PlaylistShimmer /> : null}
      {showNotification && (
        <Notification
          {...{ severity, text: playList.msg, show: showNotification }}
        />
      )}
      <div className="playlist-page-container">
        {!playList?.loading && playList?.list?.length === 0 ? (
          <span className="no-class text-gray" data-testid="no-playlist-found">
            {Translate({
              id: "Playlist.NoPlaylistFound",
              defaultMessage: "You don't currently have any playlist",
            })}
          </span>
        ) : (
          ""
        )}
        {!playList?.loading && playList?.list?.length
          ? map(playList?.list, (item, index) => (
              <PlaylistItem key={index} item={item} />
            ))
          : null}
      </div>
    </div>
  );
};

Playlists.propTypes = {};

export { Playlists };
