import * as actionTypes from "./actionTypes";
import api from "../../api";
import * as APIConstants from "../APIConstants";
import { openHrefInNewTab } from "../../utils/utilityfunctions";
import userApi from "userAPI";
import config from "../../assets/config.json"

export const fetchAllChannels = () => {
  return dispatch => {
    dispatch({ type: actionTypes.FETCH_ALLCHANNELS_START });
    let userId = localStorage.getItem('userId');
    let url = APIConstants.getAllChannels;
    let connect = userId ? userApi : api
    connect.get(url)
      .then(response => {
        if (response.ok) {
          /// Filter out data having active channels and titles
          let filteredData = response.data.filter(obj => {
            let isAvailableForWebAndApp = false
            if (obj.availability && obj.availability.length > 0 && obj.availability.includes(1)) {
              isAvailableForWebAndApp = true
            }

            let isActive = obj.active
            let haveTitles = false
            if (obj.title && obj.title.length > 0) {
              haveTitles = true
            }

            return isActive && isAvailableForWebAndApp && haveTitles
          })
          dispatch({
            type: actionTypes.FETCH_ALLCHANNELS_SUCCESS,
            channels: filteredData
          });
        } else {
          dispatch({ type: actionTypes.FETCH_ALLCHANNELS_FAIL });
        }
      });
  };
};

/// Fetch MORE classes of given channel
export const fetchMoreClassesForChannel = (channelTag, numberOfTitles, pageNumber, userId = null) => {
  return dispatch => {
    dispatch({ type: actionTypes.FETCH_MORECLASSESFORCHANNEL_START });
    let userId = localStorage.getItem('userId');
    let body = {
      tenantId: config.name,
      channelId: channelTag,
      take: numberOfTitles
    }
    
    let connect = userId ? userApi : api
    connect.post(APIConstants.getAllChannelsClasses, body)
      .then(response => {
        if (response.ok) {
          dispatch({
            type: actionTypes.FETCH_MORECLASSESFORCHANNEL_SUCCESS,
            classes: response.data
          });
        } else {
          dispatch({ type: actionTypes.FETCH_MORECLASSESFORCHANNEL_FAIL });
        }
      });
  };
};

/// Fetch all classes of given channel
export const fetchAllClassesForChannel = (channelTag, numberOfTitles, pageNumber, userId = null) => {
  return dispatch => {
    dispatch({ type: actionTypes.FETCH_CLASSESFORCHANNEL_START });
    let userId = localStorage.getItem('userId');
    let body = {
      tenantId: config.name,
      channelId: channelTag,
      take: numberOfTitles
    }
    let connect = userId ? userApi : api
    connect.post(APIConstants.getAllChannelsClasses, body)
      .then(response => {
        if (response.ok) {
          dispatch({
            type: actionTypes.FETCH_CLASSESFORCHANNEL_SUCCESS,
            classes: response.data
          });
        } else {
          dispatch({ type: actionTypes.FETCH_CLASSESFORCHANNEL_FAIL });
        }
      });
  };
};

export const clearAllChannelClasses = () =>{
  return dispatch =>{
    dispatch({
      type: actionTypes.FETCH_CLASSESFORCHANNEL_CLEAR
    })
  }
}


export const fetchDeeplinkedClassForChannel = (id, userId) => {
  return dispatch => {
    dispatch({ type: actionTypes.FETCH_CLASSESFORCHANNEL_START });

    let url =`${APIConstants.getDeeplinkedChannelClass}`
    let body = {
      tenantId : config.name, 
      tag : id
    }
    userApi.post(url,body)
      .then(response => {
        if (response.ok) {
          dispatch({
            type: actionTypes.FETCH_CLASSES_SUCCESS,
            classes: updateHyperlink(response.data)
          });
        } else {
          dispatch({ type: actionTypes.FETCH_CLASSESFORCHANNEL_FAIL });
        }
      });
  };
};

const updateHyperlink = (res) =>{
  const description =  res?.titleDetails?.classDescription
  const container = openHrefInNewTab(description)
  return {
    channelDetails:{...res.channelDetails},
    titleDetails: {...res.titleDetails,classDescription:container.innerHTML}
  }
}