import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const CardShimmer = (props) => {
    const {noOfCards,footer,cardType,liveList} = props
    
    return (
            <div className={`cardList m-t-5 flexNoWrap list ${liveList && "list-live-page"} ${cardType === 'cotd-card' && 'cotd-list'}`}>
            {noOfCards.map((i,index) =>  <div key={index}>
                <Skeleton variant="rect" className={`cardBorder ${cardType === 'cotd-card' ? 'cotd-card' : 'channel-page-card'} `} />
                {footer && <Skeleton variant="text" width={'60%'} height={15} />}
                {footer && <Skeleton variant="text" width={'60%'} height={15} />}
                </div> 
            )}
            </div>
    );
}
export default CardShimmer;