import * as actionTypes from "./actionTypes";
import api from "../../api";
import userApi from "../../userAPI";
import * as APIConstants from "../APIConstants";
import { GetUserSubscription } from "./subscription";
import { ConsentType } from "../../utils/constants";
import { Translate } from "../../utils/Translate";
import {
  AuthenticateThirdPartyUser,
  createThirdPartyUser,
} from "../../ClubManager/index";
import config from "../../assets/config.json";
import { checkSubscriptionForEmbeddedOnly, isPseudoSso } from "../../utils/utilityfunctions";

export const AuthenticateUser = (userData) => {
  return (dispatch) => {
    if (config.isThirdPartyEnabled) {
      dispatch(AuthenticateThirdPartyUser(userData));
    } else {
      dispatch(AuthenticateWexerUser(userData));
    }
  };
};

export const createUserWrapper = (userData, freeAccessCode) => {
  return (dispatch) => {
    if (config.isThirdPartyEnabled) {
      dispatch(createThirdPartyUser(userData));
    } else {
      dispatch(createWexerUser(userData, freeAccessCode));
    }
  };
};

export const AuthenticateWexerUser = (userData) => {
  return (dispatch) => {
    const { email, password } = userData;
    dispatch({ type: actionTypes.AUTH_START });

    var bodyFormData = new FormData();
    bodyFormData.append("client_id", process.env.REACT_APP_API_CLIENT_ID);
    bodyFormData.append("redirect_uri", process.env.REACT_APP_API_BASE_URL);
    bodyFormData.append("response_type", "token");
    bodyFormData.append("scope", "openid");
    bodyFormData.append("username", email);
    bodyFormData.append("password", password);
    bodyFormData.append("tenantId", localStorage.getItem("clientId"));

    api.setHeader("Content-Type", "multipart/form-data");
    api.deleteHeader("Authorization");
    api.deleteHeader("TenantId");
    api
      .post(APIConstants.authenticatePlatformUserAPI, bodyFormData)
      .then((response) => {
        if (response.ok) {
          localStorage.setItem("token", response.data.Code);
          localStorage.setItem("userId", response.data.UserId);
          dispatch({
            type: actionTypes.AUTH_SUCCESS,
            token: response.data.Code,
            userId: response.data.UserId,
          });
          dispatch(GetUserDetail());
          dispatch(GetConsentDetail());
          dispatch(GetUserConsent());
        } else {
          if (response.status === 404) {
            dispatch({
              type: actionTypes.AUTH_FAIL,
              error: Translate({
                id: "ApiResponseStrings.InvalidUserNameOrPassword",
              }),
            });
          } else if (response.status === 500) {
            dispatch({
              type: actionTypes.AUTH_FAIL,
              error: Translate({
                id: "ApiResponseStrings.InternalServerError",
              }),
            });
          } else {
            dispatch({
              type: actionTypes.AUTH_FAIL,
              error: response.originalError.message,
            });
          }
        }
      });
  };
};

export const GetConsentDetail = () => {
  return (dispatch) => {
    const languageCode = localStorage.getItem("language") ?? config.language;
    const URI = `${APIConstants.consentAPI}?languageCode=${languageCode}`;
    api.get(URI).then((response) => {
      if (response.ok) {
        dispatch({
          type: actionTypes.FETCH_CONSENT_SUCCESS,
          consents: response.data,
        });
      }
    });
  };
};

export const UpdateUserProfileData = (data) => {
  return (dispatch) => {
    var body = {
      ApplicationProfile: {
        FirstName: data.firstName,
        LastName: data.lastName,
        emailAddress: data.email,
      },
    };

    dispatch({
      type: actionTypes.UPDATE_USER_START,
    });
    userApi.put(APIConstants.updateUserDetails, body).then((response) => {
      var msg = "";
      let Notification = Translate({
        id: body.ApplicationProfile.FirstName
          ? "Profile.ProfileUpdated"
          : "Profile.EmailUpdated",
      });
      if (response.status === 200) {
        dispatch(GetUserDetail());
        dispatch({
          type: actionTypes.NOTIFY_USER,
          NotificationText: Notification,
        });
        dispatch({
          type: actionTypes.UPDATE_USER_SUCCESS,
        });
      } else {
        if (response.status === 409) {
          msg = Translate({ id: "ApiResponseStrings.EmailaddressInUse" });
        } else {
          msg = Translate({ id: "ApiResponseStrings.GenericError" });
        }
        dispatch({
          type: actionTypes.UPDATE_USER_FAIL,
          errorMsg: msg,
        });
      }
    });
  };
};

export const GetUserConsent = () => {
  return (dispatch) => {
    // api.setHeader("Authorization", "Bearer");
    userApi.get(APIConstants.userConsentAPI).then((response) => {
      let tncAccepted = false;
      if (response.ok) {
        let tncObject = response.data.find((x) => x.policy === ConsentType.TNC);
        if (tncObject !== undefined) {
          tncAccepted = tncObject.userAccepted;
        }

        dispatch({
          type: actionTypes.FETCH_USER_CONSENT_SUCCESS,
          userConsent: response.data,
          isLatestTncAccepted: tncAccepted,
        });
      }
    });
  };
};

export const GetUserDetail = () => {
  return (dispatch) => {
    // api.setHeader("Authorization", "Bearer");
    dispatch({ type: actionTypes.FETCH_USER_DETAIL_START });
    userApi.get(APIConstants.getUserDetailAPI).then((response) => {
      if (response.ok) {
        dispatch({
          type: actionTypes.FETCH_USER_DETAIL_SUCCESS,
          userDetail: response.data,
        });
      } else {
        dispatch({ type: actionTypes.FETCH_USER_DETAIL_FAIL });
      }
    });
  };
};

export const clearAuthData = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_SIGNUP_FAIL,
      errorMsg: "",
    });
  };
};

export const ForgetPasswordRequest = (email) => {
  return (dispatch) => {
    let url = APIConstants.forgetPassword;
    var body = {
      EmailAddress: email,
    };
    api.post(url, body).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_SUCCESS,
        });
      } else {
        let errorMsg = "";
        if (response.status === 400) {
          errorMsg = Translate({ id: "ApiResponseStrings.InvalidDataPassed" });
        } else if (response.status === 204) {
          errorMsg = Translate({
            id: "ApiResponseStrings.ForgotPasswordAccountNotFound",
          });
        } else {
          errorMsg = Translate({
            id: "ApiResponseStrings.InternalServerError",
          });
        }
        dispatch({
          type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_FAIL,
          errorMsg: errorMsg,
        });
      }
    });
  };
};

export const ResetPasswordRequest = (data) => {
  return (dispatch) => {
    api.post(APIConstants.resetPassword, data).then((response) => {
      let errorMsg = "";
      if (response.status === 200) {
        errorMsg = Translate({ id: "ApiResponseStrings.ResetSuccessful" });
        dispatch({
          type: actionTypes.RESET_PASSWORD_SUCCESS,
          errorMsg: errorMsg,
        });
      } else {
        if (response.status === 400) {
          errorMsg = Translate({ id: "ApiResponseStrings.InvalidDataPassed" });
        } else if (response.status === 404) {
          errorMsg = Translate({
            id: "ApiResponseStrings.ForgotPasswordAccountNotFound",
          });
        } else if (response.status === 401) {
          errorMsg = Translate({ id: "ApiResponseStrings.ResetTokenExpired" });
        } else {
          errorMsg = Translate({
            id: "ApiResponseStrings.InternalServerError",
          });
        }
        dispatch({
          type: actionTypes.RESET_PASSWORD_ERROR,
          errorMsg: errorMsg,
        });
      }
    });
  };
};

export const SaveUserConsent = (consentTag, action) => {
  return (dispatch) => {
    var data = {
      ConsentTag: consentTag,
      Action: action,
    };
    userApi.post(APIConstants.saveUserConsentAPI, data).then((response) => {
      if (response.ok) {
        dispatch(GetUserConsent());
      }
    });
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  localStorage.removeItem("SSOToken");
  localStorage.removeItem("thirdPartyAuth");
  return {
    type: actionTypes.AUTH_FAIL,
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      const userId = localStorage.getItem("userId");
      dispatch({
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        userId: userId,
      });
      if (checkSubscriptionForEmbeddedOnly()) {
        dispatch(GetUserSubscription());
      }
      dispatch(GetUserDetail());
      dispatch(GetConsentDetail());
      dispatch(GetUserConsent());
    }
  };
};

// export const storeDeepLinkDataToStore = (payload) => {
//   return dispatch => {
//     dispatch({ type: actionTypes.SAVE_DEEPLINK_PAYLOAD, deeplinkData: payload })
//   }
// }

export const createWexerUser = (userData, freeAccessCode) => {
  return (dispatch) => {
    if (freeAccessCode !== "") {
      var url = APIConstants.verifyFreeAccessCode + freeAccessCode;
      api.post(url).then((response) => {
        if (response.status === 200) {
          let Notification = Translate({
            id: "Signup.CodeSignUp",
          });
          dispatch({
            type: actionTypes.VERIFY_FREEACCESSCODE_SUCCESS,
            code: freeAccessCode,
          });
          dispatch(createUser(userData));
          dispatch({
            type: actionTypes.SET_NOTIFICATION_TEXTS,
            NotificationText: Notification,
          });
        } else {
          let errorMsg = "";
          if (response.status === 400) {
            errorMsg = Translate({
              id: "ApiResponseStrings.InvalidDataPassed",
            });
          } else if (response.status === 409) {
            errorMsg = Translate({ id: "ApiResponseStrings.InvalidCoupon" });
          } else {
            errorMsg = Translate({
              id: "ApiResponseStrings.InternalServerError",
            });
          }
          dispatch({
            type: actionTypes.USER_SIGNUP_FAIL,
            errorMsg: errorMsg,
          });
        }
      });
    } else {
      let Notification = Translate({
        id: "Signup.FreeTrialSignUp",
      });
      dispatch(createUser(userData));
      dispatch({
        type: actionTypes.SET_NOTIFICATION_TEXTS,
        NotificationText: Notification,
      });
    }
  };
};

export const displayNotification = (notification) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFY_USER,
      NotificationText: notification,
    });
  };
};

export const createUser = (userData) => {
  const uname = userData.UserDetail.ApplicationProfile.EmailAddress;
  const pwd = userData.UserDetail.ApplicationProfile.PasswordHash;
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_SIGNUP_START });
    api.post(APIConstants.createPlatformUserAPI, userData).then((response) => {
      if (response.ok) {
        dispatch({ type: actionTypes.USER_SIGNUP_SUCCESS });
        dispatch({
          type: actionTypes.SHOW_PRESET_NOTIFICATION,
        });
        dispatch({
          type: actionTypes.FETCH_USER_DETAIL_SUCCESS,
          userDetail: response?.data,
        });
        if (response.data.code) {
          if (config.isThirdPartyEnabled) {
            dispatch(AuthenticateThirdPartyUser(userData));
          } else {
            localStorage.setItem("token", response.data.code);
            localStorage.setItem("userId", response.data.userId);
            dispatch({
              type: actionTypes.AUTH_SUCCESS,
              token: response.data.code,
              userId: response.data.userId,
            });
          }
        } else {
          dispatch({
            type: actionTypes.NOTIFY_USER,
            NotificationText: Translate({
              id: "ApiResponseStrings.GenericError",
            }),
            NotificationType: "error",
          });
        }
      } else {
        let errorMsg = "";
        if (response.status === 400) {
          errorMsg = Translate({ id: "ApiResponseStrings.InvalidDataPassed" });
        } else if (response.status === 409) {
          errorMsg = Translate({ id: "ApiResponseStrings.EmailaddressInUse" });
        } else {
          errorMsg = Translate({
            id: "ApiResponseStrings.InternalServerError",
          });
        }
        dispatch({
          type: actionTypes.USER_SIGNUP_FAIL,
          errorMsg: errorMsg,
        });
      }
    });
  };
};

export const SendToastMsg = (msg) => {
  return (dispatch) => {
    let Notification = Translate({
      id: msg,
    });
    dispatch({
      type: actionTypes.NOTIFY_USER,
      NotificationText: Notification,
    });
  };
};

export const redirectForSSO = (callback) => {
  return (dispatch) => {
    if (config.msalSsoSupport) {
      dispatch(AuthenticateThirdPartyUser());
    } else {
      callback();
    }
  };
};

export const setNavigatedFromWhichScreen = (screenName) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NAVIGATED_FROM_WHICH_SCREEN,
      screenName,
    });
  };
};

export const createUserWithUserId = (userId) => {
  return (dispatch) => {
    const tenantId = config.name;
    const platformType = isPseudoSso()? 4 : 3 ;
    var bodyFormData = new FormData();
    bodyFormData.append("username", userId);
    bodyFormData.append("platformType", platformType);
    bodyFormData.append("tenantId", tenantId);
    api.deleteHeader("TenantId");
    api.setHeader("Content-Type", "multipart/form-data");
    api.post(APIConstants.createUserWithUserIdAPI, bodyFormData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          const token = response.data.Code;
          const userId = response.data.UserId;
          localStorage.setItem("token", token);
          localStorage.setItem("userId", userId);
          dispatch({
            type: actionTypes.AUTH_SUCCESS,
            token: response.data.Code,
            userId: response.data.UserId,
          });
        } else {
          dispatch({
            type: actionTypes.AUTH_FAIL,
            token: null,
            userId: null,
            error: Translate({
              id: "ApiResponseStrings.InvalidUserNameOrPassword",
            }),
          });
          dispatch({
            type: actionTypes.SHOW_ERROR_PAGE_SSO,
            showErrorPageSSO: true,
          });
        }
      });
  };
};

export const authTokenValidation = (token, userId) => {
  return (dispatch) => {
    var data = {
      tenantId: userId,
      token: token,
    };
    api.post(APIConstants.setPasswordTokenValidation, data).then((response) => {
      if (response.ok) {
        dispatch({
          type: actionTypes.FETCH_TOKEN_VALIDATION_SET_PASSWORD,
          setPasswordTokenValidate: response.data,
        });
        dispatch({
          type: actionTypes.SET_PASSWORD_ERROR_MSG,
          setPasswordApiErrorMsg: false,
        });
      } else {
        dispatch({
          type: actionTypes.SET_PASSWORD_ERROR_MSG,
          setPasswordApiErrorMsg: true,
        });
      }
    });
  };
};

export const setPasswordLogin = (tenantId, password, token, consentRequest) => {
  return (dispatch) => {
    var data = {
      tenantId: tenantId,
      password: password,
      token: token,
      consentRequest: consentRequest,
    };
    api.post(APIConstants.setPassword, data).then((response) => {
      if (response.ok) {
        const setPasswordResponse = response.data;
        const token = setPasswordResponse.code;
        const userId = setPasswordResponse.userId;
        localStorage.setItem("token", token);
        localStorage.setItem("userId", userId);
        dispatch({
          type: actionTypes.AUTH_SUCCESS,
          token: setPasswordResponse.code,
          userId: setPasswordResponse.code,
        });
        let Notification = Translate({ id: "SetPassword.SetPasswordSignUp" });
        dispatch({
          type: actionTypes.NOTIFY_USER,
          NotificationText: Notification,
        });
      }
    });
  };
};

export const reSendSetPassword = (tenantId, token) => {
  return (dispatch) => {
    var data = {
      tenantId: tenantId,
      token: token,
    };
    api.post(APIConstants.resendSetPassword, data).then((response) => {
      if (response.ok) {
        dispatch({
          type: actionTypes.RE_SEND_SET_PASSWORD,
          resendPasswordTokenValidate: response.status,
        });
      }
    });
  };
};
