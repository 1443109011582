import React, { useState } from "react";
import PropTypes from "prop-types";
import { getPosterSize, appendQueryParameterInURL, getTime, getTranslatedFile } from "../../../utils/utilityfunctions";
import { PlayListInfoDrag } from "./PlayListInfoDrag";
import { IconPlayListDelete } from "../../../CommonImages/icon-playlist-delete";
import { useDrag, useDrop } from "react-dnd";
import { Chip } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import { Notification } from "../../PlayerAndCorousel/views/Player/PlayList/Notification";
import Routes from "../../../components/Router/Routes";
import { IconPlayListDisableDelete } from "CommonImages/icon-playlist-disable-delete";
const PlayListItemInfo = (props) => {
  const {
    item: PlayListItem,
    movePlayListItem,
    index,
    handelDelete,
    isDisabledDel,
  } = props;
  const translate = getTranslatedFile();
  const [showNotification, setNotification] = useState(false);
  const navigate = useNavigate();
  const [, drop] = useDrop({
    accept: "PLAYLISTINFO",
    drop(item) {
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace itemi with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      if (item.item.playListItemId !== PlayListItem.playListItemId)
        movePlayListItem(hoverIndex, item.item.playListItemId);
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "PLAYLISTINFO",
    item: () => ({ ...props }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });
  const openDeleteModal = (e) => {
    e.stopPropagation();
    !isDisabledDel && handelDelete(PlayListItem);
  };

  const navigateToClass = () => {
    // PlayListItem?.isPlayable && history.push({pathname:`${Routes.playList}/${PlayListItem.playListItemId}/${PlayListItem.tag}`})

    if (PlayListItem?.isPlayable) {
      navigate(Routes.classListViewRoute + "/" + PlayListItem.tag);
      setNotification(false);
    } else {
      setNotification(true);
      setTimeout(() => {
        setNotification(false);
      }, 3000);
    }
  };
  const getMediaScreenResolution = () => {
    const mediaQuery = window.matchMedia("(pointer: coarse)");
    return mediaQuery?.matches;
  };
  //   const opacity = isDragging;
  //   opacity ? "play-list-item opacity no-drag" :

  return (
    <div
      ref={
        getMediaScreenResolution()
          ? (node) => drop(node)
          : (node) => drag(drop(node))
      }
      onClick={navigateToClass}
      className={"play-list-item no-drag"}
      data-testid={"playListItemClick"}
    >
      <PlayListInfoDrag drag={drag} />
      <div className="play-list-item-image playlist-item">
        <img
          className="no-drag thumb-xs"
          alt="asd"
          src= {PlayListItem?.imageLink ? appendQueryParameterInURL(PlayListItem?.imageLink,"width",getPosterSize("").playlistThumbnail) : ""} 
        />
      </div>
      <div className="play-list-item-class playlist-item-right-side">
        <div className="play-list-item-class-info">
          <h3 className="playlist-item-title item-info-title">
            {PlayListItem?.title}
          </h3>
          <span className="play-list-item-class-info-time text-gray item-details-time">
            {getTime(PlayListItem?.duration)}
          </span>
          {!PlayListItem?.isPlayable && (
            <Chip
              size="small"
              className="play-list-item-class-info-not-available class-not-available"
              label={
                translate?.Playlist?.ClassUnavailable ?? "Class unavailable"
              }
            />
          )}
        </div>
        <div className="play-list-item-class-info end play-list-delete">
          <span className="play-list-item-class-info-icon">
            <div
              onClick={openDeleteModal}
              data-testid={"deleteIndividualClasses"}
              className={
                isDisabledDel
                  ? "m-t-5 single-playlist-delete"
                  : "play-list-info-btn-container m-t-5"
              }
            >
              {isDisabledDel ? (
                <IconPlayListDisableDelete id="single-item-delete" color={"#ededed"} />
              ) : (
                <IconPlayListDelete id={PlayListItem?.playListItemId} />
              )}
            </div>
          </span>
        </div>
      </div>
      {showNotification && (
        <Notification
          {...{
            severity: "error",
            text: translate?.ClassListViews?.ClassNotAvailable,
            show: "open",
          }}
        />
      )}
    </div>
  );
};

PlayListItemInfo.propTypes = {
  item: PropTypes.object,
  isDisabledDel: PropTypes.bool,
};

export { PlayListItemInfo };
