import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogContent from "@material-ui/core/DialogContent";
import Styles from './ModalStyle'
import IconClose from "../../CommonImages/icon-close";
import config from "../../assets/config.json"


class CustomDialogFAC extends React.Component {

  render() {
    const { cancelicon, classes, title, open, isContentScrollable, tncAccept, children, heading ,dialogId} = this.props;

    return (
      //CUSTOM DIALOG USED IN THE WHOLE APPLICATION FOR DIFFERENT PURPOSES;
      // STYLE IS BEING CONTROLED BY  "this.props.cancelicon"
      //"this.props.cancelicon" will render cancle button and bigger modals 
      <Dialog
        classes={{
          paper: cancelicon ? (isContentScrollable ? classes.paper3 : classes.paper1) : (tncAccept ? classes.paper3 : classes.paper2)
        }}
        scroll="paper"
        open={open}
        onClose={this.props.cancelButtonClick}
      >
        
        <div id={dialogId}>

        {cancelicon ?
          <Grid item className="tncClose">
            <IconClose cls="CloseButton customClose" onClick={this.props.handleDialogClose} color={config.primaryColor}
            />
          </Grid>  
          : null}
        {title && (

          <Grid item xs={12} sm={12} className={`padding16 title-font  modal-title ${tncAccept && 'padding-top-tnc-accept'}`}>
            <Typography variant="h1">
              {title}
            </Typography>

          </Grid>
        )}

        <Grid xs={12} sm={12} lg={8} md={8} className="padding16 m-auto-imp" item>
          <Typography variant="h3" className="text-gray subscriptionTagline">
            {heading}
          </Typography>
        </Grid>

        {isContentScrollable ?
          //DialogContent renders srollable content|| scrollable content is being rendered in tnc dialog
          <DialogContent>
            <div className="MuiTypography-root MuiDialogContentText-root MuiTypography-body1" >
              {children}
            </div>
          </DialogContent> :

          <React.Fragment>
            {children}
          </React.Fragment>
        }

        <Grid className="p-lr-24 padding16"
          style={this.props.gridstyle}
        >
          {this.props.okButtonText && (
            <Button fullWidth
              size="large"
              variant="contained"
              color="secondary"
              className="button btn-default"
              onClick={this.props.okButtonClick}
              style={this.props.buttonstyle}
            >
              <Typography variant="h5">
                {this.props.okButtonText}
              </Typography>
            </Button>
          )}
            &nbsp;
            {this.props.cancelButtonText && (
            <Button
              variant="contained"
              color="secondary"
              className="button"
              onClick={this.props.cancelButtonClick}
            >
              {this.props.cancelButtonText}
            </Button>
          )}
        </Grid>
        </div>
      </Dialog>
    );
  }
}


export default withStyles(Styles)(CustomDialogFAC);
