import React,{useState} from "react"
import { iconSizeLarge } from '../utils/svgSize';


const IconAlertError = ()=>{
const [size,setSize] = useState('')
if(size === ""){
    const newSize = iconSizeLarge(size);
    newSize && setSize(newSize)
}

window.onresize = ()=>{ 
    const newSize = iconSizeLarge(size);
    console.log(newSize,"newSize")
    newSize && setSize(newSize)
}

return(
<svg width={size} height={size} viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <path d="M36 21c1.65 0 3 1.35 3 3v12c0 1.65-1.35 3-3 3s-3-1.35-3-3V24c0-1.65 1.35-3 3-3zm-.03-15C19.41 6 6 19.44 6 36c0 16.56 13.41 30 29.97 30C52.56 66 66 52.56 66 36 66 19.44 52.56 6 35.97 6zM36 60c-13.26 0-24-10.74-24-24s10.74-24 24-24 24 10.74 24 24-10.74 24-24 24zm3-9h-6v-6h6v6z" id="of69jhg0ka"/>
    </defs>
    <g fill="none" fillRule="evenodd">
        <mask id="32t51sbcjb" fill="#fff">
            <use xlinkHref="#of69jhg0ka"/>
        </mask>
        <g mask="url(#32t51sbcjb)" fill="#000" fill-opacity=".87">
            <path d="M0 0h72v72H0z"/>
        </g>
    </g>
</svg>
)
}
export {IconAlertError}
