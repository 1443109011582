import React from "react";

function LockIcon(props) {

    const {color, classes} = props
    return (
        <div className={classes}>
            <svg width="21.982" height="21.981" viewBox="0 0 21.982 21.981" xmlns="http://www.w3.org/2000/svg" xmlnsLink="http://www.w3.org/1999/xlink">
                <defs>
                    <path d="M16.486 7.785h-.916V5.953a4.581 4.581 0 0 0-4.58-4.58 4.581 4.581 0 0 0-4.579 4.58v1.832h-.916a1.837 1.837 0 0 0-1.831 1.832v9.159c0 1.007.824 1.831 1.831 1.831h10.991a1.837 1.837 0 0 0 1.832-1.831v-9.16a1.837 1.837 0 0 0-1.832-1.831zm-5.495 8.243a1.837 1.837 0 0 1-1.832-1.832c0-1.007.824-1.832 1.832-1.832 1.007 0 1.832.825 1.832 1.832a1.837 1.837 0 0 1-1.832 1.832zM8.243 7.785V5.953a2.744 2.744 0 0 1 2.748-2.747 2.744 2.744 0 0 1 2.748 2.747v1.832H8.243z" id="671ansqmga" />
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <mask id="cicqtzvpmb" fill="#fff">
                        <use xlinkHref="#671ansqmga" />
                    </mask>
                    <g mask="url(#cicqtzvpmb)" fill={color}>
                        <path d="M0 0h21.982v21.981H0z" />
                    </g>
                </g>
            </svg>
        </div>
    );
}

export { LockIcon };
