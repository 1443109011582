import React from "react"

function IconFilter() {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" shape-rendering="crispEdges" viewBox="0 0 20 20">
            <defs>
                <path id="au1030bqoa" d="M9.167 15h1.666c.459 0 .834-.375.834-.833 0-.459-.375-.834-.834-.834H9.167c-.459 0-.834.375-.834.834 0 .458.375.833.834.833zM2.5 5.833c0 .459.375.834.833.834h13.334c.458 0 .833-.375.833-.834 0-.458-.375-.833-.833-.833H3.333c-.458 0-.833.375-.833.833zm3.333 5h8.334c.458 0 .833-.375.833-.833 0-.458-.375-.833-.833-.833H5.833C5.375 9.167 5 9.542 5 10c0 .458.375.833.833.833z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-1244 -297) translate(1244 297)">
                        <use xlinkHref="#au1030bqoa" fill="#FFF" />
                    </g>
                </g>
            </g>
        </svg>

    )
}

export default IconFilter