import React, { Component } from "react";
import { Route, Navigate, Routes } from "react-router-dom";

import TncLink from "../../containers/TermsAndConditions/TncLink";
// import HomePage from "hoc/HomePageContainer";
import OnDemandPage from "containers/OnDemandPage/OnDemandPage";
import BrowseCollections from "containers/PlayerAndCorousel/views/ClasslistView/BrowseCollections"
import BrowseCollectionsList from "containers/PlayerAndCorousel/views/ClasslistView/BrowseCollectionsList"
// import ClubPage from "containers/LiveConnect/ClubPage"
import ChannelsCollection from "hoc/ChannelsCollectionContainer"
import ChannelView from "hoc/ChannelViewContainer"
import WrapperList from "containers/LiveConnect/WrapperList"
import LiveStreamPlayer from "containers/LiveStreaming/LiveStreamPlayer"
import Categories from "containers/BrowseClasses/Categories"
import PaymentDone from "containers/Stripe/PaymentDone";
import RoutesPath from './Routes'
import DefaultClassesPage from "containers/BrowseClasses/DefaultClassesPage"
import Loading from "../spinner/ComponentLoader";
import ProfileWrapper from "containers/User/ProfileWrapper";
import EmbeddedWrapper from "EmbeddedWrapper";
import App from "App";

import loadable from '@loadable/component'
import SetPassword from "containers/SetPassword/SetPassword";

import RouterEntitlementManager from "./RouterEntitlementManager";
import BundlePage from "components/Subscription/SubscriptionDetail";

// import App from "App";
const config = require('../../assets/config.json');


const delay = 1500;
const HomePage = loadable(() => import("hoc/HomePageContainer"), {
  fallback: Loading
});

const Notfound = loadable(() => import("../404/404"), {
  fallback: Loading,
});

const Error = loadable(() => import("../Error"), {
  fallback: Loading,
});

const ForgetPassword = loadable(() => import("../../hoc/ForgetPasswordContainer"), {
  fallback: Loading,
});

const Contact = loadable(() => import("../../hoc/ContactContainer"), {
  fallback: Loading,
});

const SearchResult = loadable(() => import("../../hoc/GlobalSearchContainer"), {
  fallback: Loading,
});

const ClassCollection = loadable(() => import("../../hoc/NewCollectionPagesContainer"), {
  fallback: Loading,
});

const ChannelPlayer = loadable(() => import("../../hoc/ChannelPlayerContainer"), {
  fallback: Loading,
});

const Player = loadable(() => import("../../hoc/PlayerContainer"), {
  fallback: Loading,
});

const SubscribePage = loadable(() => import("../../hoc/SubscribeContainer"), {
  fallback: Loading,
});

const CheckOutPage = loadable(() => import("../../hoc/CheckOutPageContainer"), {
  fallback: Loading,
});

const SigninPage = loadable(() => import("../../hoc/SignInContainer"), {
  fallback: Loading,
});

const OauthCallbackPage = loadable(() => import("../../containers/Oauth/Callback"), {
  fallback: Loading,
});

const Signup = loadable(() => import("../../hoc/SignupContainer"), {
  fallback: Loading,
});




//By Nikhil on 26h Nov, 2019
//This component/method is used to restrict the user from accessing the direct routes on the basis of checkStatusTag
// checkStatusTag is configured in config.json and it will redirect the user to signIn page. 
const PrivateRoute = ({ component: Component, checkStatusTag, ...rest }) => (
  <Route {...rest} render={(props) => (
    checkStatusTag === false
      ? <Navigate to='/signin' replace /> : <Component {...props} />
  )} />
)
class Router extends Component {
  componentDidMount() {
    let url = window.location.pathname;
    let splittedUrl = url.split("/");
    if (splittedUrl.includes("ondemand")) {
      //checking old deeplink
      if (splittedUrl.includes("deep")) {
        let indexOfClassId = splittedUrl.indexOf("deep") + 1;
        if (splittedUrl[indexOfClassId] !== undefined) {
          window.location.pathname = `/classes/${splittedUrl[indexOfClassId]}`
        }
      } else {


        let index = splittedUrl.indexOf("ondemand");
        splittedUrl[index] = "home";
        let newUrl = splittedUrl.join("/");
        window.location.pathname = newUrl;
      }
    }
  }
  // getChildContext() {
  //   return {
  //     location: this.props.location
  //   }
  // }
  render() {
    var entitlement = this.props.entitlement;
    window.scrollTo(0, 0);
    return (
        <Routes>
            <Route path="/" element={<App {...this.props} />}>
              <Route path={RoutesPath.homePage} element={<OnDemandPage {...this.props} />}/>
              <Route path="/" exact element={<HomePage {...this.props} />} />
              <Route path="/tc" element={<TncLink {...this.props} />} />
              <Route path="/channels" exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path="/channels" exact element={<ChannelPlayer  {...this.props} />} />
              <Route path="/success" element={<PaymentDone {...this.props} />} />
              <Route path="/ondemand/fav" exact element={<ProfileWrapper {...this.props} />} />
              <Route path="/signin" exact element={<SigninPage {...this.props} />} />
              <Route path="/callback" exact element={<OauthCallbackPage {...this.props} />} />
              <Route path="/subscribe" exact element={<SubscribePage {...this.props} />} />
              <Route path="/subscribe/CheckOut" exact element={<CheckOutPage {...this.props} />} />
              <Route path={RoutesPath.reviewsRoute} exact element={<ProfileWrapper {...this.props} />} />
              <Route path="/user/account" exact element={<ProfileWrapper {...this.props} />} />
              <Route path="/user/update" exact element={<ProfileWrapper {...this.props} />} />
              <Route path="/contact" exact element={<Contact {...this.props} />} />
              <Route path="/ondemand/player/deep/:classId" exact element={<Player {...this.props} />} />
              <Route path="/error" exact element={<Error {...this.props} />} />
              <Route path={`${RoutesPath.collectionViewRoute}:id`} exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path={RoutesPath.oldOnDemandClassPlayerRoute} exact element={<OnDemandPage {...this.props} />} />
              <Route path={`${RoutesPath.categories}:id`} exact element={<RouterEntitlementManager {...this.props} />} />
              {/* <Route path={`${RoutesPath.categories}/all`} exact element={<Categories {...this.props} />} /> */}
              <Route path={`${RoutesPath.channelDetailView}:id`} exact element={<RouterEntitlementManager {...this.props} />} />

              <Route path={`${RoutesPath.liveSchedulePlayerRoute}:id`} exact element={<LiveStreamPlayer {...this.props} />} />
              <Route path={`${RoutesPath.liveDetail}:id`} exact element={<LiveStreamPlayer {...this.props} />} />
              <Route path={RoutesPath.connectClasses} exact element={<WrapperList  {...this.props} />} />
              <Route path={`${RoutesPath.connectClassPlayerRoute}:id`} exact element={<Player {...this.props} />} />
              <Route path={`${RoutesPath.connect}schedule`} exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path={`${RoutesPath.connect}classes`} exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path={`${RoutesPath.connect}:id`} exact element={<Player {...this.props} />} />
              
              
              <Route path={RoutesPath.collections} exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path={RoutesPath.classListViewRoute} exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path={RoutesPath.searchRoute} exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path={RoutesPath.recentActivityRoute} exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path={RoutesPath.recentActivityRouteV2} exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path={RoutesPath.favClassRoute} exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path={RoutesPath.favClassRouteV2} exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path={`${RoutesPath.onDemandClassPlayerRoute}:id`} exact element={<Player {...this.props} />} />
              {/* <Route path={RoutesPath.browseCollection} exact element={<BrowseCollectionsList {...this.props} />} /> */}
              <Route path={RoutesPath.classListViewRoute} exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path="/search/:query" exact element={<SearchResult {...this.props} />} />
              <Route path="/search" exact element={<SearchResult {...this.props} />} />
              <Route path={`${RoutesPath.collectionViewRouteV2}:id`} exact element={<ClassCollection {...this.props} />} />
              <Route path="/user/forget/:token" exact element={<ForgetPassword checkStatusTag={config.showForgetPasswordPage} {...this.props}/>} />
              <Route path="/signup" element={ <Signup {...this.props}/> } />

              <Route path={`${RoutesPath.channelDetailView}:id`} exact element={<Player />} />
              <Route path={RoutesPath.userPlayList} exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path={`${RoutesPath.userPlayList}/:playlistId`} exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path={`${RoutesPath.playList}/:playlistId/:classId`} exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path={RoutesPath.mySubscriptionsRoute} exact element={<RouterEntitlementManager {...this.props} />} />
              <Route path={`${RoutesPath.bundleDetail}:tag/:id`} exact element={<ClassCollection {...this.props} />} />
              <Route path={`${RoutesPath.bundleDetail}:tag`} exact element={<BundlePage {...this.props} />} />

              <Route path={`*`} element={<Notfound {...this.props} />} />
              <Route path={RoutesPath.setPassword} exact element={<SetPassword {...this.props} />} />
            </Route>
        </Routes>
      );
  }
}

export default Router;
/* Rendering any components inside the Switch that isn't a Route causes buggy behaviour so here I'm wrapping the component in a UserConsumer */
