import React from 'react'

function Unfav(props) {
    const color = props.color
    return (
      <div className="icon-svg">
        <svg
          className="icon-svg-play-list"
          id={props.id}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 32 32`}
        >
          <defs>
            <path d="M32 16a16 16 0 1 1-32 0 16 16 0 1 1 32 0" id="4nd2bbo8ta" />
            <path
              d="M16.384 3.473c-2.2-1.5-4.917-.8-6.384.916-1.466-1.716-4.183-2.425-6.383-.916a4.56 4.56 0 0 0-1.95 3.575c-.117 3.233 2.75 5.825 7.125 9.8l.083.075a1.663 1.663 0 0 0 2.242-.009l.092-.083c4.375-3.967 7.233-6.558 7.125-9.792a4.557 4.557 0 0 0-1.95-3.566zm-6.3 12.133-.084.083-.083-.083C5.95 12.014 3.334 9.639 3.334 7.231c0-1.667 1.25-2.917 2.916-2.917 1.284 0 2.534.825 2.975 1.967h1.559c.433-1.142 1.683-1.967 2.966-1.967 1.667 0 2.917 1.25 2.917 2.917 0 2.408-2.617 4.783-6.583 8.375z"
              id="dopa6oy8yc"
            />
          </defs>
          <g fill="none" fill-rule="evenodd">
            <g>
              <use xlinkHref="#4nd2bbo8ta" fill={color} />
            </g>
            <g transform="translate(6 7)">
              <use xlinkHref="#dopa6oy8yc" fill="#fff" />
            </g>
          </g>
        </svg>
      </div>
    );
}

export { Unfav }