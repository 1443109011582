import React, { useEffect } from "react";
import { getImage } from "utils/utilityfunctions";
import { Route, useNavigate } from "react-router";
import BannerComponent from "components/Banner/BannerComponent";
import { Translate } from "utils/Translate";
import LoadingClassesShimmer from "components/Shimmer/LoadingClassesShimmer";
import Slider from "components/PlayerAndCarousel/Slider/Slider";
import { useSubscriptionDetail } from "hooks/useSubscriptionDetail";
import Routes from "components/Router/Routes";
const SubscriptionBundle = () => {

    const {useSubscriptionDetailState,useSubscriptionDetailDispatch} = useSubscriptionDetail()
    const {getSubscriptionBundleDetails} = useSubscriptionDetailDispatch();
    const {subscriptionBundleDetails,title,description,imageLink} = useSubscriptionDetailState();

  useEffect(() => {
    window.scrollTo(0, 0);
    var path = window?.location?.pathname;
    var pathItems = path.split("/");
    const bundleId = pathItems[2]
    getSubscriptionBundleDetails(bundleId);
    }, []);

  let collectionArray = subscriptionBundleDetails?.collections;
  const src = collectionArray ? (imageLink !== undefined ? imageLink : require("../../assets/images/home.jpg")):null;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(Routes.mySubscriptionsRoute)
  }

  return (
    <div className="main-margin">
      <BannerComponent
        heading={title}
        subHeading={description}
        imageUrl={src}
        topHeading={Translate({
          id: "homePage.MySubscription",
          defaultMessage: "My Releases",
        })}
        topHeadingClass="makeTextClickable"
        handleClick={handleClick}
      ></BannerComponent>

      <div className="collection-list collectionPage main-page-container list-container-gap collection-list-page">
        {collectionArray ? (
          collectionArray.length > 0 &&
          collectionArray.map((collection) => (
            <Slider
              key={collection.tag}
              collectionTag={collection.tag}
              collectionName={collection.title}
              items={collection.items}
              listClass={"list"}
              navigate={navigate}
            />
          ))
        ) : (
          <LoadingClassesShimmer />
        )}
      </div>
    </div>
  );
};

export default SubscriptionBundle;
