import React  from 'react'

function IconRigntArrow(props) {
    const color = props.color
    return (
      <span className={`right-arrow icon-svg ${props.cls}`}>
        <svg
           className="icon-svg-play-list"
           id={props.id}
           xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 32 32`}
         >
           <defs>
             <path
               d="M26.388 14.66H8.828l3.84-3.84a1.328 1.328 0 0 0-.94-2.27c-.352 0-.69.14-.94.39l-6.12 6.12c-.52.52-.52 1.36 0 1.88l6.12 6.12c.52.52 1.36.52 1.88 0 .52-.52.52-1.36 0-1.88l-3.84-3.853h17.56c.734 0 1.334-.6 1.334-1.334 0-.733-.6-1.333-1.334-1.333z"
               id="1plyjd808a"
             />
           </defs>
           <g transform="matrix(-1 0 0 1 32 0)" fill="none" fill-rule="evenodd">
             <use xlinkHref="#1plyjd808a" fill={color} />
           </g>
         </svg>
        </span>
  )
}

export default IconRigntArrow