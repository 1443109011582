import React from 'react'
import PropTypes from 'prop-types'
import IconPlayListDrag from '../../../CommonImages/icon-playlist-drag';
import config from "../../../assets/config.json"

const PlayListInfoDrag = props => {
    const {drag} = props 
  return (
    <div ref={(node) => drag(node)} className='play-list-info-drag'>
        <IconPlayListDrag color={config.primaryColor}/>
    </div>
  )
}

PlayListInfoDrag.propTypes = {}

export { PlayListInfoDrag }