import Account from "../containers/User/Account"
import {
    GetUserSubscription,
    CancelSubscription,
    ResumeSubscription,
    VerifyAndPurchaseFreeSubscription,
    GetSubscriptionProduct,
    getUserSubscriptionDetails,
    GetSubscriptionPortalLink
  } from "../store/actions/subscription";
import {injectIntl} from 'react-intl'; 
import { connect } from "react-redux";
import { getUserActiveSubscription } from "../store/selectors/subscription";
import { logout, GetUserDetail } from "../store/actions/auth";
import { SAVE_DEEPLINK_PAYLOAD } from "../store/actions/actionTypes";
const mapStateToProps = state => {
    return {
      updateUserMsg: state.auth.updateUserMsg,
      // eslint-disable-next-line eqeqeq
      isAuthenticated: state.auth.token != "",
      errorMessage: state.subscription.errorMessage,
      userSubscription: state.subscription.userSubscripton,
      subscriptionDetails:state.subscription.subscriptionDetails,
      subscriptionDetailsLoading: state.subscription.isSubscriptionDetailsLoading,
      userDetail: state.auth.userDetail,
      userActiveSubscription: getUserActiveSubscription(state),
      userDetailLoading: state.auth.loading,
      loading: state.subscription.loading,
      isSubscriptionActive: state.subscription.isSubscriptionActive,
      accessCodeErrorMessage: state.subscription.accessCodeErrorMessage,
      currency: state.subscription.currency,
      customerPortalUrl: state.subscription.customerPortalUrl,
      subscriptionDetailStatusCode: state.subscription.subscriptionDetailStatusCode,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      onLogout: () => dispatch(logout()),
      loadUserDetail: () => dispatch(GetUserDetail()),
      loadUserSubscription: () => dispatch(GetUserSubscription()),
      loadUserSubscriptionDetails: () => dispatch(getUserSubscriptionDetails()),
      cancelUserSubscription: () => dispatch(CancelSubscription()),
      reactivateUserSubscription: (subscriptionTag, planId, productId) =>
        dispatch(ResumeSubscription(subscriptionTag, planId, productId)),
      loadSubscriptionProducts: () => dispatch(GetSubscriptionProduct()),
      saveDeeplinkPayload: (path)=>dispatch({ type: SAVE_DEEPLINK_PAYLOAD, deeplinkData: path }),
      onRenewSubscription: code =>
        dispatch(VerifyAndPurchaseFreeSubscription(code)),
      GetSubscriptionPortalLink:()=>dispatch(GetSubscriptionPortalLink())
  
    };
  };


  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(Account));