import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  addPlayList,
  getPlayList,
  addItemIntoPlayList,
} from "../store/actions/playList";
import { isEmpty } from "lodash";
import { PlayList } from "../utils/constants";
const usePlayList = () => {
  const playList = useSelector((state) => state.playList, shallowEqual);
  const tenantID = useSelector((state) => state.client?.data?.tenantID ?? "");
  const [, , contentId] = window.location.pathname.split("/");
  const dispatch = useDispatch();
  const fetchPlayList = () => {
    dispatch(getPlayList(tenantID, contentId));
  };
  const createPlayList = (name) => {
    dispatch(addPlayList(tenantID, name, contentId));
  };
  const addItemInPlayList = (_, items) => {
    dispatch(addItemIntoPlayList(tenantID, _, items));
  };
  // SHOW NOTIFICATION IF ANY ACTION IS DONE LIKE(ERROR,INSERT,UPDATE)
  const handelNotification = () => {
    if (playList?.msg === PlayList?.msg?.created) return true;
    if (playList?.msg === PlayList?.msg?.updated) return true;
    if (playList?.msg === PlayList?.msg?.deleted) return true;
    if (playList?.error && !isEmpty(playList?.msg)) return true;
    return false;
  };
  const showNotification = handelNotification();
  // SHOW DEFAULT PAGE
  const showList =
    !playList?.list.length && playList?.msg === PlayList?.msg?.get
      ? false
      : true;
  // SHOW NOTIFICATION SEVERITY
  const severity = playList?.error
    ? PlayList?.notification?.severity?.error
    : PlayList?.notification?.severity?.success;

  return {
    fetchPlayList,
    createPlayList,
    addItemInPlayList,
    fetchPlayList,
    playList,
    tenantId: tenantID,
    contentId,
    severity,
    showNotification,
    showList,
  };
};

export { usePlayList };
