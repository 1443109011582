import React, { Component } from 'react'
import "../../theme/styles/image-gallery.css";
import Classes from "../../hoc/ClasslistViewContainer";

class OnDemandPage extends Component {
  render() {
    return (
        <div className="App">
        <Classes/> 
                 {/* {this.props.location.pathname !== '/ondemand/player' &&    
                         <SubscriptionComponent></SubscriptionComponent>
                 } */}
        </div>
    );
  }
}

export default OnDemandPage;
