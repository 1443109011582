import React from "react"

function IconMusicLib(props) {
    const color = props.color
    const classes = props.cls
    return (
        <div className={classes}>
            <svg viewBox="0 0 37 37" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <path d="M30 3H12c-1.65 0-3 1.35-3 3v18c0 1.65 1.35 3 3 3h18c1.65 0 3-1.35 3-3V6c0-1.65-1.35-3-3-3zm-4.5 7.5h-3v8.055c0 1.905-1.35 3.66-3.24 3.9-2.535.345-4.665-1.875-4.2-4.425.3-1.65 1.77-2.925 3.45-3.03.945-.06 1.8.24 2.49.765V9c0-.825.675-1.5 1.5-1.5h3c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5zM4.5 9C3.675 9 3 9.675 3 10.5V30c0 1.65 1.35 3 3 3h19.5c.825 0 1.5-.675 1.5-1.5s-.675-1.5-1.5-1.5h-18c-.825 0-1.5-.675-1.5-1.5v-18C6 9.675 5.325 9 4.5 9z" id="45tadz6zra" />
                </defs>
                <g transform="translate(.5 .5)" fill="none" fill-rule="evenodd">
                    <mask id="i1c9g4urcb" fill="#fff">
                        <use xlinkHref="#45tadz6zra" />
                    </mask>
                    <g mask="url(#i1c9g4urcb)" fill="#36DCB6">
                        <use xlinkHref="#45tadz6zra" />
                        <path d="M0 0h36v36H0z" id="i1c9g4urcb"/>
                    </g>
                </g>
            </svg>
        </div>
    )}

export default IconMusicLib