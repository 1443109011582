import React from "react";
import {Typography, Grid} from "@material-ui/core";
import {PropTypes} from "prop-types";
import {Translate} from "utils/Translate";
import {getImage} from "utils/utilityfunctions";
import InnerBannerComponent from "./InnerBannerComponent";

function BannerComponent(props) {
  const handleClick = () => {
    props.handleClick()
  }
  return (
    <>
      <div className="">
        <Grid container id="channelBg">
          <div className="fixedWidthContainer container channelbg-banner">
            {
              <div className="bg-placeholder transition" >
                <div className="fallbackBox" />
                <img
                  src={props.imageUrl}
                  className="bg-banner"
                  alt=""
                  data-testid={props.imageUrl?"banner-image-id":""}
                />
              </div>
            }
            <div className="overlay banner-overlay">
              <div style={{ zIndex: 1 }} className="page-heading text-white">
                {props.topHeading && props.heading && (
                  <Typography
                    variant="h6"
                    className={`paddingTopHeader font-h6 ${props.topHeadingClass}`}
                    onClick={handleClick}
                  >
                    {props.topHeading}
                  </Typography>
                )}
                <div className="channelbgimg">
                  {props?.titleImage && (
                    <img
                      src={props.titleImage}
                      className="channel-profileImg"
                      alt="profile"
                    />
                  )}

                 {props?.heading && <Typography variant="h1" className="m-t-xs-5 " data-testid="banner-heading-id">
                    {props.heading}
                  </Typography>}
                </div>
                <Grid container direction="row">
                  <Grid
                    item
                    lg={12}
                    md={12}
                    className="channelDescription hidden-xs banner-desc-clamp"
                  >
                      {props?.subHeading && <Typography className="padding16 font-h6" variant="h6" data-testid="banner-sub-heading-id">
                      {props.subHeading}
                    </Typography>}
                  </Grid>
                </Grid>
              </div>
              <div className="inner-banner-component">{props.rightChild}</div>
            </div>
          </div>
        </Grid>
        <div className="pseudo-div"></div>
      </div>
    </>
  );
}
BannerComponent.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
};

export default BannerComponent;
