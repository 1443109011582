import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import "../containers/GlobalSearch/GlobalSearch.scss";
import Close from "@material-ui/icons/Close";
import { Translate } from "utils/Translate";
import { getTranslatedFile } from "utils/utilityfunctions";


function InputSearch(props) {
  const [isError, setError] = useState(true);
  const [value, setValue] = useState("");

  const handleClearInput = () => {
    setValue("")
    const search = {target:{value:""}}
    props.searchItem(search);
  }

  const placeholder = () => {
    if (props.inputSearchPlaceholder === "localSearch") {
      return getTranslatedFile?.ClassListViews?.SearchReleases
        ? getTranslatedFile?.ClassListViews?.SearchReleases
        : "Search releases";
    } else {
      return getTranslatedFile?.ClassListViews?.SearchClasses
        ? getTranslatedFile?.ClassListViews?.SearchClasses
        : "Search classes";
    }
  }


  return (
    <div className={`search-items ${props.cls}`}>
      <div className="comp-search">
        <div className="icon-search">
          <SearchIcon height={24} width={24} />
        </div>
        <div className="text-box">
          <InputBase
            autoFocus={true}
            placeholder={placeholder()}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              document.getElementById("error-msg").innerHTML = "";
              props.searchItem(e);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                if (e.target.value.length <= 1) {
                  document.getElementById("error-msg").innerHTML =
                    "Please enter at least 2 characters to search";
                } else {
                  document.getElementById("error-msg").innerHTML = "";
                  props.searchItem(e);
                }
              }
            }}
          />
        </div>
        <div className="icon-close">
          <Close onClick={(e) => handleClearInput(e)} height={24} width={24} />
        </div>
      </div>
      <div className="error-msg" id="error-msg"></div>
    </div>
  );
}

export default InputSearch;
