import { useSelector } from 'react-redux';
import { useNavigate,useNavigation,useLocation,useMatches,useParams } from 'react-router-dom'; 

const useRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const useRouterState = () => ({
    entitlement: useSelector(state => state.tenantConfig.entitlement) 
  })
  const { entitlement } = useRouterState()
  return { navigate, location, useMatches, useNavigation, useParams, entitlement }
};

export { useRouter };