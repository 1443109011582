import React, { Component } from "react";
import Workout from "hoc/WorkoutContainer";
import "./Slider.css";
import { Grid, Typography } from "@material-ui/core";
import config from "assets/config";
import Routes from 'components/Router/Routes'
import logger from "utils/logger"
import IconRigntArrow from 'CommonImages/icon-rightArrow'

import CustomGrid from "containers/CustomGrid/CustomGrid";
import ResCard from "CardVersions/version1/newcard";

class Slider extends Component {
  constructor(props) {
    super(props);
    this.isAuth = localStorage.getItem("token");
    this.state = {
      isSubscriptionActive: this.props.isSubscriptionActive ? this.props.isSubscriptionActive : false,
      classes: this.props.items || [],
      currentVideoItem: null,
      currentItemId: null
    };
  }

  filterItems = () => {
    return this.props.items.filter(c => {
      if (c.collectionName === this.props.collectionName) {
        return c;
      }
      return false;
    });
  };

  openCollectionPages = () => {
    this.props.navigate(
      Routes.collectionViewRoute + this.props.collectionTag,
      {state: { selectedCollection: this.props.collectionName }});
  };

  getClass = (id) => {
    let reqClass = this.props.items.filter(particularClass => particularClass.tag === id);
    return reqClass[0];
  }

  onClickHandler = (id) => {
    if (!this.isAuth) {
      var callback = () => {
        this.props.navigate( "/signin" );
        return
      }
      this.props.redirectForSSO(callback);
      return
    }
    const item = this.getClass(id);
    const video = this.getVideoObject(item)

    this.setState({
      currentItemId: id,
      currentVideoItem: video
    })

    //var userActiveSubscription = this.state.isSubscriptionActive;
    // if (userActiveSubscription || config.msalSsoSupport) {
      if (config.showChannels === false) {
        this.pushNormalPlayerRoute(video, id)        
      } else {
        if (item.channelId === null || this.props.allChannels == null) {
          this.pushNormalPlayerRoute(video, id)        
        } else {
          /// get channel data
          let channel = this.props.allChannels?.filter( obj => obj.tag === item.channelId)
          if (channel !== null && channel[0] !== null) {
            this.pushChannelPlayerRoute(channel[0], item, id)          
          } else {
            /// channelId exist but data not exist in the local cache
            // this.props.fetchDeeplinkedClassForChannel(id) - this is not working
            this.pushNormalPlayerRoute(video, id)          
          }
        }
      }            
    // } else {
    //   this.props.history.push({ pathname: "/subscribe" });
    // }
  };

  pushNormalPlayerRoute = (item, tag) => {
    this.props.navigate(
      Routes.onDemandClassPlayerRoute + tag,
      { state: { selectedItem: item, prevPath: Routes.onDemandClassPlayerRoute }});
  }

  pushChannelPlayerRoute = (channel, item, tag) => {
    let data = {
      ...channel,
      title: [item]
    }
    this.props.navigate(
      Routes.onDemandClassPlayerRoute + tag,
      {state: {
        selectedItem: data,
        from: 'COLLECTION'
      },
    });
    // this.props.history.push({
    //   pathname: Routes.channelsPlayer + channel.tag + "/classes/" + tag,
    //   state: {
    //       selectedItem: {
    //           ...channel,
    //           title: [item]
    //       }
    //   },
    // });
  }

  getVideoObject = (item) => {
    let equipmentNames=""
    if (item?.equipmentNames&&item.equipmentNames.length === 1)
      equipmentNames= item.equipmentNames
    else if (item?.equipmentNames&&item.equipmentNames.length > 1)
      equipmentNames=item.equipmentNames.join(', ')

    let video = {
      id: item.tag,
      creationDate: item.creationDate ? item.creationDate : "",
      trailerLinkWeb: item.trailerLinkWeb,
      description: item.classDescription
        ? item.classDescription
        : item.description,
      classDescription: item.classDescription
        ? item.classDescription
        : item.description, 
      title: item.className,
      thumbnail: item.imageLink,
      calorieBurn: item.calories,
      duration: item.durationSecond ? item.durationSecond / 60 : item.durationMinutes.split(':')[1],
      durationSecond: item.durationSecond,
      altLink: item.alternateLink,
      fav: item.favourite,
      level: item.level,
      classCategory: item.classCategory,
      newProviderId: item.providerID,
      provider_id: item.provider,
      scheduleDate:item.scheduleDate,
      equipmentNames
    };
    return video;
  }

  checkFavStatus = (id) => {
    logger(id)
    let classes = this.state.classes
    let classIndex = classes.findIndex(particularclass => particularclass.id === id)
    if (classIndex !== -1) {
      classes[classIndex].favourite = !classes[classIndex].favourite
      this.setState({ classes: classes });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSubscriptionActive !== this.props.isSubscriptionActive) {
      this.setState({ isSubscriptionActive: this.props.isSubscriptionActive })
    }
    if (prevProps.channelResponse !== this.props.channelResponse) {
      if (this.props.channelResponse !== null) {
        this.handleFetchClassForChannelResponse()
      }
    }

    if (prevProps.classLoadingFailed !== this.props.classLoadingFailed &&
      this.props.classLoadingFailed === true) {
      this.pushNormalPlayerRoute(
        this.state.currentVideoItem,
        this.state.currentItemId
      )
    }
  }


  handleFetchClassForChannelResponse = () => {
    if (this.props.channelResponse !== null) {
      if (this.props.channelResponse.titleDetails !== null && this.props.channelResponse.channelDetails !== null) {
        var c = this.props.channelResponse.titleDetails;
        let obj = null;
        if (c !== null || c !== undefined) {
          obj = this.getVideoObject(c);
        }
        if (obj !== null || obj !== undefined) {
          this.props.navigate(
            Routes.channelsPlayer + c.tag,
            {state: {
              selectedItem: {
                ...this.props.channelResponse.channelDetails,
                title: [this.props.channelResponse.titleDetails]
              }
            },
          });
        }
      }
    }
  }

  render() {
    const cardVersion = config.cardVersion
    let items = JSON.parse(JSON.stringify(this.state.classes));
    const sortItems = (list)=>{
      // const allList = list
      list.length = list.length > 3 ?  5 : list.length;
      return list;
    }
    const listData = sortItems(items);
    const cardData = {
      collectionType:"featured",
      collectionItems:listData
    }
    return (
      <div>
        <Grid container direction="row" alignItems="center">
          <Grid item className="align-left makeTextClickable adjustTitleWidth" onClick={this.openCollectionPages}>
            <Typography variant="h1" className="align-left">
              {this.props.collectionName}
            </Typography>
          </Grid>
          <Grid item className="makeTextClickable" onClick={this.openCollectionPages}>
            {!this.props.fromHome && <IconRigntArrow id='arrow-3' color={config.primaryColor} cls={"collectionArrow"} />}
          </Grid>          
        </Grid>
            <CustomGrid
                items={listData}
                totalItems={listData.length}
                showLoading={false}
                classes='cardList flexNoWrap'
                containerClass={!this.props.fromHome ? "mobileScroll collection-page-list":"mobileScroll"}
                listClass={this.props.listClass}
                isPageContainer={false}
              >
             {cardVersion === 0.1 && ((item,index) => <Workout
                  collectionType='featured'
                  cardType='live-channel-collection'
                  keywords={item.keywords}
                  isSearchPageCard={false}
                  displayDate={item.scheduleDate}
                  trailerLinkWeb={item.trailerLinkWeb}
                  trailerLinkMobile={item.trailerLinkMobile}
                  isSliderWorkout={true}
                  showFav={this.isAuth ? true : false}
                  thumbnail={`${item.imageLink}`}
                  equipmentTypes={item.equipmentTypes}
                  equipmentNames={item.equipmentNames}
                  isFav={item.favourite}
                  title={item.className}
                  duration={item.durationSecond
                    ? Math.ceil(item.durationSecond / 60)
                    : 55}
                  description={item.classDescription}
                  show={true}
                  calories={item.calorieBurn}
                  id={item.tag}
                  classCategory={item.classCategory}
                  key={this.props.collectionName + index}
                  clicked={() => this.onClickHandler(item.tag)}
                  level={item.level}
                  collectionName={this.props.collectionName}
                  favCallback={() => { this.checkFavStatus(item.tag) }}
                  mediaType={item.mediaType}
                  navigate={this.props.navigate}
                  isUnlocked={item.isUnlocked}
                />)}
            </CustomGrid>
            {cardVersion === 0.0 && <ResCard collection={cardData} takeUserToClassDetail={this.onClickHandler}/>}
      </div>
    );
  }
}

export default Slider;
