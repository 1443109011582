import { useSelector } from "react-redux";

const useSiteConfig = () => {
    const useSiteConfigState = () => ({
        socialLinks: useSelector(state => state.siteConfiguration?.socialLinks?.data),
        appBadgesLinks: useSelector(state => state.siteConfiguration?.appBadgesLinks?.data),
        customLinks: useSelector(state => state.siteConfiguration?.customLinks),
        entitlement: useSelector(state => state.tenantConfig?.entitlement?.footer ?? {} ),
    })
    return { useSiteConfigState }
}
export { useSiteConfig }