 import React from "react"

function IconPlayListDelete(props) {
  const color = props.color
   return (
      <div className="icon-svg">
        <svg
          className="icon-svg-play-list"
          id={props.id}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 32 32`}
        >
          <defs>
            <path
              d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0z"
              id="uz8lvmo61a"
            />
            <path
              d="M5 15.833c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667V7.5c0-.917-.75-1.667-1.667-1.667H6.667C5.75 5.833 5 6.583 5 7.5v8.333zm2.642-6.525a.83.83 0 0 1 1.175 0L10 10.492l1.183-1.184a.83.83 0 1 1 1.175 1.175l-1.183 1.184 1.183 1.183a.83.83 0 1 1-1.175 1.175L10 12.842l-1.183 1.183a.83.83 0 1 1-1.175-1.175l1.183-1.183-1.183-1.184a.83.83 0 0 1 0-1.175zm5.275-5.975-.592-.591a.84.84 0 0 0-.583-.242H8.258a.84.84 0 0 0-.583.242l-.592.591H5a.836.836 0 0 0-.833.834c0 .458.375.833.833.833h10a.836.836 0 0 0 .833-.833.836.836 0 0 0-.833-.834h-2.083z"
              id="q5zmtr2soc"
            />
          </defs>
          <g fill="none" fill-rule="evenodd">
            <g>
              <use xlinkHref="#uz8lvmo61a" />
            </g>
            <g transform="translate(6 6)">
              <use xlinkHref="#q5zmtr2soc" fill="#FFF" />
            </g>
          </g>
        </svg>
      </div>
    );
}

export { IconPlayListDelete };
