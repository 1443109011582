import Slider from "../components/PlayerAndCarousel/Slider/Slider"
import {
    fetchDeeplinkedClassForChannel,
  } from "../store/actions/channels";
  import {redirectForSSO} from "../store/actions/auth"
  import { getUserActiveSubscription } from "../store/selectors/subscription";
  import { connect } from "react-redux";
  import {injectIntl} from 'react-intl'; 

const mapStateToProps = state => {
    return {
      userActiveSubscription: getUserActiveSubscription(state),
      userSubscription: state.subscription.userSubscripton,
      isSubscriptionActive: state.subscription.isSubscriptionActive,
       // eslint-disable-next-line eqeqeq
      isAuthenticated: state.auth.token != "",
      allChannels: state.channels.allChannels,
      channelResponse: state.channels.classes,
      classLoadingFailed: state.channels.classLoadingFailed,
      entitlement: state.tenantConfig.entitlement?.homepageLoggedIn ?? {}
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      fetchDeeplinkedClassForChannel: (id) => dispatch(fetchDeeplinkedClassForChannel(id)),
      redirectForSSO : (callback) => dispatch(redirectForSSO(callback))
    };
  };


  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(Slider));