import React from "react"

function RatingLogo(props) {

    return (
        <div className={props.cls}>
            <svg viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <path d="m21 30.66 7.262 4.392c1.33.805 2.958-.385 2.608-1.89l-1.925-8.26 6.422-5.565c1.173-1.015.543-2.94-.997-3.063l-8.452-.717-3.308-7.805c-.595-1.418-2.625-1.418-3.22 0l-3.308 7.787-8.452.718c-1.54.122-2.17 2.047-.997 3.062l6.422 5.565-1.925 8.26c-.35 1.505 1.277 2.695 2.607 1.89L21 30.66z" id="9bx02tu2ua" />
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <use xlinkHref="#9bx02tu2ua" fill="#000" />
                </g>
            </svg>
        </div>)

}

export default RatingLogo