import { useDispatch, useSelector } from "react-redux";
import { GetUserSubscription } from "../store/actions/subscription";
import { setNavigatedFromWhichScreen } from "../store/actions/auth";


const usePaymentDone = () => {
    const dispatch = useDispatch();
const usePaymentDoneState = () => ({
    isSubscriptionLoading: useSelector(
      (state) => state.subscription.isSubscriptionLoading
    ),
    hasPlayerAccess: useSelector(
      (state) => state.subscription.hasPlayerAccess
    ),
    navigatedFromWhichScreen: useSelector(
      (state) => state.auth.navigatedFromWhichScreen
    ),
  });

const usePaymentDoneDispatch = () => ({
    getUserSubscription: () => {
      dispatch(GetUserSubscription());
    },
    setNavigatedFromWhichScreen: (screen = "") => {
        dispatch(setNavigatedFromWhichScreen(screen));
      },
  });

  return {usePaymentDoneState,
    usePaymentDoneDispatch}
}

export {usePaymentDone}