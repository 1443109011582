import VideoPlayer from "../containers/PlayerAndCorousel/views/Player/VideoPlayer";
import { connect } from "react-redux";
import {
  postWorkoutResult,
  sendPlayDurationToLocalytics,
} from "../store/actions/workout";

import { injectIntl } from "react-intl";
import * as actionTypes from "../store/actions/actionTypes";
import { setPlayerWasOnFullScreen } from "../store/actions/playList";

const mapStateToProps = (state) => {
  return {
    collection: state.onDemand.onDemandCollection,
    ClassDetails: state.workout.ClassDetails,
    classes: [],
    loading: state.onDemand.loading,
    isPlayerOn: state.onDemand.isClassStarted,
    videoUrl: state.onDemand.StreamingVideoLink,
    videoError: state.onDemand.videoError,
    playerWasOnFullScreen: state.playList.playerWasOnFullScreen,
    tracks: state.onDemand.tracks
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitWorkoutResult: (sClass) => dispatch(postWorkoutResult(sClass)),
    clearClassData: () =>
      dispatch({ type: actionTypes.FETCH_CLASSESFORCHANNEL_CLEAR }),
    sendPlayDurationToLocalytics: (time) =>
      dispatch(sendPlayDurationToLocalytics(time)),
    setPlayerWasOnFullScreen: (onfullscreen) =>
      dispatch(setPlayerWasOnFullScreen(onfullscreen)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(VideoPlayer));
