import React from 'react'
import { useIconSize } from '../utils/svgSize';

function Star(props) {
    const getSize = useIconSize(["40px","32px",'32px',"32px"], props.id);
    const color = props.color
    return (<>
        {
            props.fill ? <svg 
    className="makeTextClickable"
    id={props.id}
    onMouseEnter={()=>{props.mouseIn()}}
    onMouseLeave={()=>{props.mouseOut()}}
                width={getSize} height={getSize} viewBox={`0 0 32 32`} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <path
                        d="m16 23.36 5.533 3.346c1.014.613 2.254-.293 1.987-1.44l-1.467-6.293 4.894-4.24c.893-.774.413-2.24-.76-2.334l-6.44-.546-2.52-5.947c-.454-1.08-2-1.08-2.454 0l-2.52 5.933-6.44.547c-1.173.093-1.653 1.56-.76 2.333l4.894 4.24-1.467 6.294c-.267 1.146.973 2.053 1.987 1.44L16 23.359z"
                        id="0xi680phpa"
                    />
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <use xlinkHref="#0xi680phpa" fill={color} />
                </g></svg> :
                <svg
                    className="makeTextClickable"
                    id={props.id}
                    onMouseEnter={() => { props.mouseIn() }}
                    onMouseLeave={() => { props.mouseOut() }}
                    width={getSize} height={getSize} viewBox={`0 0 32 32`} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <defs>
                        <path
                            d="m26.198 12.393-6.453-.56-2.52-5.934c-.454-1.08-2-1.08-2.454 0l-2.52 5.947-6.44.547c-1.173.093-1.653 1.56-.76 2.333l4.894 4.24-1.467 6.293c-.267 1.147.973 2.054 1.987 1.44l5.533-3.333 5.533 3.347c1.014.613 2.254-.294 1.987-1.44l-1.467-6.307 4.894-4.24c.893-.773.426-2.24-.747-2.333zm-10.2 8.48-5.013 3.026 1.333-5.706-4.427-3.84 5.84-.507 2.267-5.373 2.28 5.386 5.84.507-4.427 3.84 1.334 5.707-5.027-3.04z"
                            id="so19wbfd8a"
                        />
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <use xlinkHref="#so19wbfd8a" fill={color} />
                    </g>
                </svg>
        }
    </>


  )
}

export { Star }