import * as actionTypes from './actionTypes'

export const setselectedFilters = (filterValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_SELECTED_FILTERS,
            value: filterValue
        });
    };
};

export const setKeywords = (keywordsValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_KEYWORDS,
            value: keywordsValue
        });
    };
};

export const setCategories = (categoryValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTERED_SUB_CATEGORY,
            value: categoryValue
        });
    };
};

export const setDuration = (durationValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_DURATION,
            value: durationValue
        });
    };
};

export const setSkillLevel = (skillValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_SKILL,
            value: skillValue
        });
    };
};

export const setFocusArea = (focusAreaValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_FOCUS_AREA,
            value: focusAreaValue
        });
    };
};

export const setEquipmentsTypes = (equipmentsValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_EQUIPMENTS_TYPES,
            value: equipmentsValue
        });
    };
};

export const setIntensityLevel = (intensityValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_INTENSITY,
            value: intensityValue
        });
    };
};

export const setClassLanguage = (languageValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_CLASS_LANGUAGE,
            value: languageValue
        });
    };
};

export const setSort = (sortValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_SORT,
            value: sortValue
        });
    };
};

export const setFilterSearchText = (searchValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_SEARCH_TEXT,
            value: searchValue
        });
    };
};

export const setFilteredClassSearchData = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTERED_CLASS_SEARCH_DATA,
            value: value
        });
    };
};

export const setFilteredTopKeywords = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTERED_TOP_KEYWORDS,
            value: value
        });
    };
};
export const setPageCount = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_PAGE_COUNT,
            value: value
        });
    };
};

export const setPaginatedData = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_PAGINATED_DATA,
            value: value
        });
    };
};

export const setClassesSearchData = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_CLASSES_SEARCH_DATA,
            value: value
        });
    };
};

export const settempClassSearchData = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_TEMP_CLASS_SEARCH_DATA,
            value: value
        });
    };
};

export const setTopKeywords = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_TOP_KEYWORDS,
            value: value
        });
    };
};

export const resetFilters = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.RESET_FILTERS,
        });
    };
};

export const setMediaType = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_MEDIA_TYPE,
            value: value
        });
    };
};