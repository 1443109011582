import React, { useEffect } from 'react';
import Portal from "../../Portal/Portal";
import {
    ModalOverVideo
} from "../../Notification/StripOverVideo";
import Routes from '../../../components/Router/Routes'
import { getPayload } from '../../../utils/LogClassUtilites';
import logger from "../../../utils/logger";
import { useNavigate } from 'react-router';

function VideoWrapperToLogClass(props) {
    const { classDetails, currentTime } = props;
    const navigate = useNavigate();
    useEffect(() => {
        logger("leave modal")
    }, [])

    const resumeHandler = () => {
        //this.player.play();

    };

    const discardHandler = () => {
        if (!props.fromDeepLink) {
            navigate(-1);
        } else {
            navigate( Routes.homePage);
        }
    };

    const logClassHandler = () => {
        props.callback();
        props.onSubmitWorkoutResult(getPayload(classDetails, 9, parseInt(currentTime)));
    };

    return (
        <React.Fragment>
            <Portal>
                <ModalOverVideo
                    class={props.class}
                    doNotShowCloseButton={true}
                    resumeHandler={() => resumeHandler()}
                    elapsedTime={currentTime}
                    discardHandler={() => discardHandler()}
                    logClassHandler={() => logClassHandler()}
                />
            </Portal>
        </React.Fragment >
    );
}

export default VideoWrapperToLogClass