import React from "react";
function PDFDownload(props) {
  const color = props.color;
  return (
    <div className="pdf-download-icons">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        className="icon-pdf-download"
      >
        <path
          d="M26.667 2.667h-16A2.675 2.675 0 0 0 8 5.333v16C8 22.8 9.2 24 10.667 24h16c1.466 0 2.666-1.2 2.666-2.667v-16c0-1.466-1.2-2.666-2.666-2.666zm-11.334 10c0 1.106-.893 2-2 2H12v1.666c0 .547-.453 1-1 1-.547 0-1-.453-1-1v-5.666c0-.734.6-1.334 1.333-1.334h2c1.107 0 2 .894 2 2v1.334zM22 15.333c0 1.107-.893 2-2 2h-2.667a.66.66 0 0 1-.666-.666V10a.66.66 0 0 1 .666-.667H20c1.107 0 2 .894 2 2v4zm5.333-5c0 .547-.453 1-1 1h-1v1.334h1c.547 0 1 .453 1 1 0 .546-.453 1-1 1h-1v1.666c0 .547-.453 1-1 1-.546 0-1-.453-1-1v-5.666c0-.734.6-1.334 1.334-1.334h1.666c.547 0 1 .454 1 1zM12 12.667h1.333v-1.334H12v1.334zM4 8c-.733 0-1.333.6-1.333 1.333v17.334c0 1.466 1.2 2.666 2.666 2.666h17.334c.733 0 1.333-.6 1.333-1.333s-.6-1.333-1.333-1.333h-16c-.734 0-1.334-.6-1.334-1.334v-16C5.333 8.6 4.733 8 4 8zm14.667 7.333H20v-4h-1.333v4z"
          fill="#C9C9D0"
          fill-opacity=".87"
        />
      </svg>
    </div>
  );
}
export {PDFDownload};
