import React from "react"

function IconPause(props) {
    const opacity = props.opacity
    const classes = props.cls
    const pauseTrack = props.pauseTrack

    return (
        <div className={classes} onClick={() => pauseTrack()}>
            <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <path d="M16 38c2.2 0 4-1.8 4-4V14c0-2.2-1.8-4-4-4s-4 1.8-4 4v20c0 2.2 1.8 4 4 4zm12-24v20c0 2.2 1.8 4 4 4s4-1.8 4-4V14c0-2.2-1.8-4-4-4s-4 1.8-4 4z" id="58nsj1cbva" />
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <mask id="7xr10duz1b" fill="#fff">
                        <use xlinkHref="#58nsj1cbva" />
                    </mask>
                    <g mask="url(#7xr10duz1b)" fill="#000" fillOpacity={opacity}>
                        <path d="M0 0h48v48H0z" />
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default IconPause