import React from "react";

function PlayListIcon(props) {



  const color = props.color
  return (
    <div className="icon-svg">
      <svg
        className="icon-svg-play-list"
        id={props.id}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 32 32`}
      >
        <defs>
          <path d="M32 16a16 16 0 1 1-32 0 16 16 0 1 1 32 0" id="gytgv7vpoa" />
          <path
            d="M10.833 7.5H2.5a.836.836 0 0 0-.833.833c0 .459.375.834.833.834h8.333a.836.836 0 0 0 .834-.834.836.836 0 0 0-.834-.833zm0-3.333H2.5A.836.836 0 0 0 1.667 5c0 .458.375.833.833.833h8.333A.836.836 0 0 0 11.667 5a.836.836 0 0 0-.834-.833zM15 10.833v-2.5a.836.836 0 0 0-.833-.833.836.836 0 0 0-.834.833v2.5h-2.5a.836.836 0 0 0-.833.834c0 .458.375.833.833.833h2.5V15c0 .458.375.833.834.833A.836.836 0 0 0 15 15v-2.5h2.5a.836.836 0 0 0 .833-.833.836.836 0 0 0-.833-.834H15zM2.5 12.5h5a.836.836 0 0 0 .833-.833.836.836 0 0 0-.833-.834h-5a.836.836 0 0 0-.833.834c0 .458.375.833.833.833z"
            id="gjzv508cvc"
          />
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g>
            <use xlinkHref="#gytgv7vpoa" fill={color} />
          </g>
          <g transform="translate(7 7)">
            <use xlinkHref="#gjzv508cvc" fill="#fff" />
          </g>
        </g>
      </svg>
    </div>
  );
}

export { PlayListIcon };
