import React from "react";
import ChannelsCard from "containers/Channels/ChannelsCard";
import Routes from 'components/Router/Routes'
import { useState, useEffect } from "react";
// import ChannelsNewCard from "../../CardVersions/version1/channelNewCard";
import config from "assets/config.json"
import { useNavigate } from "react-router";

function ChannelsCardWrapper(props) {

  const { data,cardType } = props;
  const [description, setDescription] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const cardVersion = config.cardVersion
  const navigate = useNavigate();


  useEffect(() => {

    if (data !== null) {
      let channelDescription = data.description.invariantText;
      let channelDisplayName = data.displayName.invariantText;
      if (channelDescription === undefined && channelDisplayName === undefined) {
        setDescription(data.description);
        setDisplayName(data.displayName)
      }
      else {
        setDisplayName(channelDisplayName)
        setDescription(channelDescription)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewChannel = () => {
    navigate( Routes.channelDetailView + data.tag, // RR : Routing to channele detail with channel tag, It will work for deeplink as well
            { state: { clubClasses: data } } );
  };

  return (
   <>
  { cardVersion === 0.1 && <ChannelsCard
      data={data}
      cardType={cardType}
      redirectTo={viewChannel}
      displayName={displayName}
      profileImageUrl={data.profileImageUrl}
      description={description}
      streamDescription={data.title[0].classDescription}
      level={data.title[0].level}
      trailerLinkWeb={data.title[0].trailerLinkWeb}
      duration={data.title[0].durationSecond}
      created_Time={data.title[0].scheduleDate}
      thumbnail={data.title[0].imageLink}
      title={data.title[0].className}
      trailerLinkMobile={data.title[0].trailerLinkMobile}
      id={data.title[0].tag}
      isFav={data.title[0].favourite}
          />}
    </>
  );

}

export default ChannelsCardWrapper;
