import React from 'react';
import NotFound from 'components/404/404';
import BrowseCollections from 'containers/PlayerAndCorousel/views/ClasslistView/BrowseCollections';
import loadable from '@loadable/component';
import Loading from "../spinner/ComponentLoader";
import ChannelsCollectionContainer from 'hoc/ChannelsCollectionContainer';
import WrapperList from 'containers/LiveConnect/WrapperList';
import ProfileWrapper from 'containers/User/ProfileWrapper';
import ChannelViewContainer from 'hoc/ChannelViewContainer';
import { useNavigate } from 'react-router';
import DefaultClassesPage from 'containers/BrowseClasses/DefaultClassesPage';
import { useSelector } from 'react-redux';
import Categories from 'containers/BrowseClasses/Categories';

const ClassCollection = loadable(() => import("../../hoc/NewCollectionPagesContainer"), {
    fallback: Loading,
});

const RouterEntitlementManager = (props) => {

    const navigate = useNavigate()

    const routerEntiltlement = useSelector((state) => state.tenantConfig?.routerEntiltlement )
    
    const location = (props.location.pathname)

    const checkEntitlement = (entitlementPage, element, redirection, redirectTo) => {
        const entitlementKey = entitlementPage.toLowerCase()
        if (routerEntiltlement[entitlementKey]) {
           return element
        } else if (redirection) {
            navigate(redirectTo)
        } else {
           return <NotFound />
        }
    }

    switch (true) {

        case location.includes("user/subscription"):
            return checkEntitlement("mysubscription", <ProfileWrapper {...props} />);

        case location.includes("user/classes/recent"):
            return checkEntitlement("recentActivity", <ProfileWrapper {...props} />);

        case location.includes("user/classes/favourites"):
            return checkEntitlement("favourite", <ProfileWrapper {...props} />);

        case location.includes("user/reviews"):
            return checkEntitlement("rating", <ProfileWrapper {...props} />);

        case location.includes("user/playlist"):
            return checkEntitlement("playlist", <ProfileWrapper {...props} />);

        case location.includes("subscription"):
            return checkEntitlement("subscription", <ProfileWrapper {...props} />);
        
        case location.includes("connect/classes"):
            return checkEntitlement("ondemand", <WrapperList {...props} />);

        case location.includes("connect/schedule"):
            return checkEntitlement("live", <WrapperList {...props} />);

        case location.includes("classes/categories"):
            return checkEntitlement("category", <Categories {...props} />);

        case location.includes("classes"):
            return checkEntitlement("category", <DefaultClassesPage {...props} />);
   
        case location.includes("channels/"):
            return checkEntitlement("channel", <ChannelViewContainer {...props} />);
                
        case location.includes("channels"):
            return checkEntitlement("channel", <ChannelsCollectionContainer {...props} />);

        case location.includes("collections/"):
            return checkEntitlement("collection", <ClassCollection {...props} />);
            
        case location.includes("collections") || location.includes("collection"):
            return checkEntitlement("collection", <BrowseCollections {...props} />);


        default:
            return <NotFound />;
    }

}

export default RouterEntitlementManager