import ClasslistViewRecent from  "../containers/PlayerAndCorousel/views/ClasslistView/ClasslistViewRecent";
import { getUserRecentActivity, putWorkoutResult } from "../store/actions/workout";
import {
  searchOnDemandClass,
  FetchClubLiveConnect,
  FetchLSVideoDetails,
  fetchConnectDeepLinkClass
} from "../store/actions/ondemand";
import {
  fetchDeeplinkedClassForChannel,
} from "../store/actions/channels";
import {injectIntl} from 'react-intl'; 
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
      recentActivities: state.workout.recentActivities,
      collectionObject: state.onDemand.onDemandCollection,
      collection: state.onDemand.recentOnDemandClasses,
      userId: state.auth.userId,
       // eslint-disable-next-line eqeqeq
      isAuthenticated: state.auth.token != "",
      loading: state.workout.loading,
      clubClasses: state.onDemand.clubClasses,
      LSVideoDetails: state.onDemand.LSVideoDetails,
      connectClassDataByID: state.onDemand.deeplinkClass,
      channelResponse: state.channels.classesData,
      classLoadingFailed: state.channels.classLoadingFailed,
      isClassesLoading: state.channels.isClassesLoading
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      OngetUserRecentActivity: () => dispatch(getUserRecentActivity()),
      putWorkoutResult: (data, tag) => dispatch(putWorkoutResult(data, tag)),
      onSearchOndemand: criteria => dispatch(searchOnDemandClass(criteria, true)),
      fetchClubLiveConnectClasses: () => dispatch(FetchClubLiveConnect()),
      FetchClubLiveConnect: (id) => dispatch(fetchConnectDeepLinkClass(id)),
      FetchLSVideoDetails: (event_id, videoId) => dispatch(FetchLSVideoDetails(event_id, videoId)),
      fetchDeeplinkedClassForChannel: (id) => dispatch(fetchDeeplinkedClassForChannel(id))
    };
  };

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(ClasslistViewRecent));