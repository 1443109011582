import React from "react";
import { Translate } from "../../../utils/Translate";


export default function NewCard(props) {
  const { message, cls } = props
  return (
    <label className={`bubble-tag ${cls}`}>
      {Translate({ id: `${message}` })}
    </label>
  );
}

