import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";


const initialState = {
    userClassRating: null,
    userClassRatingAll: null
  };
  
  const fetchUserRating = (state, action) => {
    return updateObject(state, {
      userClassRating: action.data
    });
  };
  const fetchAllUserRatingStart = (state, action) => {
    return updateObject(state, {
      loading:true,
      userClassRatingAll: null
    });
  };

  const fetchAllUserRatingSuccess = (state, action) => {
    return updateObject(state, {
      loading:false,
      userClassRatingAll: action.data
    });
  };

  const updateUserRating = (state, action) => {
    return updateObject(state, {
      reloadMyReviews: action.reload
    })
  }

  const reducer = (state = initialState, action) => {
    switch (action.type) {
      //fetching All Ratings of User
      case actionTypes.FETCH_ALL_USER_RATING_START:
        return fetchAllUserRatingStart(state, action);
      case actionTypes.FETCH_ALL_USER_RATING_SUCCESS:
        return fetchAllUserRatingSuccess(state, action);
      //fetching All Ratings of User
      case actionTypes.FETCH_USER_RATING_SUCCESS:
        return fetchUserRating(state, action);
      case actionTypes.FETCH_USER_RATING_FAIL:
        return fetchUserRating(state, action);
      case actionTypes.UPDATE_USER_RATING_FAIL:
        return updateUserRating(state, action);
      case actionTypes.UPDATE_USER_RATING_SUCCESS:
        return updateUserRating(state, action);
        
      default:
        return state;
    }
  };
  
  export default reducer;