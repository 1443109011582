import React from "react";
import PropTypes from "prop-types";
import IconPlayListEdit from "../../../CommonImages/icon-playlist-edit";
import { IconPlayListDelete } from "../../../CommonImages/icon-playlist-delete";
import { PlayListEditModal } from "./PlayListEditModal";
import { PlayListDeleteModal } from "./PlayListDeleteModal";
import config from "../../../assets/config.json";

const PlayListInfoEditDelete = (props) => {
  const { handelModal, modal, modalDelete } = props;
  const openModal = () => handelModal(true);
  const openDeleteModal = () => handelModal(true, "delete");
  const handleClose = () => handelModal(false);
  const handleDeleteClose = () => handelModal(false, "delete");
  return (
    <>
      {modal && <PlayListEditModal {...{ ...props, handleClose }} />}
      {modalDelete && (
        <PlayListDeleteModal
          {...{ ...props, handleClose: handleDeleteClose }}
        />
      )}
      <div
        onClick={openDeleteModal}
        className="play-list-info-btn-container"
        data-testid={"deleteButton"}
      >
        <IconPlayListDelete id="play-list-delete-icon" color={config.primaryColor} />
      </div>
      <div
        onClick={openModal}
        className="play-list-info-btn-container edit-modal"
        data-testid={"editDeleteModal"}
      >
        <IconPlayListEdit id="play-list-edit-icon" color={config.primaryColor} />
      </div>
    </>
  );
};
PlayListInfoEditDelete.defaultProps = {
  modal: false,
  modalDelete: false,
  savePlayList: () => {},
  handelModal: () => {},
};
PlayListInfoEditDelete.propTypes = {
  savePlayList: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  modalDelete: PropTypes.bool.isRequired,
};

export { PlayListInfoEditDelete };
