import React  from 'react'

function IconSearch(props) {
    const {color="#000",background = "#fff",height="28",width="28"} = props
    return (
            <svg width={`${width}`} height={`${height}`} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <path d="M18.64 16.883h-.922l-.327-.315a7.584 7.584 0 0 0 1.727-6.23c-.549-3.243-3.255-5.833-6.522-6.23a7.589 7.589 0 0 0-8.482 8.482c.397 3.267 2.987 5.973 6.23 6.522a7.584 7.584 0 0 0 6.23-1.727l.315.327v.921l4.959 4.959c.478.478 1.26.478 1.738 0a1.232 1.232 0 0 0 0-1.739l-4.947-4.97zm-7 0a5.243 5.243 0 0 1-5.25-5.25 5.243 5.243 0 0 1 5.25-5.25 5.243 5.243 0 0 1 5.25 5.25 5.243 5.243 0 0 1-5.25 5.25z" id="n2zwhsvu6a"/>
                </defs>
                <g fill="none" fillRule="evenodd">
                    <mask id="x0lashjhsb" fill={`${background}`}>
                        <use xlinkHref="#n2zwhsvu6a"/>
                    </mask>
                    <g mask="url(#x0lashjhsb)" fill={`${color}`} fillOpacity=".87">
                        <path d="M0 0h28v28H0z"/>
                    </g>
                </g>
            </svg>
    );
}
export default IconSearch;