import React from 'react'
import { Translate } from './Translate';
import Timer from 'react-compound-timer';
import moment from "moment";

export function getCardsDate(d){
    let date = new Date(d)
    const currDate = new Date()
    if (date.getDate() === currDate.getDate()
        && date.getMonth() === currDate.getMonth()
        && date.getYear() === currDate.getYear()) { return Translate({ id: "liveStream.Today" }) }

    else {
        // const dateParts = date.toString().split(' ')
        // return `${dateParts[2]} ${dateParts[1]}`
        return moment(date).format("DD MMM")
    } 
}
export function getNextOccurenceDate(status, activeTiming, occuranceTiming) {
    // const date = prop.split('T')[0]
    let occDate
    if (status === "streaming")
        occDate = new Date(occuranceTiming+"T"+activeTiming)
    else
        occDate = new Date(occuranceTiming+"T"+activeTiming)
        const currDate = new Date()
    if (occDate.getDate() === currDate.getDate()
        && occDate.getMonth() === currDate.getMonth()
        && occDate.getYear() === currDate.getYear()) { return Translate({ id: "liveStream.Today" }) }
    else {
        try{
            return moment(occDate).format("dddd DD MMMM") 
        } catch(e){
            return moment().format("dddd DD MMMM")
        }
    }
            //moment(occDate).format("MMM DD")
        // const dateParts = occDate.toString().split(' ')
        // return `${dateParts[1]} ${dateParts[2]}`
    }

export function getTimetoStart(rawDate,time) {
    const stringDate = `${rawDate}T${time}`
    const date = new Date(stringDate);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
     // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    let timeWithAmPm = (hours<10?'0':'')+hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;
    return timeWithAmPm;
}
export function classStarted(startTime, isStreaming) {
    if (startTime != null && new Date(startTime) < new Date() && isStreaming === true) {
        const passTime = new Date(startTime).getTime()
        const currTime = new Date().getTime()
        const diff = ((currTime - passTime));
        return (
            <Timer formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`} initialTime={diff || 0} >
                <span>{Translate({ id: "liveStream.Join" })} </span>
                {diff / 3600000 >= 1 &&
                    <React.Fragment>
                        <Timer.Hours />:
                </React.Fragment>
                }
                <Timer.Minutes />{':'}
                <Timer.Seconds />
            </Timer>)
    }
}
export const isSLClassStarted = (scheduleDate)=>{
    try{
        return new Date() >= new Date(scheduleDate)
    }
    catch(e){
        return false;
    }
}
