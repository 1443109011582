import { useDispatch } from "react-redux";
import {
  collectionListClasses,
  fetchOnDemandClasses,
} from "../store/actions/ondemand";

const useBrowseCollections = () => {
  const dispatch = useDispatch();
  const useBrowseCollectionsDispatch = () => ({
    // fetchOnDemandClasses: (userId) => {
    //   dispatch(fetchOnDemandClasses(userId));
    // },
    collectionListClasses: (data) => {
      dispatch(collectionListClasses(data));
    },
  });

  return { useBrowseCollectionsDispatch };
};
export { useBrowseCollections };
