import * as actionTypes from "./actionTypes";
import * as APIConstants from "../APIConstants";
import userApi from "../../userAPI";
import { Translate } from "../../utils/Translate";

const config = require("../../assets/config.json")

  export const fetchUserRating = (classId="") => {
 
    return dispatch => {
    let url = APIConstants.fetchUserRating
    let body = {
      contentId: classId,
      tenantId:config.name,
    }
    classId === "" && dispatch({type: actionTypes.FETCH_ALL_USER_RATING_START})
    userApi.post(url, body).then(response =>{
      if(response.ok){
        classId ?
        dispatch({
          type: actionTypes.FETCH_USER_RATING_SUCCESS,
          data:response.data ? response.data[0] : {
            tag:classId,
            rating:0,
            reviewComment:"",
          }
        }):
        dispatch({
          type: actionTypes.FETCH_ALL_USER_RATING_SUCCESS,
          data:response.data
        });
      }else{
        dispatch({
          type: actionTypes.FETCH_USER_RATING_FAIL,
          data:null
        });
      }
    })   
    };
  };
  export const resetRatingModalData = () => {
 
    return dispatch => {
        dispatch({
          type: actionTypes.FETCH_USER_RATING_FAIL,
          data:null
        });
    };
  };

  export const updateUserRating = (classId,rating,review) => {
    return dispatch => {
    let url = APIConstants.updateUserRating
    let body = {
      contentId: classId,
      tenantId:config.name,
      Rating: rating,
      Review: review,
    }
    
    userApi.post(url, body).then(response =>{
      if(response.ok){
        dispatch({
          type: actionTypes.NOTIFY_USER,
          NotificationText: Translate({
            id: 'RatingsReviews.RatingSaved'
          })
        });
        dispatch({
          type:actionTypes.UPDATE_USER_RATING_SUCCESS,
          reload:true
        })
      }else{
        dispatch({
          type: actionTypes.NOTIFY_USER,
          NotificationText: "Something went wrong !",
          NotificationType: 'error'
        });
        dispatch({
          type:actionTypes.UPDATE_USER_RATING_FAIL,
          reload:false
        })
      }
    })   
    };
  };

  export const resetReloadingReviews = () => {
 
    return dispatch => {
      dispatch({
        type:actionTypes.UPDATE_USER_RATING_FAIL,
        reload:false
      })
    };
  };

