import React from "react"

function IconSkip(props) {
    const opacity = props.opacity
    const classes = props.cls
    const skip = props.skip

    return (
        <div className={classes} onClick={() => skip()}>
            <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <path d="M13.855 13.64v20.72c0 1.58 1.74 2.54 3.08 1.68l16.28-10.36c1.24-.78 1.24-2.58 0-3.38l-16.28-10.34c-1.34-.86-3.08.1-3.08 1.68z" id="tytspf9eea" />
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <g>
                        <mask id="ycju0r8mnb" fill="#fff">
                            <use xlinkHref="#tytspf9eea" />
                        </mask>
                        <g mask="url(#ycju0r8mnb)" fill="#000" fill-opacity={opacity}>
                            <path d="M0 0h48v48H0z" />
                        </g>
                    </g>
                    <rect stroke="#B3B3B4" fill="#000" x="34.58" y="12.02" width="2.6" height="24.2" rx="1" fill-opacity={opacity} />
                </g>
            </svg>

        </div>
    )
}

export default IconSkip