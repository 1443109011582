import { useDispatch, useSelector } from "react-redux";
import { fetchUserRating, resetRatingModalData, resetReloadingReviews, updateUserRating } from "../store/actions/ratingReview";


const useRatingReviews = () => {
    const dispatch = useDispatch();
    const useRatingReviewsState = () => ({
        userClassRating : useSelector(state => state.ratingReviews.userClassRating),
        loading : useSelector(state => state.ratingReviews.loading),
        userClassRatingAll : useSelector(state => state.ratingReviews.userClassRatingAll),
        reloadMyReviews : useSelector(state => state.ratingReviews.reloadMyReviews),
    });
    const useRatingReviewsDispatch = () => ({
        fetchUserRating: (classId)=>{
            dispatch(fetchUserRating(classId))
        },
        resetRatingModalData: ()=>{
            dispatch(resetRatingModalData())
        },
        resetReloadingReviews: ()=>{
            dispatch(resetReloadingReviews())
        },
        updateUserRating: (classId,rating,review)=>{
            dispatch(updateUserRating(classId,rating,review))
        }
    })

    const initialState = {
        classId : null,
        title : null ,
        showDailog : false,
        rating:null,
        hover:null,
        comment:"",
        shrink:false
    }

    const useMyReviewAction = {
        UPDATE_MYREVIEW : "UPDATE_MYREVIEW"
    }

    const useMyReviewReducer = (state, action) =>{
        const { type, payload } = action;
        switch (type) {
            case useMyReviewAction.UPDATE_MYREVIEW:
                return { ...state , ...payload}

            default:
                return state
        }
    }

    const setReducerData = (myReviewDispatch,payload)=>{
        myReviewDispatch({
            type: useMyReviewAction.UPDATE_MYREVIEW,
            payload: payload
        })
    }

    return {useRatingReviewsState,
        useRatingReviewsDispatch,
        myReviewReducer : { initialState,useMyReviewReducer,setReducerData }
        }
}
export {useRatingReviews}