import React from "react"

function Intensity(){
    const src = `<svg width="29" height="41" viewBox="0 0 29 41" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.066.143 2.285 20.648l9.436 2.595L-.095 41.088l26.96-20.802-10.082-2.492z" fill="%23000" fillRule="evenodd"/>
    </svg>`
return(
    <img className="intesity-logo"
    src={`data:image/svg+xml;utf8,${src}`}
    alt="intensity-logo" />
)}

export default Intensity