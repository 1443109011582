import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";

const PlaylistShimmer = (props) => {
  const items = [...Array(5)];
  return (
    <div data-testid={"playlistShimmer"}>
      {items.map((_, index) => (
        <div className="playlist-page-shimmer" key={index}>
          <div className="playlist-page-shimmer-item">
            <Skeleton variant="rect" width={"100%"} height={100} />
          </div>
          {index < 4 ? <div className="line"></div> : null}
        </div>
      ))}
    </div>
  );
};

PlaylistShimmer.propTypes = {};

export { PlaylistShimmer };
