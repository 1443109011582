import React from "react";
import { useIconSize } from "../utils/svgSize";

function IconPlayListEdit(props) {
  const color = props.color 
  return (
    <div className="icon-svg">
      <svg
        className="edit-icon-svg-play-list"
        id={props.id}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 32 32`}
      >
        <defs>
          <path
            d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0z"
            id="uz8lvmo61a"
          />
          <path
            d="M2.499 14.551v2.533c0 .234.183.417.417.417h2.533a.39.39 0 0 0 .292-.125l9.1-9.092-3.125-3.125-9.092 9.092a.41.41 0 0 0-.125.3zm14.758-8.683a.83.83 0 0 0 0-1.175l-1.95-1.95a.83.83 0 0 0-1.175 0l-1.525 1.525 3.125 3.125 1.525-1.525z"
            id="gjzv508cvc"
          />
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g>
            <use xlinkHref="#uz8lvmo61a"/>
          </g>
          <g transform="translate(8 8) scale(0.8)">
            <use xlinkHref="#gjzv508cvc" fill="#fff" />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default IconPlayListEdit;
