import * as actionTypes from "./actionTypes";
import * as APIConstants from "../APIConstants";
import userApi from "../../userAPI";
import { filter, find, includes, map, reject } from "lodash";
import { apiStatusCode, PlayList } from "../../utils/constants";


export const fitRadioRoyaltyApiCallTestPersist = (body) => {
   return (dispatch, getState) => {
   dispatch({
     type: actionTypes.FIT_RADIO_ROYALTY_START,
     payload: {
       error: false,
       msg: "",
       loading: true,
     },
   });
    const url = APIConstants.fitRadioRoyalty;
    userApi
      .post(url, body)
      .then((response) => {
        if (includes(apiStatusCode.success, response.status))
         {
            dispatch({
              type: actionTypes.FIT_RADIO_ROYALTY_SUCCESS,
              payload: {
                royalty: "stored persist",
                error: false,
                msg: PlayList.msg.get,
              },
            });
         } 
        else {
           const {
             fitRadioRoyalty: { pendingRoyaltyCalls },
           } = getState();
           console.log(pendingRoyaltyCalls, "pendingRoyaltyCalls");
            //throw new Error(response?.data);
            dispatch(
              updatePendingRoyaltyCalls([
                ...getState().fitRadioRoyalty.pendingRoyaltyCalls,
                body,
              ])
            );
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.FIT_RADIO_ROYALTY_ERROR,
          payload: { error: true, msg: PlayList.msg.error },
        });
        dispatch(
          updatePendingRoyaltyCalls([
            ...getState().fitRadioRoyalty.pendingRoyaltyCalls,
            body,
          ])
        );
      })
      .finally(() => {
        dispatch({
          type: actionTypes.FIT_RADIO_ROYALTY_END,
          payload: { loading: false, msg: "", error: false },
        });
      });
  };
};

export const fitRadioRoyaltyApiCall = (body,isFirstTime) => {
   return (dispatch, getState) => {
   dispatch({
     type: actionTypes.FIT_RADIO_ROYALTY_START,
     payload  : {
       error: false,
       msg: "",
       loading: true,
     },
   });
    const url = APIConstants.fitRadioRoyalty;
    userApi
      .post(url, body)
      .then((response) => {
        if (includes(apiStatusCode.success, response.status))
         {
            dispatch({
              type: actionTypes.FIT_RADIO_ROYALTY_SUCCESS,
              payload: {
                royalty: "stored persist",
                error: false,
                msg: PlayList.msg.get,
              },
            });
         } 
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.FIT_RADIO_ROYALTY_ERROR,
          payload: { error: true, msg: PlayList.msg.error },
        });
        if (isFirstTime) { 
         dispatch(
           updatePendingRoyaltyCalls([
             ...getState().fitRadioRoyalty.pendingRoyaltyCalls,
             body,
           ])
         );
        }
       
      })
      .finally(() => {
        dispatch({
          type: actionTypes.FIT_RADIO_ROYALTY_END,
          payload: { loading: false, msg: "", error: false },
        });
      });
  };
};
export const fitRadioRoyaltyNextApiCall = (body) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.FIT_RADIO_ROYALTY_START,
      payload: {
        error: false,
        msg: "",
        loading: true,
      },
    });
    const url = APIConstants.fitRadioRoyalty;
    userApi
      .post(url, body)
      .then((response) => {
        if (includes(apiStatusCode.success, response.status)) {
          dispatch({
            type: actionTypes.FIT_RADIO_ROYALTY_SUCCESS,
            payload: {
              royalty: "stored persist",
              error: false,
              msg: PlayList.msg.get,
            },
          });
          dispatch({
            type: actionTypes.FIT_RADIO_ROYALTY_PENDING_CALLS,
            payload: {
              pendingRoyaltyCalls: getState()
                   .fitRadioRoyalty.pendingRoyaltyCalls.filter(
                     (royaltyCall) =>
                       royaltyCall.trackId !== body.trackId
                   )
            },
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.FIT_RADIO_ROYALTY_ERROR,
          payload: { error: true, msg: PlayList.msg.error },
        });
      })
      .finally(() => {
        dispatch({
          type: actionTypes.FIT_RADIO_ROYALTY_END,
          payload: { loading: false, msg: "", error: false },
        });
      });
  };
};
export const updateSkips = (skips) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.FIT_RADIO_ROYALTY_UPDATE_SKIPS,
      payload: {
        skips: skips,
      },
    });
  };
};

export const updatePendingRoyaltyCalls = (pendingCalls) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.FIT_RADIO_ROYALTY_PENDING_CALLS,
      payload: {
        pendingRoyaltyCalls: pendingCalls,
      },
    });
  };
};

export const setSkipTimer = (item) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.FIT_RADIO_ROYALTY_SKIP_TIMER,
      payload: {
        skipsLimitSet: item,
      },
    });
  };
};

export const setSkipCount = (count) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.FIT_RADIO_ROYALTY_SKIP_COUNT,
      payload: {
        skipCounter: count,
      },
    });
  };
};