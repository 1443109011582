import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../Logo/Logo";
import AppBar from "@material-ui/core/AppBar";
import AccountButtons from "../../components/AccountButtons";
import LoggedInTexts from "../../components/LoggedInTexts";
import { withStyles } from '@material-ui/core/styles';
import { isSeamlessMode } from "../../utils/utilityfunctions";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
const config=require("../../assets/config.json");
const desktop = '@media (min-width:1360px)';
const minWidth = '@media (min-width:780px)';
const tablet = '@media (min-width:768px)';
const style = {
  appbar: {
    boxShadow: '0 2px 100px 0 rgba(0, 0, 0, 0.2)',
  },
  headHeight: {
    [minWidth]: {
      height: "96px"
    }
  },
  margin: {
    marginTop: '18px',
    //marginBottom: '16px',
    [tablet]: {
      marginTop: '28px',
      // marginBottom: '16px'
    },
    [desktop]: {
      marginTop: '28px',
     // marginBottom: '16px'
    },
    paddingLeft: "0px",
    paddingRight: "0px",
  }
}


const Appbar = (props)=> {
    const { classes } = props;
    const isEmbeddedAndOnlyTenant=(config.embeddedConfig &&config.embeddedConfig["content-mode"] &&config.embeddedConfig["content-mode"].toLowerCase() === "tenant")
    const homePageLoggedOutEntitlement = useSelector( state => state.tenantConfig?.entitlement.headerLoggedOut)
    const navigate = useNavigate()
    return (
     
      <AppBar
        position={isSeamlessMode() ? "sticky" : "static"} color={config.barColor==="#ffffff" ? "transparent": "primary"}
        className=""
      >
         <div className="page-container">
           <Toolbar className={classes.margin} style={{
          justifyContent: "space-between"
        }} >
          <Logo />
          {props.isAuthenticated &&!(isEmbeddedAndOnlyTenant)&&
            <LoggedInTexts
            pageFrom={props.pageFrom}
            setSearchQuery={props.setSearchQuery}
            history={props.history}
            showSearch={props.showSearch}
            handelSearchBox={props.handelSearchBox}
            globalSearchLoading={props.globalSearchLoading}
          />
          }

          {!props.isAuthenticated &&!(isEmbeddedAndOnlyTenant)&&
            (<div className="login-panel">
              <AccountButtons navigate={navigate} homePageLoggedOutEntitlement={homePageLoggedOutEntitlement}/>
            </div>
            )} 
        </Toolbar>        
      </div>
      </AppBar>
    );
}


export default withStyles(style)(Appbar) 
