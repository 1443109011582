/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Translate } from "../../utils/Translate";
import Routes from '../../components/Router/Routes'
import ClasslistViewFav from "../../hoc/ClasslistViewFavouriteContainer";
import ClasslistViewRecent from "../../hoc/ClasslistViewRecentContainer";
import Account from "../../hoc/AccountContainer";
import UpdateAccount from "../../hoc/UpdateAccountContainer";
import { useLocation, useNavigate } from "react-router";
import { Playlists } from "./PlayLists/Playlists";
import { PROFILE } from "../../utils/constants";
import { PlayListInfo } from "./PlayListInfo/";
import { isEmpty } from "lodash";
import { PlayListPlayer } from "./PlayListPlayer/PlayListPlayer";
import MyReviews from "./MyReviews";
import MySubscription from "./MySubscription";
import { useSelector } from "react-redux";
var config = require("../../assets/config.json");

function ProfileWrapper(props){
  const [activeLink,setActiveLink] = useState('subscription')
  const userEntitlement = useSelector((state) => state.tenantConfig?.entitlement?.account)
  const featuresConfig = useSelector((state) => state.tenantConfig?.entitlementResponse?.[0]?.features)
  const navigate = useNavigate();
  const { pathname,state } = useLocation();
  const isEmbededConfig = sessionStorage.getItem("embededConfig") !== null;
  const showAccount = () =>{
    return !isEmbededConfig
  }
  const { playlistId,classId } = useParams();

  useEffect(() => {
    let index = playlistId === undefined ? -1 : -2;
     index = classId !== undefined && playlistId !== undefined ? -4 : index
    if(pathname) {
      setActiveLink(pathname.split('/').at(index))
    }
  }, [pathname,playlistId,classId]);

  return (
    <div
      className="page-container topProfileLink res-fav-acc m-b-xs-20 m-t-15"
      data-testid={"account-section"}
    >
      <Grid container spacing={0} className="scroll-x">
        {featuresConfig?.subscription && userEntitlement.mysubscription ? (
          <Grid item className="topProfileLink-margin">
            <a
              className={
                activeLink === PROFILE.MYSUBSCRIPTIONS
                  ? `${config.name} dynamiclinks makeTextClickable`
                  : "makeTextClickable"
              }
              onClick={() => {
                navigate(Routes.mySubscriptionsRoute);
              }}
            >
              <Typography variant="body1">
                {Translate({
                  id: "ClassListViews.Subscriptions",
                  defaultMessage: "Releases",
                })}
              </Typography>
            </a>
          </Grid>
        ) : null}

        {userEntitlement?.favourite ? (
          <Grid item className="topProfileLink-margin">
            <a
              className={
                activeLink === PROFILE.FAVORITES
                  ? `${config.name} dynamiclinks makeTextClickable`
                  : "makeTextClickable"
              }
              onClick={() => {
                navigate(Routes.favClassRoute);
              }}
            >
              <Typography variant="body1">
                {Translate({ id: "ClassListViews.FavTitle" })}
              </Typography>
            </a>
          </Grid>
        ) : null}
        {userEntitlement?.recentactivity ? (
          <Grid item className="topProfileLink-margin">
            <a
              className={
                activeLink === PROFILE.RECENT
                  ? `${config.name} dynamiclinks makeTextClickable`
                  : "makeTextClickable"
              }
              onClick={() => {
                navigate(Routes.recentActivityRoute);
              }}
            >
              <Typography variant="body1">
                {Translate({ id: "ClassListViews.MyActivity" })}
              </Typography>
            </a>
          </Grid>
        ) : null}
        {featuresConfig?.ratingEnabled && userEntitlement?.rating ? (
          <Grid item className="topProfileLink-margin">
            <a
              className={
                activeLink === "reviews"
                  ? `${config.name} dynamiclinks makeTextClickable`
                  : "makeTextClickable"
              }
              onClick={() => {
                navigate(Routes.reviewsRoute);
              }}
            >
              <Typography variant="body1">
                {Translate({ id: "RatingsReviews.Reviews" })}
              </Typography>
            </a>
          </Grid>
        ) : null}
        {userEntitlement?.playlist ? (
          <Grid item className="topProfileLink-margin">
            <a
              className={
                activeLink === PROFILE.PLAYLIST
                  ? `${config.name} dynamiclinks makeTextClickable`
                  : "makeTextClickable"
              }
              onClick={() => {
                props.navigate(Routes.userPlayList);
              }}
            >
              <Typography variant="body1">
                {Translate({
                  id: "Playlist.Playlists",
                  defaultMessage: "Playlists",
                })}
              </Typography>
            </a>
          </Grid>
        ) : null}
        {showAccount() ? (
          <Grid item>
            <a
              className={
                activeLink === PROFILE.ACCOUNT || activeLink === PROFILE.UPDATE
                  ? `${config.name} dynamiclinks makeTextClickable`
                  : "makeTextClickable"
              }
              onClick={() => {
                navigate("/user/account");
              }}
            >
              <Typography variant="body1">
                {Translate({ id: "UserAccount.Title" })}
              </Typography>
            </a>
          </Grid>
        ) : null}
      </Grid>

      {featuresConfig?.subscription && userEntitlement.mysubscription
        ? activeLink === PROFILE.MYSUBSCRIPTIONS && (
            <MySubscription {...props} />
          )
        : null}
      {userEntitlement?.favourite
        ? activeLink === PROFILE.FAVORITES && <ClasslistViewFav {...props} />
        : null}
      {userEntitlement?.recentactivity
        ? activeLink === PROFILE.RECENT && <ClasslistViewRecent {...props} />
        : null}
      {userEntitlement?.rating
        ? activeLink === PROFILE.REVIEWS && <MyReviews />
        : null}
      {activeLink === PROFILE.ACCOUNT && <Account navigate={navigate} />}
      {activeLink === PROFILE.UPDATE && (
        <UpdateAccount state={state} navigate={navigate} />
      )}
      {userEntitlement?.playlist ? (
        activeLink === PROFILE.PLAYLIST ? (
          !isEmpty(playlistId) && isEmpty(classId) ? (
            <PlayListInfo playlistId={playlistId} />
          ) : !isEmpty(playlistId) && !isEmpty(classId) ? (
            <PlayListPlayer playlistId={playlistId} classId={classId} />
          ) : (
            <Playlists />
          )
        ) : null
      ) : null}
    </div>
  );
  }

export default ProfileWrapper
