import * as actionTypes from './actionTypes'

export const setselectedFiltersOnDemand = (filterValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_SELECTED_FILTERS_ONDEMAND,
            value: filterValue
        });
    };
};

export const setKeywordsOnDemand = (keywordsValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_KEYWORDS_ONDEMAND,
            value: keywordsValue
        });
    };
};

export const setCategoriesOnDemand = (categoryValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTERED_SUB_CATEGORY_ONDEMAND,
            value: categoryValue
        });
    };
};

export const setDurationOnDemand = (durationValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_DURATION_ONDEMAND,
            value: durationValue
        });
    };
};

export const setSkillLevelOnDemand = (skillValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_SKILL_ONDEMAND,
            value: skillValue
        });
    };
};

export const setFocusAreaOnDemand = (focusAreaValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_FOCUS_AREA_ONDEMAND,
            value: focusAreaValue
        });
    };
};

export const setEquipmentsTypesOnDemand = (equipmentsValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_EQUIPMENTS_TYPES_ONDEMAND,
            value: equipmentsValue
        });
    };
};

export const setIntensityLevelOnDemand = (intensityValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_INTENSITY_ONDEMAND,
            value: intensityValue
        });
    };
};

export const setClassLanguageOnDemand = (languageValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_CLASS_LANGUAGE_ONDEMAND,
            value: languageValue
        });
    };
};

export const setSortOnDemand = (sortValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_SORT_ONDEMAND,
            value: sortValue
        });
    };
};

export const setFilterSearchTextOnDemand = (searchValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_SEARCH_TEXT_ONDEMAND,
            value: searchValue
        });
    };
};

export const setFilteredClassSearchData = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTERED_CLASS_SEARCH_DATA_ONDEMAND,
            value: value
        });
    };
};

export const setFilteredTopKeywords = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTERED_TOP_KEYWORDS_ONDEMAND,
            value: value
        });
    };
};
export const setPageCount = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_PAGE_COUNT_ONDEMAND,
            value: value
        });
    };
};

export const setPaginatedDataOnDemand = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_PAGINATED_DATA_ONDEMAND,
            value: value
        });
    };
};

export const setClassesSearchDataOnDemand = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_CLASSES_SEARCH_DATA_ONDEMAND,
            value: value
        });
    };
};

export const settempClassSearchDataOnDemand = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_TEMP_CLASS_SEARCH_DATA_ONDEMAND,
            value: value
        });
    };
};

export const setTopKeywordsOnDemand = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_TOP_KEYWORDS_ONDEMAND,
            value: value
        });
    };
};

export const resetFiltersOnDemand = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.RESET_FILTERS_ONDEMAND,
        });
    };
};

export const setOnDemandMediaType = (mediaType) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_MEDIA_TYPE_ONDEMAND,
            value: mediaType
        });
    };
};