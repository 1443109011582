   import React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import {Typography,Grid} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import RadioButtonCheckedRoundedIcon from '@material-ui/icons/RadioButtonCheckedRounded';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';


class CheckboxValidatorElement extends ValidatorComponent {

    render() {

        //const validatorlistener = this.props.validatorListener
        const { errorMessages, validators, requiredError, value, label,tncclick,validatorListener,...rest } = this.props;
        return (
            <Grid className="tnctext" >
            <div  >
                 <Checkbox
                     icon={
                     <CheckBoxOutlineBlank  className="termsIcon check-box-align" />
                    }
                     checkedIcon={
                     <CheckBox  className="termsIcon check-box-align" />
                    } 
                    {...rest}
                    ref={(r) => { this.input = r; }}
                />
                </div>
            
                <div >
                    <Typography onClick={this.props.tncclick}
                    color = "secondary"
                  variant="h3"
                  style={{marginTop:"7px"}}
                  className={this.props.classes}
                    >
                        {this.props.label}
                 </Typography>
                   
                </div>
                <p className='Error'>{this.errorText()}</p>
            
            </Grid>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div className="MuiFormHelperText-root-250 MuiFormHelperText-error-251" >
                {this.getErrorMessage()}
            </div>
        );
    }}

 

export default CheckboxValidatorElement;
