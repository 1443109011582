import React, { useCallback, useEffect, useRef } from "react";
import { isInternetConnected } from "../../utils/utilityfunctions";
import RoyaltyCacheManager from "./FitRadioRoyaltyCacheManager";

export const RoyaltyCacheTrigger = () => {
  const royaltyCacheManagerRef = useRef(RoyaltyCacheManager.getInstance());

  const handleApplicationLaunch = useCallback(() => {
    royaltyCacheManagerRef.current?.run();
  }, []);

  useEffect(() => {
    if (isInternetConnected()) {
      royaltyCacheManagerRef.current.run();
    }
  }, [isInternetConnected()]);

  useEffect(() => {
    handleApplicationLaunch();
  }, [handleApplicationLaunch]);

  return null;
};
