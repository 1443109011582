import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { IsMiniMobile,IsMobile,IsTab,IsDesktop,IsProjector } from 'utils/breakPoints';
import CardShimmer from './CardShimmer';

const LoadingClassesShimmer = ({totalCards=5,myClass=''}) => {
    const restCards = (items,division) => Number((items % division).toFixed());
    const handelItems = ()=>{

        try{
            let items = [];
            if(IsMiniMobile()){
                items.length = 1;
            }
            if(IsMobile()){
                items.length = 1;
            }
            if(IsTab()){
                const leftCards = restCards(totalCards,3);
                items.length = leftCards === 0 ? 3 : leftCards === 1 ? 2 : 1;
            }
            if(IsDesktop()){
                const leftCards = restCards(totalCards,4);
                items.length = leftCards === 0 ? 4 : leftCards === 1 ? 3 : leftCards === 2 ? 2 : 1;
            }
            if(IsProjector()){
                // const leftCards = restCards(totalCards,5);
                items.length = 5
                //leftCards === 0 ? 4 : leftCards === 1 ? 3 : leftCards === 2 ? 2 : 1;
            }
            items.fill(1);
            return items;
        }
        catch(e){
            return [1,2,3,4,5]
        }
    }

    const initialLoad = ()=>{
        try{
            let cards = [];
            if(IsMiniMobile()){
                cards.length = 3;
            }
            if(IsMobile()){
                cards.length = 3;
            }
            if(IsTab()){
                cards.length = 9;
            }
            if(IsDesktop()){
                cards.length = 8 ;
            }
            if(IsProjector()){
                cards.length = 10 ;
            }
            cards.fill(1);
            return cards;
        }
        catch(e){
            return [1,2,3,4,5]
        }
    }

    return (
        myClass === '' ? (
            <div className= 'm-t-40'>
                <CardShimmer noOfCards={initialLoad()} liveList={true} footer={true} />
            </div>
        ):
        myClass === "custom-grid" ? (
            initialLoad().map((val,i) =>
                <div key={i}>
                    <Skeleton variant="rect" className="cardBorder channel-page-card" />
                    <Skeleton variant="text" width={'60%'} height={15} />
                    <Skeleton variant="text" width={'60%'} height={15} />
                </div>
            )
        ):
        (
            <React.Fragment>
            <div className={`cardList m-t-5 flexNoWrap list`}>
            {handelItems().map((val,i) =>
                <div key={i}>
                    <Skeleton variant="rect" className="cardBorder channel-page-card" />
                    <Skeleton variant="text" width={'60%'} height={15} />
                    <Skeleton variant="text" width={'60%'} height={15} />
                </div>
            )}
            </div>
        </React.Fragment>
        )
    );
}
export default LoadingClassesShimmer;
