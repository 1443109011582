// import React, { useEffect, useState } from "react";

// function getIconSize(sizeArray, screenWidth) {
//   const breakpoints = [768, 1360, 2000, 2001];
//   let iconSizeIndex = 0;

//   for (let i = 0; i < breakpoints.length; i++) {
//     if (screenWidth >= breakpoints[i]) {
//       iconSizeIndex = i + 1;
//     } else {
//       break;
//     }
//   }

//   const sizeIndex = Math.min(iconSizeIndex, sizeArray.length - 1);
//   return sizeArray[sizeIndex];
// }

// function IconPlayListPlay(props) {
//   const color = props.color;
//   const svgId = props.id;

//   const [iconSize, setIconSize] = useState("32px")

//   useEffect(() => {
//     window.addEventListener("resize", () => {
//       try {
//         let screenWidth = window.innerWidth;
//         const result = getIconSize(["32px", "32px", "48px", "48px"], screenWidth)
//         setIconSize(result)
//       } catch (e) {
//         console.log(svgId, e.message, "===CATCH");
//       }
//     });
//   }, [])

//   return (
//     <div className="icon-playlist-play-svg">
//       <svg
//         className="icon-svg-play-list"
//         id={props.id}
//         width={iconSize}
//         height={iconSize}
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox={`0 0 32 32`}
//       >
//         <defs>
//           <path
//             d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0z"
//             id="6h67lr4dja"
//           />

//           <path
//             d="M5.735 9.194h10c.55 0 1 .45 1 1s-.45 1-1 1h-10c-.55 0-1-.45-1-1s.45-1 1-1zm0-4h10c.55 0 1 .45 1 1s-.45 1-1 1h-10c-.55 0-1-.45-1-1s.45-1 1-1zm0 8h6c.55 0 1 .45 1 1s-.45 1-1 1h-6c-.55 0-1-.45-1-1s.45-1 1-1zm9 .88v4.23c0 .39.42.63.76.43l3.53-2.12c.32-.19.32-.66 0-.86l-3.53-2.12a.508.508 0 0 0-.76.44z"
//             id="4zqmb0b22c"
//           />
//         </defs>

//         <g fill="none" fill-rule="evenodd">
//           <g>
//             <use xlinkHref="#6h67lr4dja" fill={"clear"} />
//           </g>

//           <g transform="translate(1 9) scale(0.8)">
//             <use xlinkHref="#4zqmb0b22c" fill="#fff" />
//           </g>
//         </g>
//       </svg>
//     </div>
//   );
// }

// export default IconPlayListPlay;

import React from "react";
import { iconSizePlayListBtn, useIconSize } from "../utils/svgSize";

// const color = (require("../assets/config.json")).primaryColor.slice(1)
function IconPlayListPlay(props) {
  let size = useIconSize(["20px", "20px", "24px", "28px"], props.id);

  return (
    <div className="play-icon-btn">
      <svg
        className="play-icon-svg-play-list"
        id={props.id}
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 32 32`}
      >
        <defs>
          <path
            d="M5.735 9.194h10c.55 0 1 .45 1 1s-.45 1-1 1h-10c-.55 0-1-.45-1-1s.45-1 1-1zm0-4h10c.55 0 1 .45 1 1s-.45 1-1 1h-10c-.55 0-1-.45-1-1s.45-1 1-1zm0 8h6c.55 0 1 .45 1 1s-.45 1-1 1h-6c-.55 0-1-.45-1-1s.45-1 1-1zm9 .88v4.23c0 .39.42.63.76.43l3.53-2.12c.32-.19.32-.66 0-.86l-3.53-2.12a.508.508 0 0 0-.76.44z"
            id="play-icon"
          />
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g>
            <use xlinkHref="#play-icon" fill="#fff" />
            {/* <mask id="play-icon-list" fill="#fff">
              <use xlinkHref="#play-icon" />
            </mask>
            <g mask="url(#play-icon-list)" fill="#FFF">
              <path d="M0 0h28v28H0z" />
            </g> */}
          </g>
        </g>
      </svg>
    </div>
  );
}
export default IconPlayListPlay;
